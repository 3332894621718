import * as React from 'react'
import * as PropTypes from 'prop-types'
import styled from 'styled-components'
import * as colors from '@colors'

import ClickOutside from '../../core/ClickOutside'
import DropdownLabel from './DropdownLabel'
import ListFilter, { Options } from './ListFilter'
import Panel from './Panel'

// NOTE: for Firefox & Edge compatibility
const encodeColor = color => color.replace(/#/, '%23')

const Container = styled.span`
  display: inline-flex;
  position: relative;
`
/* prettier-ignore */
const ScrollableContainer = styled(Container)`
  ${Options} {
    max-height: ${props => props.scrollableHeight || '300px'};
    overflow-y: auto;
    margin: 0 -10px 2px;
    padding: 10px;

    ::after {
      content: '';
      background-color: transparent;
      background-image:
        url(
          'data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" fill-rule="evenodd" clip-rule="evenodd" viewBox="0 0 32 32" width="8"><g transform="translate(0, 12)"><path d="M32 .9c-.32-.6-.96-.9-1.918-.9H1.918C.958 0 .319.3 0 .9 0 1.8 0 2.4.32 3l14.079 14.7c.642 0 .96.3 1.6.3.64 0 .96-.3 1.283-.602L31.362 3C32 2.4 32 1.8 32 .9" fill="${encodeColor(colors.GREY_SHADE_4)}"/></g></svg>'
        );
      background-repeat: no-repeat;
      background-position: center center;
      width: 100%;
      height: 16px;
      position: absolute;
      left: 50%;
      bottom: 0;
      transform: translateX(-50%);
    }
  }
`

class MultiselectDropdown extends React.Component {
  state = {
    opened: false,
    focused: this.props.focused,
    sizerWidth: 0,
    selected: this.props.selected || [],
    searched: [],
    options: this.props.options,
    isGrouped: false,
  }

  componentDidMount() {
    this.mounted = true
    this.updateSizerWidth()
    this.setState({
      isGrouped: this.isGrouped(),
    })
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.focused !== this.props.focused) {
      this.setState({ focused: this.props.focused })
    }

    if (prevProps.selected !== this.props.selected) {
      this.updateSizerWidth()
    }

    if (prevState.selected !== this.props.selected) {
      this.setState({ selected: this.props.selected })
    }
  }

  componentWillUnmount() {
    this.mounted = false
  }

  handleClick = event => {
    if (this.props.onClick) {
      this.props.onClick(event)
    }

    event.preventDefault()
    event.stopPropagation()
    this.openMenu()
  }

  /**
   * Helper; check if values are grouped
   *
   * @memberof MultiselectDropdown
   */
  isGrouped = () => {
    const { options } = this.props
    return !!(options && options.find(option => option.items))
  }

  /**
   * Retrieve the object which is related to the value
   *
   * @param string
   * @memberof MultiselectDropdown
   * @return an array of objects
   */
  findOptionsByValue = value => {
    const { options } = this.props
    const { isGrouped } = this.state

    if (isGrouped) {
      const selectedValue = []

      options &&
        options.forEach(option => {
          option &&
            option.items &&
            option.items.map(objectOption => {
              if (objectOption && objectOption.value == value) {
                selectedValue.push(objectOption)
              }
            })
        })

      return selectedValue
    }

    return options && options.filter(option => option.value === value)
  }

  /**
   * Remove value from selected and check it's empty
   *
   * @param string
   * @memberof MultiselectDropdown
   */
  removeSelection = value => {
    const { selected } = this.state

    const cleanedSelected = selected && selected.filter(selectedValue => selectedValue.value !== value)

    if ((cleanedSelected && cleanedSelected.length === 0) || !this.props.multiselect) {
      this.setState(
        {
          selected: [],
        },
        () => {
          this.retrieveValues()
          this.props.onItemDeletion(...this.findOptionsByValue(value))
        },
      )
      return
    }

    this.setState(
      {
        selected: cleanedSelected,
      },
      () => {
        this.retrieveValues()
        this.props.onItemDeletion(...this.findOptionsByValue(value))
      },
    )
  }

  /**
   * Add value to the selected state
   *
   * @param string
   * @memberof MultiselectDropdown
   */
  addSelection = value => {
    const { selected } = this.state
    const selectedValue = this.findOptionsByValue(value)

    if (!this.props.multiselect) {
      this.setState(
        {
          selected: selectedValue,
        },
        this.retrieveValues,
      )

      return
    }

    this.setState(
      {
        selected: [...selected, ...selectedValue],
      },
      () => {
        this.retrieveValues()
        this.props.onItemAddition(...selectedValue)
      },
    )
  }

  /**
   * Send data to the father of the component
   *
   * @memberof MultiselectDropdown
   */
  retrieveValues = () => {
    const { onChange } = this.props
    const { selected } = this.state

    if (onChange && typeof onChange === 'function') {
      onChange(selected)
      return
    }
  }

  /**
   * On click on the option it will check if it already exists
   * if so it removes it from the selected data, else, it will add it
   *
   * @param eventType
   * @memberof MultiselectDropdown
   */
  handleClickOption = event => {
    const { selected } = this.state
    const currentValue = event.target.dataset.value

    const index = selected && selected.findIndex(selectedValue => selectedValue && selectedValue.value === currentValue)

    if (index > -1) {
      this.removeSelection(currentValue)
      return
    }

    this.addSelection(currentValue)

    if (!this.props.multiselect) {
      this.closeMenu()
    }

    return
  }

  openMenu = () => {
    this.setState({ opened: true, focused: true, searched: [] })
  }

  closeMenu = () => {
    this.setState({ opened: false, focused: false, searched: [] })
  }

  getSizerRef = ref => {
    this.sizer = ref
  }

  getLabelRef = ref => {
    this.label = ref
  }

  /**
   * Filter the current options and return an object
   * to the parent containing the results of the search
   *
   * @param string
   * @memberof MultiselectDropdown
   */
  handleSearch = value => {
    const { options } = this.props
    const { isGrouped } = this.state

    if (value === '') {
      this.setState({
        searched: [],
      })

      return
    }

    //flag `gi` stands for global and case insensitive
    const regex = new RegExp(value, 'gi')

    if (isGrouped) {
      const searchedOption = []
      options &&
        options.filter(option => {
          return (
            option &&
            option.items &&
            option.items.filter(objectOption => {
              if (objectOption && objectOption.label.match(regex)) {
                searchedOption.push(objectOption)
              }
              return objectOption
            })
          )
        })

      this.setState({
        searched: searchedOption.flat(1),
      })

      return
    }

    const searched =
      options &&
      options.filter(option => {
        return option && option.label && option.label.match(regex)
      })

    this.setState({
      searched,
    })
  }

  handleSelectAll = () => {
    const { isGrouped, searched } = this.state
    const { options, onSelectAll } = this.props
    const onSelectionDone = () => {
      this.retrieveValues()
      onSelectAll && onSelectAll()
    }

    if (searched && searched.length) {
      this.setState(
        {
          selected: searched,
        },
        onSelectionDone,
      )

      return
    }

    if (isGrouped) {
      const selectedOptions = []
      options &&
        options.forEach(option => {
          return (
            option &&
            option.items &&
            option.items.forEach(objectOption => {
              return selectedOptions.push(objectOption)
            })
          )
        })

      this.setState(
        {
          selected: selectedOptions.flat(1),
        },
        onSelectionDone,
      )
      return
    }

    this.setState(
      {
        selected: options,
      },
      onSelectionDone,
    )
  }

  handleClearAll = () => {
    this.setState(
      {
        selected: [],
      },
      () => {
        const { handleSearch } = this.props

        if (typeof handleSearch === 'function') {
          this.listFilter.resetSearch()
          this.props.handleSearch(this.state.searched)
        }

        this.retrieveValues()
      },
    )
  }

  updateSizerWidth = () => {
    if (!this.props.autosize || !this.mounted || !this.sizer) {
      return
    }

    let newSizerWidth = this.sizer.scrollWidth * (1 + 15 / 100)

    if (this.label && this.label.scrollWidth > newSizerWidth) {
      newSizerWidth = this.label.scrollWidth * (1 + 15 / 100)
    }

    this.setState({ sizerWidth: Math.floor(newSizerWidth) })
  }

  renderMenu() {
    const { searched, isGrouped, selected } = this.state
    const {
      actions,
      alignLeft,
      handleSearch,
      onBlur,
      loadMore,
      loading,
      name,
      multiselect,
      onLoadMore,
      search,
      translation,
    } = this.props

    const options = searched && !searched.length ? this.props.options : searched
    return (
      <ClickOutside
        onClickOutside={() => {
          onBlur()
          this.closeMenu()
        }}
      >
        <Panel as="span" alignLeft={alignLeft}>
          <ListFilter
            ref={c => (this.listFilter = c)}
            options={options}
            isGrouped={isGrouped}
            hasSearch={search}
            multiselect={multiselect}
            selected={selected}
            name={name}
            handleSearch={handleSearch || this.handleSearch}
            handleClickOption={this.handleClickOption}
            actions={actions}
            handleClearAll={this.handleClearAll}
            handleSelectAll={this.handleSelectAll}
            translation={translation}
            loadMore={loadMore}
            loading={loading}
            onLoadMore={onLoadMore}
          />
        </Panel>
      </ClickOutside>
    )
  }

  render() {
    const { scrollable, scrollableHeight, translation } = this.props
    const { opened, selected, sizerWidth } = this.state
    const ContainerElem = scrollable ? ScrollableContainer : Container

    return (
      <ContainerElem scrollableHeight={scrollableHeight}>
        <DropdownLabel width={sizerWidth} onClick={this.handleClick} translation={translation} value={selected} />
        {opened && this.renderMenu()}
      </ContainerElem>
    )
  }
}

MultiselectDropdown.defaultProps = {
  alignLeft: false,
  autosize: false,
  disabled: false,
  focused: false,
  onBlur: () => {},
  onChange: () => {},
  onItemAddition: () => {},
  onItemDeletion: () => {},
  required: false,
}

MultiselectDropdown.propTypes = {
  /** activate actions: Select All, Clear All. Only useful with multiselect */
  actions: PropTypes.bool,
  /** align dropdown box to the left. If falsy, will align to right (default) */
  alignLeft: PropTypes.bool,
  /** will autosize based on the width of the parent div */
  autosize: PropTypes.bool,
  /** make dropdown unclickable */
  disabled: PropTypes.bool,
  /** ensure the focus on the input */
  focused: PropTypes.bool,
  /** Listener on the input to retrieve searched options */
  handleSearch: PropTypes.func,
  /** label of the dropdown */
  label: PropTypes.string,
  /** loadMore button loading state */
  loading: PropTypes.bool,
  /** display loadMore button */
  loadMore: PropTypes.bool,
  /** activate the multiselect option */
  multiselect: PropTypes.bool,
  /** name of the dropdown */
  name: PropTypes.string.isRequired,
  /** action on the blur of the dropdown */
  onBlur: PropTypes.func,
  /** listener that retrieve data from the dropdown options */
  onChange: PropTypes.func.isRequired,
  /** action on click of the dropdown label */
  onClick: PropTypes.func,
  /** action on the focus of the dropdown */
  onFocus: PropTypes.func,
  /** action when an item is selected in multi-select mode */
  onItemAddition: PropTypes.func,
  /** action when an item is deselected in multi-select mode */
  onItemDeletion: PropTypes.func,
  /** action on click on loadMore button */
  onLoadMore: PropTypes.func,
  /** action on select all clicked */
  onSelectAll: PropTypes.func,
  /** array of objects to display */
  options: PropTypes.array.isRequired,
  /** require the dropdown to have a value (ex: formik) */
  required: PropTypes.bool,
  /** activate scroll on options list */
  scrollable: PropTypes.bool,
  /** height of the scrollable area */
  scrollableHeight: PropTypes.string,
  /** activate search */
  search: PropTypes.bool,
  /** default value of options selected */
  selected: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    }),
  ),
  /** object of string used for translation */
  translation: PropTypes.shape({
    multiselect: PropTypes.string,
    loadMore: PropTypes.shape({
      initialLabel: PropTypes.string,
      errorLabel: PropTypes.string,
    }),
  }),
}

export default MultiselectDropdown
