import * as React from 'react'
import * as PropTypes from 'prop-types'
import styled from 'styled-components'

import * as colors from '@colors'
import * as fonts from '@fonts'

import Icon from '@legacy/Icon'
import { PrimaryLink } from '@legacy/Links'

const getBackgroundColor = level => {
  switch (level) {
    case 'success':
      return colors.PRIMARY_GREEN
    case 'alert':
      return colors.SECONDARY_RED
    case 'info':
      return colors.PRIMARY_BLACK
    case 'notification':
    default:
      return colors.PRIMARY_BLUE
  }
}

const getLinkColor = level => {
  switch (level) {
    case 'success':
    case 'info':
      return colors.PRIMARY_BLUE
    case 'alert':
    case 'notification':
    default:
      return colors.PRIMARY_BLACK
  }
}

const FlashMessageWrapper = styled.div`
  position: relative;
  width: 100%;
  padding: 10px 20px;
  background-color: ${props => getBackgroundColor(props.level)};
  color: ${colors.PRIMARY_WHITE};

  ${PrimaryLink /* sc-sel */} {
    color: ${props => getLinkColor(props.level)};
    border-bottom: 2px solid transparent;
    transition: border-bottom 150ms ease-in-out;

    :hover {
      border-bottom: 2px solid;
    }
  }
`

const MessageContainer = styled.div`
  max-width: 980px;
  font-family: ${fonts.PRIMARY_FONT};
  font-weight: 400;
  font-size: 13px;
  padding: 0;
  margin: 0 auto;
  text-align: center;
`

const CloseButton = styled.span`
  position: absolute;
  right: 20px;
  top: 50%;
  transform: translate(0, -50%);
  cursor: pointer;

  &:hover {
    opacity: 0.7;
  }
`

/** This component has to be used to notify user just after an unexpected result for his action. When user click on close icon, the message should be disapear. If no level or no message is passed by props, the component doesn't render. */
const FlashMessage = ({ level, closable, onClickClose, translations, children }) => {
  return (
    <FlashMessageWrapper level={level}>
      <MessageContainer>{children}</MessageContainer>
      {closable && (
        <CloseButton onClick={onClickClose}>
          <Icon glyph="close" title={translations['close']} size={10} />
        </CloseButton>
      )}
    </FlashMessageWrapper>
  )
}

FlashMessage.defaultProps = {
  closable: false,
  level: 'notification',
}

FlashMessage.propTypes = {
  /** controls the main color of notification */
  level: PropTypes.oneOf(['success', 'alert', 'info', 'notification']),
  /** if true, add a cross icon */
  closable: PropTypes.bool,
  /** useful only if closable is true */
  onClickClose: PropTypes.func,
  translations: PropTypes.shape({
    close: PropTypes.string.isRequired,
  }),
  /** children can have html elements */
  children: PropTypes.node.isRequired,
}

export default FlashMessage
