import styled, { css } from 'styled-components'

import * as colors from '@colors'
import * as fonts from '@fonts'
import { Icon } from '@glyphs/index'

export type Color = 'blue' | 'dark-blue' | 'dark-green' | 'green' | 'grey' | 'indigo' | 'light-blue' | 'red' | 'yellow'
export type Size = 'small' | 'big'

type TagProps = {
  size?: Size
  color?: Color
} & React.HTMLAttributes<HTMLDivElement>

export const Tag = styled.div<TagProps>`
  background-color: ${colors.GREY_SHADE_5};
  border-radius: 2px;
  color: ${colors.GREY_SHADE_1};
  display: inline-block;
  font-family: ${fonts.PRIMARY_FONT};
  font-size: 10px;
  font-weight: bold;
  height: 20px;
  line-height: 20px;
  padding: 0em 0.6em;
  text-transform: uppercase;
  width: max-content;

  /* Color */
  ${props => {
    if (props.color === 'blue') {
      return css`
        color: ${colors.PRIMARY_WHITE};
        background-color: ${colors.PRIMARY_BLUE};
      `
    }
    if (props.color === 'dark-blue') {
      return css`
        color: ${colors.PRIMARY_WHITE};
        background-color: ${colors.BLUE_SHADE_2};
      `
    }
    if (props.color === 'dark-green') {
      return css`
        color: ${colors.PRIMARY_WHITE};
        background-color: ${colors.BRAND_ACCENT_GREEN};
      `
    }
    if (props.color === 'green') {
      return css`
        color: ${colors.PRIMARY_WHITE};
        background-color: ${colors.PRIMARY_GREEN};
      `
    }
    if (props.color === 'grey') {
      return css`
        color: ${colors.GREY_SHADE_1};
        background-color: ${colors.GREY_SHADE_5};
      `
    }
    if (props.color === 'indigo') {
      return css`
        color: ${colors.PRIMARY_WHITE};
        background-color: ${colors.SECONDARY_INDIGO};
      `
    }
    if (props.color === 'light-blue') {
      return css`
        color: ${colors.BLUE_SHADE_2};
        background-color: ${colors.BLUE_SHADE_1};
      `
    }
    if (props.color === 'red') {
      return css`
        color: ${colors.PRIMARY_WHITE};
        background-color: ${colors.RED_SHADE_2};
      `
    }
    if (props.color === 'yellow') {
      return css`
        color: ${colors.PRIMARY_WHITE};
        background-color: ${colors.SECONDARY_YELLOW};
      `
    }
  }}

  /* Size */
  ${props => {
    if (props.size === 'small') {
      return css`
        font-size: 8px;
        font-weight: 600;
      `
    }
    if (props.size === 'big') {
      return css`
        font-size: 15px;
      `
    }
  }}

  /* Tag with icon */
  span[role=img] {
    margin-right: 6px
  }
`
Tag.displayName = 'S.tag.Tag'

export const RoundTag = styled.button`
  border: 1px solid ${colors.GREY_SHADE_5};
  background-color: ${colors.PRIMARY_WHITE};
  border-radius: 16px;
  display: inline-flex;
  padding: 4px 16px 4px 12px;

  &::-moz-focus-inner {
    border: 0;
  }

  &:focus,
  &:active {
    outline: none;
  }

  ${Icon} {
    color: ${colors.GREY_SHADE_7};
    margin-right: 7px;
    margin-top: 3px;
  }
`
RoundTag.displayName = 'S.tag.RoundTag'
