/**
 * Z-Index list
 *
 */

export const BELOW = -1
export const NEUTRAL = 0
export const ABOVE = 1
export const HIGH = 100

export const NAVIGATION_MENU = 200
export const TOAST = 250
export const HEADER = 300
export const HEADER_BRAND = 301
export const HEADER_NAV_ITEM = 302
export const HEADER_NAV_SHUTTER = 303
export const SHUTTER = 800
export const MODAL = 900
