import styled from 'styled-components'

import * as colors from '@colors'
import * as fonts from '@fonts'

export const Label = styled.label`
  font-family: ${fonts.PRIMARY_FONT}, sans-serif;
  font-weight: 400;
  font-size: 15px;
  line-height: 18px;
  color: ${colors.PRIMARY_BLACK};
`

export const Description = styled.span`
  font-family: ${fonts.PRIMARY_FONT}, sans-serif;
  font-weight: 300;
  font-size: 15px;
  line-height: 22px;
  color: ${colors.PRIMARY_BLACK};
`

export const Detail = styled.div`
  font-family: ${fonts.PRIMARY_FONT}, sans-serif;
  font-weight: 500;
  font-size: 13px;
  color: ${props => (props.error ? `${colors.SECONDARY_RED}` : `${colors.PRIMARY_GREEN}`)};
  margin-top: 7px;
`
