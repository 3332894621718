import styled from 'styled-components'

import * as colors from '@colors'
import * as fonts from '@fonts'

import { Label, Description } from './Label'

/** INPUT */

export const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
`

const getInputBorder = (error, success, disabled) => {
  if (disabled) {
    return `1px solid ${colors.GREY_SHADE_5}`
  }

  if (error) {
    return `1px dashed ${colors.SECONDARY_RED}`
  }

  if (success) {
    return `1px solid ${colors.PRIMARY_GREEN}`
  }
  return `1px solid ${colors.GREY_SHADE_5}`
}

export const Input = styled.input`
  height: 50px;
  border: ${props => getInputBorder(props.error, props.success, props.disabled)};
  border-radius: 2px;
  padding: 15px;
  font-family: ${fonts.PRIMARY_FONT}, sans-serif;
  font-size: 15px;
  font-weight: 400;
  outline-color: ${colors.PRIMARY_BLUE};
  /* stylelint-disable-next-line */
  background-color: ${props => (props.disabled ? `${colors.GREY_SHADE_6}` : `${colors.PRIMARY_WHITE}`)};
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;

  ::placeholder {
    font-family: ${fonts.PRIMARY_FONT}, sans-serif;
    color: ${colors.GREY_SHADE_3};
    font-size: 15px;
    font-weight: 400;
  }
`

export const InputLabel = styled(Label)`
  margin-bottom: 7px;
`

export const InputDescription = styled(Description)`
  margin-bottom: 7px;
`

export const InputCounter = styled.span`
  /* stylelint-disable-next-line */
  color: ${props => (props.currentCount > props.maxCount ? `${colors.SECONDARY_RED}` : `${colors.GREY_SHADE_3}`)};
  font-size: 10px;
  font-family: ${fonts.PRIMARY_FONT}, sans-serif;
  font-weight: 500;
  position: absolute;
  right: 0;
  bottom: -16px;
`

/** RADIO */

export const SmallRadioGroup = styled.div`
  display: flex;
`

const getRadioBorder = (error, disabled, checked, small) => {
  if (error) {
    return `1px dashed ${colors.SECONDARY_RED}`
  }

  if (small) {
    return `1px solid transparent`
  }

  if (disabled) {
    return `none`
  }

  if (checked) {
    return `1px solid ${colors.PRIMARY_BLUE}`
  }

  return `1px solid ${colors.GREY_SHADE_5}`
}

export const RadioButton = styled.span`
  display: block;
  position: relative;
  height: ${props => (props.small ? `18px` : `20px`)};
  width: ${props => (props.small ? `18px` : `20px`)};
  /* stylelint-disable-next-line */
  border: 2px solid ${props => (props.checked ? `${colors.PRIMARY_BLUE}` : `${colors.GREY_SHADE_5}`)};
  border-radius: 50%;
  cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};
`

export const RadioButtonFill = styled.span`
  background-color: ${colors.PRIMARY_BLUE};
  position: absolute;
  width: ${props => (props.small ? `8px` : `10px`)};
  height: ${props => (props.small ? `8px` : `10px`)};
  border-radius: 50%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`

export const RadioContainer = styled.div`
  border: ${props => getRadioBorder(props.error, props.disabled, props.checked, props.small)};
  position: relative;
  border-radius: 2px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 15px 10px;
  /* stylelint-disable-next-line */
  background-color: ${props => (props.disabled ? `${colors.GREY_SHADE_6}` : `${colors.PRIMARY_WHITE}`)};

  :hover {
    /* stylelint-disable-next-line */
    border: 1px solid
      ${props => (props.checked || props.disabled || props.small ? `transparant` : `${colors.GREY_SHADE_4}`)};
  }

  ${RadioButton} {
    flex: 0 0 auto;
    margin-right: 10px;
  }
`

export const Radio = styled.input`
  position: absolute;
  opacity: 0;
  height: 0;
  width: 0;
`

export const RadioInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
`
export const RadioInfoLabel = styled(Label)`
  /* stylelint-disable-next-line */
  color: ${props =>
    props.checked ? `${colors.PRIMARY_BLUE}` : props.disabled ? `${colors.GREY_SHADE_4}` : `${colors.PRIMARY_BLACK}`};
  font-size: ${props => (props.small ? `13px` : `15px`)};
`

export const RadioInfoDescription = styled(Description)`
  font-weight: 400;
  font-family: ${fonts.PRIMARY_FONT}, sans-serif;
  font-size: 13px;
  color: ${colors.GREY_SHADE_3};
`

/** CHECKBOX */

export const CheckboxContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: ${props => (props.hasTitle ? 'initial' : 'center')};
`

export const Checkbox = styled.input`
  position: absolute;
  opacity: 0;
  height: 0;
  width: 0;
`

export const CheckmarkSvg = styled.svg`
  fill: currentColor;
`

export const CheckmarkWrapper = styled.div`
  position: relative;
  width: 18px;
  height: 18px;
  min-width: 18px;
  min-height: 18px;
  cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};
  pointer-events: ${props => (props.disabled ? 'none' : 'auto')};
  /* stylelint-disable-next-line */
  border: 2px solid ${props => (!props.checked || props.disabled ? `${colors.GREY_SHADE_4}` : `${colors.PRIMARY_BLUE}`)};
  /* stylelint-disable-next-line */
  background-color: ${props =>
    props.disabled ? `${colors.GREY_SHADE_6}` : props.checked ? `${colors.PRIMARY_BLUE}` : `${colors.PRIMARY_WHITE}`};
  border-radius: 2px;

  ${CheckmarkSvg /* sc-sel */} {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    opacity: ${props => (props.checked ? 1 : 0)};
    fill: ${props => (props.disabled ? `${colors.GREY_SHADE_4}` : `${colors.PRIMARY_WHITE}`)};
  }
`

export const CheckboxContentWrapper = styled.div`
  display: flex;
  flex-direction: ${props => (props.hasTitle ? 'column' : 'row')};
`

export const CheckboxLabel = styled(Label)`
  color: ${colors.PRIMARY_BLACK};
  font-weight: 300;
  margin-left: 10px;
  pointer-events: ${props => (props.disabled ? 'none' : 'auto')};
`

export const CheckboxTitle = styled(Label)`
  margin-left: 10px;
`

/** TEXTAREA */

export const TextareaContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
`

export const TextareaLabel = styled(Label)`
  margin-bottom: 7px;
`

export const TextareaDescription = styled(Description)`
  margin-bottom: 7px;
`

export const Textarea = styled.textarea`
  width: 100%;
  height: 100px;
  outline-color: ${colors.PRIMARY_BLUE};
  border-radius: 2px;
  /* stylelint-disable-next-line */
  border: 1px ${props => (props.error ? `dashed ${colors.SECONDARY_RED}` : `solid ${colors.GREY_SHADE_5}`)};
  padding: 12px 0 0 12px;
  font-family: ${fonts.PRIMARY_FONT}, sans-serif;
  font-weight: 400;
  font-size: 15px;
  /* stylelint-disable-next-line */
  background-color: ${props => (props.disabled ? `${colors.GREY_SHADE_6}` : `${colors.PRIMARY_WHITE}`)};
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  ::placeholder {
    font-family: ${fonts.PRIMARY_FONT}, sans-serif;
    color: ${colors.GREY_SHADE_3};
    font-size: 15px;
    font-weight: 400;
  }
`

export const TextareaCounter = styled.span`
  /* stylelint-disable-next-line */
  color: ${props => (props.currentCount > props.maxCount ? `${colors.SECONDARY_RED}` : `${colors.GREY_SHADE_3}`)};
  font-size: 10px;
  font-family: ${fonts.PRIMARY_FONT}, sans-serif;
  font-weight: 500;
  position: absolute;
  right: 0;
  bottom: -16px;
`

/** SELECT */

// NOTE: for Firefox & Edge compatibility
const encodeColor = color => color.replace(/#/, '%23')

export const SelectContainer = styled.div`
  display: flex;
  flex-direction: column;
`

/* prettier-ignore */
export const Select = styled.select`
  outline-color: ${colors.PRIMARY_BLUE};
  padding: 15px 0 15px 15px;
  width: 100%;
  background-color: ${colors.PRIMARY_WHITE};
  appearance: none;
  border-radius: 2px;
  /* stylelint-disable-next-line */
  border: 1px
    ${props => (props.error ? `dashed ${colors.SECONDARY_RED}` : `solid ${colors.GREY_SHADE_5}`)};
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" fill-rule="evenodd" clip-rule="evenodd" viewBox="0 0 32 32" width="8"><g transform="translate(0, 12)"><path d="M32 .9c-.32-.6-.96-.9-1.918-.9H1.918C.958 0 .319.3 0 .9 0 1.8 0 2.4.32 3l14.079 14.7c.642 0 .96.3 1.6.3.64 0 .96-.3 1.283-.602L31.362 3C32 2.4 32 1.8 32 .9" fill="${encodeColor(colors.GREY_SHADE_3)}"></path></g></svg>');
  background-repeat: no-repeat;
  background-position: center right 15px;
  font-size: 15px;
`

export const SelectOption = styled.option`
  font-family: ${fonts.PRIMARY_FONT}, sans-serif;
`

export const SelectLabel = styled(Label)`
  margin-bottom: 7px;
`

export const SelectDescription = styled(Description)`
  margin-bottom: 7px;
`
