/* eslint-disable react/prop-types */
import * as React from 'react'
import styled from 'styled-components'

import { navigableContext, useNavigableContext } from './context'
import { useNavigableList } from './useNavigableList'

const List = styled.ul`
  display: flex;
  flex-direction: column;
  list-style: none;
  margin: 0;
  padding: 0;
`

const Item = styled.li`
  display: block;
`

export function NavigableList(props) {
  // eslint-disable-next-line no-undef
  if (process.env.NODE_ENV !== 'production') {
    React.useEffect(() => {
      if ('focused' in props && 'initialFocused' in props) {
        // eslint-disable-next-line no-console
        console.error(
          new Error('<NavigableList> is both controlled and uncontrolled, use either focused or initialFocused'),
        )
      }
    }, [])
  }

  if ('focused' in props) {
    return <ControlledNavigableList {...props} />
  } else {
    return <UncontrolledNavigableList {...props} />
  }
}

function UncontrolledNavigableList({ active, onFocus, initialFocused, className, children }) {
  const [focused, setFocused] = React.useState(initialFocused)
  const handleFocus = id => {
    onFocus && onFocus(id)
    setFocused(id)
  }

  return (
    <ControlledNavigableList active={active} onFocus={handleFocus} focused={focused} className={className}>
      {children}
    </ControlledNavigableList>
  )
}

function ControlledNavigableList({ active, onFocus = () => {}, focused, className, children }) {
  const ref = React.useRef(null)
  const { useNavigableItem } = useNavigableList(ref, active, onFocus, focused)

  return (
    <List ref={ref} className={className}>
      <navigableContext.Provider value={useNavigableItem}>{children}</navigableContext.Provider>
    </List>
  )
}

export function NavigableItem({ identifier, label, disabled = false, children: Children, className }) {
  const ref = React.useRef(null)
  const useNavigableItem = useNavigableContext()
  const { focus, isFocused } = useNavigableItem({
    identifier,
    disabled,
    label,
    ref,
  })
  return (
    <Item ref={ref} className={className}>
      <Children isFocused={isFocused()} focus={focus} />
    </Item>
  )
}
