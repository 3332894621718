import * as React from 'react'
import * as PropTypes from 'prop-types'
import styled from 'styled-components'

import * as fonts from '@fonts'
import * as colors from '@colors'
import Icon from './Icon'
import LabelWithIcon from './LabelWithIcon'
import Spinner from './Spinner'

const LightIcon = styled(Icon)`
  color: ${colors.GREY_SHADE_4};
`

const Wrapper = styled.span`
  box-sizing: border-box;
  min-width: ${props => (props.loading ? '0px' : '160px')};
  height: 48px;
  flex: 0 0 auto;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-family: ${fonts.PRIMARY_FONT};
  font-size: 13px;
  font-weight: 500;
  cursor: pointer;
  color: ${props => colors.primary(props)};
  background-color: ${colors.PRIMARY_WHITE};
  border-radius: 2px;
  border: 2px solid;
  border-color: ${props => colors.primary(props)};
  padding: 0 16px;
  pointer-events: ${props => (props.loading ? 'none' : 'auto')};
  transition: min-width 250ms ease-in-out;

  ${LightIcon /* sc-sel */} {
    transform: rotate(-180deg);
    transition: transform 200ms ease-out;
  }

  &:hover {
    color: ${props => colors.primaryHover(props)};
    border-color: ${props => colors.primaryHover(props)};

    ${LightIcon /* sc-sel */} {
      transition: transform 400ms ease-in;
      transform: rotate(180deg);
    }
  }
`

/** This component should be used to trigger fetching more items in a list */
const LoadMoreButton = ({ loading, error, onClick, translations }) => {
  let label

  if (error) {
    label = <LabelWithIcon icon={<LightIcon glyph="refresh" size={16} />} label={translations['errorLabel']} />
  } else if (loading) {
    label = <Spinner small />
  } else {
    label = translations['initialLabel']
  }

  return (
    <Wrapper onClick={onClick} loading={loading}>
      {label}
    </Wrapper>
  )
}

LoadMoreButton.defaultProps = {
  loading: false,
  error: false,
}

LoadMoreButton.propTypes = {
  /** controls loading mode of component */
  loading: PropTypes.bool,
  /** controls error mode of component */
  error: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
  translations: PropTypes.shape({
    initialLabel: PropTypes.string.isRequired,
    loadingLabel: PropTypes.string,
    errorLabel: PropTypes.string.isRequired,
  }).isRequired,
}

export default LoadMoreButton
