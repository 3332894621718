/* eslint-disable react/prop-types */
import * as React from 'react'
import styled from 'styled-components'

import * as behaviour from '../../../behaviours/index'
import * as colors from '@colors'
import { SelectButton } from './SelectButton'

const SelectWrapper = styled(behaviour.Select)``

const Dropdown = styled.div`
  background-color: ${colors.PRIMARY_WHITE};
  box-shadow: 1px 2px 3px 1px rgba(0, 0, 0, 0.12);
`

export function Select({
  selected,
  initialSelected,
  children,
  className,
  multiple,
  onChange,
  onClose,
  height,
  placeholder,
}) {
  return (
    <SelectWrapper
      selected={selected}
      initialSelected={initialSelected}
      height={height}
      className={className}
      multiple={multiple}
      onChange={onChange}
      onClose={onClose}
      renderSelect={({ selected }) => <SelectButton label={label(selected, placeholder || '')} />}
      renderDrawer={({ children }) => <Dropdown>{children}</Dropdown>}
    >
      {children}
    </SelectWrapper>
  )
}

function label(selected, placeholder) {
  return selected.length === 0 ? placeholder : selected.map(option => option.label).join(', ')
}
