import styled from 'styled-components'
import * as colors from '@colors'
import * as bp from '@bp'
import * as heading from './heading'
import * as image from './image'

export const CardWrapper = styled.a`
  align-items: center;
  border: 1px solid ${colors.GREY_SHADE_5};
  box-sizing: border-box;
  color: ${colors.PRIMARY_BLACK};
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  overflow: hidden;
  padding: 10px;
  position: relative;
  text-decoration: none;
  max-width: 180px;

  &:hover {
    transition: all 200ms ease-in-out;
    box-shadow: 2px 2px 14px ${colors.GREY_SHADE_5};
  }

  ${image.Avatar} {
    height: auto;
    max-width: 160px;
    min-width: 100px;
    width: 160px;
  }
`

CardWrapper.displayName = 'S.userCard.CardWrapper'

export const CardName = styled(heading.Card1)`
  display: block;
  margin-top: 10px;
  overflow: hidden;
  text-align: center;
  text-overflow: ellipsis;
  white-space: nowrap;
`

CardName.displayName = 'S.userCard.CardName'
