import * as React from 'react'

import { NavigableListApi } from './types'

export type NavigableContext<IDENTIFIER> = NavigableListApi<IDENTIFIER>['register']

const navigableContext = React.createContext<NavigableContext<any>>(() => {
  throw Error('NavigableItem outside of NavigableList')
})

export function useNavigableContext<IDENTIFIER>(): NavigableContext<IDENTIFIER> {
  return React.useContext(navigableContext)
}

export const NavigableContextProvider = navigableContext.Provider
