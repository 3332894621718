import * as React from 'react'
import * as PropTypes from 'prop-types'
import styled from 'styled-components'

import * as colors from '@colors'

export const StyledPastille = styled.span`
  background-color: ${props => (props.color ? props.color : colors.primary(props))};
  display: flex;
  justify-content: center;
  align-items: center;
  width: 120px;
  height: 120px;
  border-radius: 50%;
  border: 5px solid ${colors.PRIMARY_WHITE};

  img {
    max-width: 80px;
    width: 100%;
  }
`

const Pastille = props => <StyledPastille {...props} />

Pastille.propTypes = {
  children: PropTypes.node.isRequired,
  as: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
}

export default Pastille
