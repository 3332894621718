import * as React from 'react'
import styled from 'styled-components'
import * as PropTypes from 'prop-types'

import * as colors from '@colors'
import * as fonts from '@fonts'

import Input from '../form/Input'
import LoadMoreButton from '../LoadMoreButton'
import { Title6 } from '../Titles'

// NOTE: for Firefox & Edge compatibility
const encodeColor = color => color.replace(/#/, '%23')

/* prettier-ignore */
const Option = styled.li`
  position: relative;
  margin: 0;
  list-style-type: none;
  padding: 10px;
  font-size: 13px;
  color: ${props => (props.selected ? colors.primary(props) : colors.PRIMARY_BLACK)};
  font-family: ${fonts.PRIMARY_FONT}, sans-serif;
  font-weight: 500;
  cursor: pointer;

  &:hover {
    color: ${colors.PRIMARY_BLUE};
  }

  ::before {
    content: '';
    background-color: ${colors.PRIMARY_BLUE};
    background-image: url('data:image/svg+xml;utf8,<svg width="8" height="6" xmlns="http://www.w3.org/2000/svg"><path d="M3.4214 3.7186c-.4942-.4935-.9652-.9657-1.4362-1.4355-.2309-.2303-.449-.2303-.6788-.0047a56.9675 56.9675 0 0 0-.4976.4901c-.2309.228-.2309.4699-.0023.699.666.6654 1.3309 1.3303 1.9957 1.9951.1007.1007.199.2031.3026.302.184.1766.4132.18.6047.0076.0243-.0214.0446-.0428.0677-.0648 1.1365-1.137 2.2729-2.2729 3.41-3.411a.775.775 0 0 0 .0688-.0758c.1464-.1777.1423-.4132-.015-.5764a18.8792 18.8792 0 0 0-.5926-.5867c-.177-.1684-.3946-.1643-.5798-.004a1.2406 1.2406 0 0 0-.0683.0653c-.8326.831-1.6659 1.6613-2.498 2.4945-.0289.0295-.0503.0654-.081.1053" fill="${encodeColor(colors.PRIMARY_WHITE)}" fill-rule="evenodd"/></svg>');
    background-repeat: no-repeat;
    background-position: center center;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    position: absolute;
    left: 0;
    top: 50%;
    transform: translate(-50%, -50%);
    display: ${props => (props.selected ? 'block' : 'none')};
  }
`

const ActionsButton = styled.span`
  color: ${colors.GREY_SHADE_3};
  font-family: ${fonts.PRIMARY_FONT};
  font-size: 13px;
  font-weight: 500;
  display: flex;
  padding: 10px 10px 10px 0;
  cursor: pointer;
`

const List = styled.ul`
  display: flex;
  flex-flow: column nowrap;
  max-width: 100%;
  margin: 0;
  padding: 0;
`

export const Options = styled.div``

const LabelGroup = styled.li`
  display: flex;
  flex-direction: column;
  margin-top: 10px;

  &:first-of-type {
    margin-top: 0;
  }

  ${Option} {
    padding-left: 15px;
  }
`

const LabelGroupLabel = styled(Title6)`
  margin-bottom: 0;
`

const Separator = styled.hr`
  height: 1px;
  color: ${colors.GREY_SHADE_3};
  background-color: ${colors.GREY_SHADE_3};
  border: none;
`

const LoadMoreButtonWrapper = styled.div`
  > span {
    margin: 5px 10px 0;
  }
`

class ListFilter extends React.Component {
  state = {
    search: '',
  }

  /**
   * Helper to check if the value is selected in the list
   *
   * @param string
   * @memberof ListFilter
   */
  isSelected = currentValue => {
    const { multiselect, selected } = this.props

    if (multiselect) {
      const index =
        selected && selected.findIndex(selectedOption => selectedOption && selectedOption.value === currentValue)

      if (index > -1) {
        return true
      }

      return false
    }

    return selected && selected[0] && selected[0].value === currentValue
  }

  renderGroupedList = () => {
    const { options, handleClickOption } = this.props

    return (
      <React.Fragment>
        {options &&
          options.length > 0 &&
          options.map((option, key) => {
            return (
              <LabelGroup key={key}>
                <LabelGroupLabel>{option.groupLabel}</LabelGroupLabel>
                {option && option.items && option.items.length > 0 && (
                  <ul>
                    {option.items.map((objectOption, index) => {
                      return (
                        <Option
                          key={index}
                          data-value={objectOption.value}
                          onClick={handleClickOption}
                          selected={this.isSelected(objectOption.value)}
                        >
                          {objectOption.label}
                        </Option>
                      )
                    })}
                  </ul>
                )}
              </LabelGroup>
            )
          })}
      </React.Fragment>
    )
  }

  renderActions = () => {
    const { handleClearAll, handleSelectAll } = this.props

    return (
      <div>
        <ActionsButton onClick={handleSelectAll}>Select All</ActionsButton>
        <ActionsButton onClick={handleClearAll}>Clear All</ActionsButton>
        <Separator />
      </div>
    )
  }

  renderSearchInput = () => {
    const { name } = this.props
    const { search } = this.state

    return (
      <Input name={`name-${name}`} onChange={this.handleSearchChange} value={search} type="text" placeholder="Search" />
    )
  }

  handleSearchChange = search => {
    const { handleSearch } = this.props
    this.setState({ search })

    if (typeof handleSearch === 'function') {
      handleSearch(search)
    }
  }

  resetSearch = () => this.handleSearchChange('')

  render() {
    const {
      isGrouped,
      options,
      actions,
      hasSearch,
      handleClickOption,
      loadMore,
      translation,
      onLoadMore,
      loading,
    } = this.props

    return (
      <List>
        {hasSearch && this.renderSearchInput()}
        {actions && this.renderActions()}
        <Options>
          {isGrouped && this.renderGroupedList()}
          {!isGrouped &&
            options &&
            options.map((option, key) => {
              return (
                <Option
                  key={`option-${key}`}
                  data-value={option.value}
                  onClick={handleClickOption}
                  selected={this.isSelected(option.value)}
                >
                  {option.label}
                </Option>
              )
            })}

          {loadMore && (
            <LoadMoreButtonWrapper>
              <LoadMoreButton translations={translation.loadMore} loading={loading} onClick={onLoadMore} />
            </LoadMoreButtonWrapper>
          )}
        </Options>
      </List>
    )
  }
}

ListFilter.propTypes = {
  /** array of objects to display */
  options: PropTypes.array.isRequired,
  /** name used for options key */
  name: PropTypes.string.isRequired,
  /** used to know if options are grouped by */
  isGrouped: PropTypes.bool,
  /** used if you need the search input */
  hasSearch: PropTypes.bool,
  /** activate the multiselect option */
  multiselect: PropTypes.bool,
  /** default value of options selected */
  selected: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    }),
  ),
  /** activate actions: Select All, Clear All. Only useful with multiselect */
  actions: PropTypes.bool,
  /** function that retrive data on click on the options */
  handleClickOption: PropTypes.func.isRequired,
  /** Listener on the input to retrieve searched options */
  handleSearch: PropTypes.func,
  /** action to select all the displayed options ex: if you have searched it will only select the searched one */
  handleSelectAll: PropTypes.func,
  /** action to clear all the selected items */
  handleClearAll: PropTypes.func,
  /** display loadMore button */
  loadMore: PropTypes.bool,
  /** loadMore button loading state */
  loading: PropTypes.bool,
  /** action on click on loadMore button */
  onLoadMore: PropTypes.func,
  /** object of string used for translation */
  translation: PropTypes.shape({
    loadMore: PropTypes.shape({
      initialLabel: PropTypes.string,
      errorLabel: PropTypes.string,
    }),
  }),
}

export default ListFilter
