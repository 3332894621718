import * as React from 'react'
import * as PropTypes from 'prop-types'

import * as S from './styles'
import { Detail } from './Label'

/** Textarea component used for multilines explanation/description. */
class Textarea extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      value: props.initialValue || '',
      currentCount: 0,
    }
  }

  handleChange = event => {
    const { onChange, onChangeEvent } = this.props

    if (onChangeEvent && typeof onChangeEvent === 'function') {
      onChangeEvent(event)
    }

    this.setState(
      {
        value: event.currentTarget.value,
        currentCount: event.currentTarget.value.length,
      },
      () => {
        if (onChange && typeof onChange === 'function') {
          onChange(this.state.value)
        }
      },
    )
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.value !== undefined && this.props.value !== prevState.value) {
      this.setState({ value: this.props.value })
    }
  }

  render() {
    const {
      className,
      placeholder,
      label,
      description,
      maxCount,
      error,
      name,
      disabled,
      success,
      onChange, // eslint-disable-line
      initialValue, // eslint-disable-line
      value: valueProp, // eslint-disable-line
      ...props
    } = this.props

    const { value, currentCount } = this.state

    return (
      <React.Fragment>
        <S.TextareaContainer>
          {label && <S.TextareaLabel htmlFor={name}>{label}</S.TextareaLabel>}
          {description && <S.TextareaDescription>{description}</S.TextareaDescription>}
          <S.Textarea
            className={className}
            error={error}
            onChange={this.handleChange}
            placeholder={placeholder}
            value={value}
            name={name}
            id={name}
            disabled={disabled}
            {...props}
          />
          {maxCount >= 0 && (
            <S.TextareaCounter currentCount={currentCount} maxCount={maxCount}>
              {currentCount} / {maxCount}
            </S.TextareaCounter>
          )}
        </S.TextareaContainer>
        {error && !success && <Detail error={!!error}>{error}</Detail>}
        {success && !error && <Detail success={!!success}>{success}</Detail>}
      </React.Fragment>
    )
  }
}

Textarea.propTypes = {
  className: PropTypes.string,
  /** used for accessibility */
  name: PropTypes.string.isRequired,
  /** used for prefetched data */
  initialValue: PropTypes.string,
  /** used to update the textarea value from outside */
  value: PropTypes.string,
  /** default displayed text */
  placeholder: PropTypes.string,
  /** label for element */
  label: PropTypes.string,
  /** purpose of this element */
  description: PropTypes.string,
  /** mandatory function to retrieve value */
  onChange: PropTypes.func.isRequired,
  /** function to retrieve change event */
  onChangeEvent: PropTypes.func,
  /** success message */
  success: PropTypes.string,
  /** error message */
  error: PropTypes.string,
  /** disable the option */
  disabled: PropTypes.bool,
  /** browser will display an error if input is empty on form submitting */
  required: PropTypes.bool,
  /** max possible characters */
  maxCount: PropTypes.number,
}

export default Textarea
Textarea.displayName = 'legacy.Textarea'
