import * as React from 'react'
import styled from 'styled-components'
import * as PropTypes from 'prop-types'
import Icon from './Icon'

import * as colors from '@colors'
import * as fonts from '@fonts'
import ClickOutside from '../core/ClickOutside'

const TabsListContainer = styled.div`
  opacity: ${props => (props.show ? 1 : 0)};
  padding: 0;
  display: flex;
  margin: 0;
  position: relative;
  max-width: 100%;
  width: 100%;
  height: 64px;
`

const TabsList = styled.ul`
  display: flex;
  list-style: none;
  align-items: center;
  padding: 0;
  margin: 0;
  position: relative;
  flex-wrap: wrap;
  height: 100%;
`

const TabsItemContainer = styled.span`
  border-bottom: 2px solid transparent;
  height: 100%;
  display: flex;
  align-items: center;

  &:hover {
    border-bottom: 2px solid ${colors.GREY_SHADE_4};
  }
`

const TabsItem = styled.li`
  display: flex;
  align-items: center;
  height: 100%;
  color: #000;
  padding: 0;
  font-size: 13px;
  text-decoration: none;
  cursor: pointer;
  font-family: ${fonts.PRIMARY_FONT};
  font-weight: 500;
  padding-right: 40px;
`

const TabsItemDropdown = styled(TabsItem)`
  width: ${props => props.maxWidth}px;

  a {
    :hover {
      color: ${colors.PRIMARY_BLUE};
    }
  }
`

const TabsDropdown = styled.ul`
  background-color: ${colors.PRIMARY_WHITE};
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  position: absolute;
  top: 50px;
  right: 30px;
  margin: 0;
  padding: 0;
  z-index: 9999;

  ${TabsItem} {
    padding: 16px 16px;
    border-bottom: none;

    :hover {
      border-bottom: none;
    }
  }
`

const TabsDropdownIcon = styled(Icon)`
  cursor: pointer;
  color: ${colors.GREY_SHADE_4};

  &:hover {
    color: ${colors.GREY_SHADE_3};
  }
`

const TabsIconWrapper = styled.span`
  display: flex;
  align-items: center;
  position: relative;
`

const TabsIconContainer = styled.span`
  padding: 0 16px;
  height: 100%;
  display: flex;
  align-items: center;
  flex: 0;
`

class Tabs extends React.Component {
  constructor(props) {
    super(props)

    this.nbChildren = React.Children.toArray(props.children).length

    this.state = {
      shouldRender: false,
      shouldDisplayKebabMenu: true,
      overflowIndex: this.nbChildren - 1,
      dropdownIsOpen: false,
    }

    this.setTabsListRef = element => {
      this.list = element
    }
    this.setKebabMenuRef = element => {
      this.kebabMenu = element
    }
    this.itemsWidth = []
  }

  componentDidMount = () => {
    this.handleOverflow()
    window.addEventListener('resize', this.handleOverflow)
  }

  componentDidUpdate(prevProps) {
    if (prevProps.maxVisibleItems !== this.props.maxVisibleItems) {
      this.handleOverflow()
    }
  }

  getTotalItemsWidth = () => {
    return this.itemsWidth.reduce((acc, val) => acc + val, 0)
  }

  handleOverflow = () => {
    this.kebabWidth = this.getKebabWidth()
    this.containerWidth = this.getContainerWidth()
    const totalItemsWidth = this.getTotalItemsWidth()
    const { maxVisibleItems } = this.props
    // create an overflow if maxVisibleItems is present
    if (maxVisibleItems && maxVisibleItems > 0 && maxVisibleItems < this.nbChildren) {
      const overflowIndex = this.getOverflowIndex(this.kebabWidth, this.containerWidth)
      this.setState({
        shouldDisplayKebabMenu: true,
        overflowIndex: overflowIndex > maxVisibleItems - 1 ? maxVisibleItems - 1 : overflowIndex,
      })
    } else if (totalItemsWidth > this.containerWidth) {
      // natural overflow with width
      const overflowIndex = this.getOverflowIndex(this.kebabWidth, this.containerWidth)

      this.setState({
        shouldDisplayKebabMenu: true,
        overflowIndex: overflowIndex > maxVisibleItems - 1 ? maxVisibleItems - 1 : overflowIndex,
      })
    } else {
      // no overflow
      this.setState({
        shouldDisplayKebabMenu: false,
        overflowIndex: this.nbChildren - 1,
      })
    }

    this.setState({
      shouldRender: true,
    })
  }

  handleItemWidth = element => {
    if (element) {
      this.itemsWidth.push(element.offsetWidth)
    }
  }

  getKebabWidth = () => {
    if (this.kebabMenu) {
      const { width } = this.kebabMenu.getBoundingClientRect()
      return width
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleOverflow)
  }

  getContainerWidth = () => {
    if (this.list) {
      const { width } = this.list.getBoundingClientRect()
      return width
    }
  }

  renderChildren = () => {
    const { overflowIndex } = this.state
    const tab = []

    React.Children.forEach(this.props.children, (child, index) => {
      if (index <= overflowIndex) {
        tab.push(
          <TabsItem key={index} ref={this.handleItemWidth} onClick={this.handleClose}>
            <TabsItemContainer>{React.cloneElement(child)}</TabsItemContainer>
          </TabsItem>,
        )
        return
      }
    })
    return tab
  }

  renderDropdown = () => {
    const { overflowIndex } = this.state
    const dropdownItemsWidth = this.itemsWidth.slice(overflowIndex + 1)
    const longestItem = Math.max(...dropdownItemsWidth)

    return (
      <TabsDropdown>
        {React.Children.map(this.props.children, (child, index) => {
          if (index <= overflowIndex) return null
          return (
            <TabsItemDropdown maxWidth={longestItem} onClick={this.handleClick}>
              {child}
            </TabsItemDropdown>
          )
        })}
      </TabsDropdown>
    )
  }

  getOverflowIndex = (initWidth, maxWidth) => {
    if (this.itemsWidth) {
      let counter = initWidth

      let maxIndex = React.Children.toArray(this.props.children).length

      for (let index = 0; index < maxIndex; index++) {
        counter = counter + this.itemsWidth[index]

        if (counter >= maxWidth) {
          maxIndex = index - 1
          break
        }
      }
      return maxIndex
    }
  }

  handleClick = () => {
    this.setState({
      dropdownIsOpen: this.state.dropdownIsOpen ? false : true,
    })
  }

  handleClose = () => {
    this.setState({
      dropdownIsOpen: false,
    })
  }

  render() {
    const { dropdownIsOpen, shouldDisplayKebabMenu, shouldRender } = this.state
    return (
      <TabsListContainer ref={this.setTabsListRef} {...this.props} show={shouldRender}>
        <TabsList>{this.renderChildren()}</TabsList>
        {shouldDisplayKebabMenu && (
          <TabsIconWrapper>
            <TabsIconContainer ref={this.setKebabMenuRef}>
              <TabsDropdownIcon onClick={this.handleClick} size={32} glyph="kebab-menu" />
              {dropdownIsOpen && <ClickOutside onClickOutside={this.handleClose}>{this.renderDropdown()}</ClickOutside>}
            </TabsIconContainer>
          </TabsIconWrapper>
        )}
      </TabsListContainer>
    )
  }
}

Tabs.propTypes = {
  /** routes that will be applied */
  children: PropTypes.node.isRequired,
  /** number of items applied if you have enough space */
  maxVisibleItems: PropTypes.number,
}

export default Tabs
