import * as React from 'react'

import { useLoseFocus } from '../../hooks/useLoseFocus'

import { reducer } from './reducer'

export function useSelect(selectBoxRef, selected, handleClose, handleChange, multiple) {
  const selectedRef = React.useRef(selected)
  selectedRef.current = selected

  const selectedHead = selected[0]
  const [state, dispatch] = React.useReducer(reducer, {
    registered: [],
    open: false,
    focused: selectedHead,
  })

  React.useEffect(() => {
    window.addEventListener('keydown', handleKeyDown)

    return () => {
      window.removeEventListener('keydown', handleKeyDown)
    }
  }, [state.open, state.focused, selected])

  useLoseFocus(
    selectBoxRef,
    () => {
      if (state.open) {
        close()
      }
    },
    [state.open],
  )

  return {
    useOption,
    isOpen: state.open,
    open: () => dispatch({ type: 'open', focused: selectedHead }),
    close,
    clear: () => {
      selected.forEach(value => {
        const option = state.registered.find(option => option.value === state.focused)
        if (option === undefined) {
          return
        }
        option.handleToggle(false, value)
      })
      handleChange([])
    },
    focus: focused => {
      dispatch({ type: 'focus', focused })
    },
    selectedOptions: state.registered.filter(option => selected.includes(option.value)),
    focused: state.focused,
  }

  function handleKeyDown(event) {
    if (!selectBoxRef.current || !selectBoxRef.current.contains(document.activeElement)) {
      return
    }

    if (!state.open) {
      if (event.key === 'ArrowUp' || event.key === 'ArrowDown') {
        return dispatch({ type: 'open', focused: selectedHead })
      }
    } else {
      if (event.key === 'Escape') {
        event.preventDefault()
        close()
        return
      }
      if (event.key === 'Enter') {
        event.preventDefault()
        if (state.focused === undefined) {
          return
        }
        const option = state.registered.find(option => option.value === state.focused)
        if (option !== undefined) {
          handleOptionToggle(selectedRef.current, option)
        }
        return
      }
    }
  }

  function useOption(option) {
    React.useEffect(() => {
      dispatch({ type: 'register', option })
      return () => {
        if (!isSelected(selectedRef.current, option)) {
          dispatch({ type: 'deregister', value: option.value })
        }
      }
    }, [option.disabled])

    return {
      toggle: () => {
        handleOptionToggle(selectedRef.current, option)
      },
      isSelected: () => isSelected(selectedRef.current, option),
      multiple,
    }
  }

  function isSelected(selected, option) {
    return selected.some(value => value === option.value)
  }

  function close() {
    if (state.open) {
      handleClose(selected)
      dispatch({ type: 'close' })
    }
  }

  function handleOptionToggle(selected, option) {
    if (option.disabled) {
      return
    }
    const isOptionSelected = isSelected(selected, option)
    if (multiple) {
      if (isOptionSelected) {
        const index = selected.findIndex(value => value === option.value)
        handleChange([...selected.slice(0, index), ...selected.slice(index + 1)])
        option.handleToggle(false, option.value)
      } else {
        handleChange([...selected, option.value])
        option.handleToggle(true, option.value)
      }
      close()
    } else {
      if (!isOptionSelected) {
        const selectedOption = state.registered.find(option => option.value === selected[0])
        if (selectedOption !== undefined) {
          selectedOption.handleToggle(false, selected[0])
        }
        handleChange([option.value])
        option.handleToggle(true, option.value)
      }
      close()
    }
  }
}
