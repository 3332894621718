import * as React from 'react'
import * as PropTypes from 'prop-types'

class ClickOutside extends React.Component {
  constructor(props) {
    super(props)
    this.isTouch = false
  }

  componentDidMount() {
    document.addEventListener('touchend', this.handleClick, true)
    document.addEventListener('click', this.handleClick, true)
  }

  componentWillUnmount() {
    document.removeEventListener('touchend', this.handleClick, true)
    document.removeEventListener('click', this.handleClick, true)
  }

  handleClick = event => {
    const el = this.container

    if (event.type === 'touchend') {
      this.isTouch = true
    }

    if (event.type === 'click' && this.isTouch) {
      return
    }

    if (!el.contains(event.target)) {
      this.props.preventDefault && event.preventDefault()
      this.props.stopPropagation && event.stopPropagation()
      this.props.onClickOutside(event)
    }
  }

  render() {
    const {
      children,
      onClickOutside: _a, // eslint-disable-line no-unused-vars
      preventDefault: _b, // eslint-disable-line no-unused-vars
      stopPropagation: _c, // eslint-disable-line no-unused-vars
      ...props
    } = this.props
    return (
      <div {...props} ref={ref => (this.container = ref)}>
        {children}
      </div>
    )
  }
}

ClickOutside.defaultProps = {
  preventDefault: true,
  stopPropagation: true,
}

ClickOutside.propTypes = {
  preventDefault: PropTypes.bool,
  stopPropagation: PropTypes.bool,
  onClickOutside: PropTypes.func.isRequired,
  children: PropTypes.any.isRequired,
}

export default ClickOutside
