import * as React from 'react'
import styled from 'styled-components'

import { Chf, Dolls, Eur, Kr, Pound } from './collections/Currencies'
import { SpecificIconProps } from './types'

type CurrencyProps = SpecificIconProps & {
  currency: string
}

function CurrencyComponent({ currency, ...iconProps }: CurrencyProps): React.ReactElement<CurrencyProps> {
  switch (currency) {
    case 'EUR': {
      return <Eur {...iconProps} />
    }
    case 'CHF': {
      return <Chf {...iconProps} />
    }
    case 'DKK':
    case 'NOK':
    case 'SEK': {
      return <Kr {...iconProps} />
    }
    case 'GBP': {
      return <Pound {...iconProps} />
    }
    default: {
      return <Dolls {...iconProps} />
    }
  }
}

export const Currency = styled(CurrencyComponent)``
