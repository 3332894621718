import * as React from 'react'
import * as PropTypes from 'prop-types'
import styled from 'styled-components'

import * as bp from '@bp'
import * as fonts from '@fonts'
import * as colors from '@colors'

const FunTitle = styled.span`
  display: inline-flex;
  font-family: ${fonts.EMPHASIS_FONT};
  font-size: 38px;
  font-weight: 500;
  text-transform: uppercase;
  font-style: italic;
  transform: rotate(-5deg);
  text-align: center;
`

const Wrapper = styled.div`
  max-width: 300px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: ${colors.PRIMARY_BLACK};

  img {
    display: none;
  }

  @media screen and ${bp.TABLET} {
    img {
      display: inline-flex;
      flex: 0;
      max-width: 165px;
      max-height: 165px;
    }
  }
`

const IllustratedTitle = ({ src, srcSet, title, className }) => (
  <Wrapper className={className}>
    <img src={src} srcSet={srcSet} alt="" />
    <FunTitle>{title}</FunTitle>
  </Wrapper>
)

IllustratedTitle.propTypes = {
  title: PropTypes.string.isRequired,
  className: PropTypes.string,
  src: PropTypes.string.isRequired,
  srcSet: PropTypes.string,
}

export default IllustratedTitle
IllustratedTitle.displayName = 'legacy.IllustratedTitle'
