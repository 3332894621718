import * as React from 'react'
import * as PropTypes from 'prop-types'

import * as S from './styles'

const Checkmark = ({ checked, disabled }) => (
  <S.CheckmarkWrapper checked={checked} disabled={disabled}>
    <S.CheckmarkSvg width="10" height="10" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M3.4963 6.1035L8.3098 1.29c.3867-.3867 1.0135-.3867 1.4002 0 .3867.3867.3867 1.0135 0 1.4002L4.1964 8.2038c-.3867.3867-1.0136.3867-1.4002 0L.29 5.6976c-.3867-.3866-.3867-1.0135 0-1.4002.3867-.3866 1.0135-.3866 1.4002 0l1.806 1.8061z"
        fillRule="nonzero"
      />
    </S.CheckmarkSvg>
  </S.CheckmarkWrapper>
)

Checkmark.propTypes = {
  disabled: PropTypes.bool,
  checked: PropTypes.bool,
}

/** Checkbox component used for multiple choices. */
class Checkbox extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      checked: this.props.defaultChecked,
    }
    this.inputRef = React.createRef(null)
  }

  handleClick = () => {
    const { onChange, checkOnClick } = this.props

    if (checkOnClick && this.inputRef.current) {
      this.inputRef.current.click()
    }

    this.setState(
      {
        checked: !this.state.checked,
      },
      () => {
        if (onChange && typeof onChange === 'function') {
          onChange(this.state.checked ? this.props.value : '')
        }
      },
    )
  }

  handleChange = event => {
    const { onChangeEvent, disabled } = this.props

    if (onChangeEvent && typeof onChangeEvent === 'function' && !disabled) {
      onChangeEvent(event)
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.checked !== undefined && this.props.checked !== prevState.checked) {
      this.setState({ checked: this.props.checked })
    }
  }

  render() {
    const {
      className,
      disabled,
      label,
      value,
      name,
      title,
      onChange, // eslint-disable-line
      checkOnClick, // eslint-disable-line
      ...props
    } = this.props
    const { checked } = this.state

    return (
      <S.CheckboxContainer className={className} hasTitle={title} onClick={disabled ? undefined : this.handleClick}>
        <S.Checkbox
          ref={this.inputRef}
          type="checkbox"
          value={value}
          name={name}
          onChange={this.handleChange}
          {...props}
        />
        <Checkmark checked={checked} disabled={disabled} />
        <S.CheckboxContentWrapper hasTitle={title}>
          {title && <S.CheckboxTitle htmlFor={name}>{title}</S.CheckboxTitle>}
          <S.CheckboxLabel htmlFor={name}>{label}</S.CheckboxLabel>
        </S.CheckboxContentWrapper>
      </S.CheckboxContainer>
    )
  }
}
Checkbox.displayName = 'legacy.Checkbox'
Checkbox.propTypes = {
  className: PropTypes.string,
  /** name attribute of checkbox element */
  name: PropTypes.string.isRequired,
  /** label for radio button */
  label: PropTypes.string,
  /** value of the checkbox */
  value: PropTypes.string.isRequired,
  /** allow to init checkbox with correct state */
  defaultChecked: PropTypes.bool,
  /** disable the option */
  disabled: PropTypes.bool,
  /** browser will display an error if input is empty on form submitting */
  required: PropTypes.bool,
  /** mandatory function to retrieve value */
  onChange: PropTypes.func,
  /** function to retrieve change event */
  onChangeEvent: PropTypes.func,
  /** set or unset the checked style */
  checked: PropTypes.bool,
  /** title of the checkbox */
  title: PropTypes.string,
  /** whether the underlying checkbox should be checked on click */
  checkOnClick: PropTypes.bool,
}

export default Checkbox
