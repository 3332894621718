import * as React from 'react'
import * as PropTypes from 'prop-types'

import Icon from '@legacy/Icon'

/** This is a logical icon component which evolve in function of \`progress\` property. */
const ProgressEgg = ({ className: cls, progress, size }) => (
  <React.Fragment>
    {progress >= 100 && <Icon className={cls} glyph="owl-egg-100" size={size} />}
    {progress >= 60 && progress < 100 && <Icon className={cls} glyph="owl-egg-60" size={size} />}
    {progress >= 42 && progress < 60 && <Icon className={cls} glyph="owl-egg-42" size={size} />}
    {progress >= 15 && progress < 42 && <Icon className={cls} glyph="owl-egg-15" size={size} />}
    {progress < 15 && <Icon className={cls} glyph="owl-egg-0" size={size} />}
  </React.Fragment>
)

ProgressEgg.defaultProps = {
  progress: 0,
  size: 16,
}

ProgressEgg.propTypes = {
  className: PropTypes.string,
  /** percentage of progress */
  progress: PropTypes.number,
  /** size in pixel */
  size: PropTypes.number,
}

export default ProgressEgg
