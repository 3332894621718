import * as React from 'react'
import * as PropTypes from 'prop-types'
import styled from 'styled-components'

import * as fonts from '@fonts'

const CategoryCardBullet = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: ${props => props.color};
  width: 80px;
  height: 80px;

  > img {
    display: block;
    width: 45%;
  }
`

const CategoryCardTitle = styled.span`
  color: ${props => props.color};
  font-family: ${fonts.PRIMARY_FONT};
  text-align: center;
  font-size: 13px;
  font-weight: 500;
  line-height: 18px;
  margin-top: 20px;
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`

const CategoryCardWrapper = styled.a`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  text-decoration: none;
  overflow: hidden;
  transition: opacity 250ms ease-out;

  &:hover {
    opacity: 0.9;
  }
`

const CategoryCard = ({ color, title, icon, absoluteUrl }) => (
  <CategoryCardWrapper href={absoluteUrl}>
    <CategoryCardBullet color={color}>
      <img src={icon} alt={title} />
    </CategoryCardBullet>
    <CategoryCardTitle color={color}>{title}</CategoryCardTitle>
  </CategoryCardWrapper>
)

CategoryCard.propTypes = {
  /** url target on click */
  absoluteUrl: PropTypes.string,
  /** main color of component */
  color: PropTypes.string,
  /** name of the category */
  title: PropTypes.string,
  /** url of SVG icon related to the category */
  icon: PropTypes.string,
}

export default CategoryCard
CategoryCard.displayName = 'legacy.CategoryCard'
