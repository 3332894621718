import * as React from 'react'
import * as PropTypes from 'prop-types'
import styled from 'styled-components'

import { CarouselButton, CarouselButtonLeft, CarouselButtonRight } from './CarouselButton'

const PADDING = 10
const TRANSITION_DURATION = 150
const OVERFLOW_SPACE = 30

const CarouselTrack = styled.div`
  width: ${props => `${props.size.width}px` || '100%'};
  height: ${props => `${props.size.height}px` || '100%'};
  overflow: hidden;
`

const CarouselList = styled.ul`
  width: 100%;
  height: ${props => `${props.size.height}px`};
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  transform: ${props => `translate3d(${props.currentScroll}px, 0, 0)`};
  perspective: 1000;
  transition: ${props => (props.fade ? `transform ${TRANSITION_DURATION}ms ease-in` : undefined)};
`

const CarouselListItems = styled.li`
  padding: ${PADDING}px;
`

const CarouselWrapper = styled.div`
  position: relative;
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: ${props => (props.displayNav ? 'space-between' : 'center')};

  ${CarouselButton} {
    flex: 0 0 auto;
  }

  ${CarouselList} {
    flex: 1;
  }
`

const defer = (callback, duration) => setTimeout(callback, duration)

/** Carousel is useful when you want expose multiple contents and you don't have enough space on your page. It's like a sofabed in a studio apartment... it's convenient but not very comfortable.
 */
class Carousel extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      itemSize: {
        width: 0,
        height: 0,
      },
      listSize: {
        width: 0,
        height: 0,
      },
      trackSize: {
        width: 0,
        height: 0,
      },
      currentScroll: 0,
      maxScroll: 0,
      maxTrackWidth: 0,
      extraOverflow: 0,
      touchX: null,
      touchY: null,
      isMoving: false,
      displayPrevNav: false,
      displayNextNav: false,
      mounted: false,
    }

    this.wrapper = null
    this.nav = null
    this.track = null
    this.list = null
    this.interval = null
  }

  getWrapperRef = ref => (this.wrapper = ref)

  getNavRef = ref => (this.nav = ref)

  getTrackRef = ref => (this.track = ref)

  getListRef = ref => (this.list = ref)

  updateDimensions = () => {
    const itemSize = { width: 0, height: 0 }
    // HACK: if there is a single child, Safari can't size properly element
    if (this.list && this.list.firstChild && React.Children.count(this.props.children) === 1) {
      itemSize.height = this.list.firstChild.firstChild.clientHeight + 2 * PADDING || 0
      itemSize.width = this.list.firstChild.firstChild.clientWidth + 2 * PADDING || 0
    } else {
      itemSize.height = this.list.firstChild.clientHeight || 0
      itemSize.width = this.list.firstChild.clientWidth || 0
    }

    let navWidth = 0
    if (this.nav) {
      navWidth = this.nav.clientWidth
    }

    // Define the overflow if needed
    const extraOverflow = this.props.displayOverflow ? OVERFLOW_SPACE : 0

    // Define the max width of track in fonction of wrapper width, nav buttons width and overflow
    const maxTrackWidth = this.wrapper.clientWidth - 2 * navWidth - extraOverflow

    // If there is no enough space for nbColumns passed by props,
    // we need to redefine in state how many columns there is
    let nbColumns = this.props.nbColumns
    if (nbColumns > Math.floor(maxTrackWidth / itemSize.width)) {
      nbColumns = Math.floor(maxTrackWidth / itemSize.width) || 1
    }

    const maxScroll = this.list.scrollWidth - this.list.clientWidth + extraOverflow / 2

    const trackSize = {
      width: itemSize.width * nbColumns + extraOverflow || 0,
      height: itemSize.height * this.props.nbRows || 0,
    }

    const listSize = {
      width: 0, // no need of calculation
      height: itemSize.height * this.props.nbRows,
    }

    // If component is mounting, we need to init the currentScroll value
    // or number of columns is updating (due to resize e.g)
    let currentScroll = 0
    if (!this.state.mounted || nbColumns !== this.state.nbColumns) {
      if (this.props.infinite && React.Children.count(this.props.children) > 1) {
        currentScroll = -1 * itemSize.width * nbColumns + extraOverflow / 2
      } else {
        currentScroll = extraOverflow / 2
      }
    } else {
      currentScroll = this.state.currentScroll
    }

    this.setState({
      itemSize,
      trackSize,
      listSize,
      nbColumns,
      maxTrackWidth,
      maxScroll,
      extraOverflow,
      initScroll: itemSize.width,
      currentScroll,
      mounted: true,
    })
  }

  resetAtBeginning = () => {
    const { initScroll, nbColumns, extraOverflow } = this.state
    const newCurrentScroll = -1 * initScroll * nbColumns + extraOverflow / 2

    this.setState({
      fade: false, // disabled transition animation to not see update of current scroll
      currentScroll: newCurrentScroll,
    })
  }

  resetAtEnd = () => {
    const { initScroll, extraOverflow } = this.state
    const pageCount = this.props.children.length / this.props.nbRows
    const newCurrentScroll = -1 * initScroll * pageCount + extraOverflow / 2

    this.setState({
      fade: false, // disabled transition animation to not see update of current scroll
      currentScroll: newCurrentScroll,
    })
  }

  componentDidUpdate(prevProps, prevState) {
    // Re-update all dimensions when item size is calculated
    if (prevState.itemSize.height !== this.state.itemSize.height) {
      this.updateDimensions()
    }

    if (React.Children.count(this.props.children) > 1) {
      // Defer reset of carousel for infinite mode
      // when transition animation of last slide ended
      if (!this.isHigherBoundScrolling() && this.props.infinite) {
        defer(this.resetAtBeginning, TRANSITION_DURATION)
      }

      // Defer reset of carousel for infinite mode
      // when transition animation of first slide ended
      if (!this.isLowerBoundScrolling() && this.props.infinite) {
        defer(this.resetAtEnd, TRANSITION_DURATION)
      }
    }
  }

  componentDidMount = () => {
    this.updateDimensions()

    if (this.props.autoPlay && React.Children.count(this.props.children) > 1) {
      this.interval = setInterval(this.playInterval, this.props.autoPlay)
    }

    window.addEventListener('resize', this.updateDimensions)
  }

  componentWillUnmount = () => {
    if (this.interval) {
      this.stopAutoPlay()
    }

    window.removeEventListener('resize', this.updateDimensions)
  }

  playInterval = () => {
    if (!this.props.infinite && !this.isHigherBoundScrolling()) {
      this.stopAutoPlay()
      return
    }

    this.go('next')
  }

  stopAutoPlay = () => {
    clearInterval(this.interval)
    this.interval = null
  }

  go = direction => {
    const scrollStep =
      this.props.navigationType === 'item'
        ? this.state.itemSize.width
        : this.state.itemSize.width * this.state.nbColumns

    let currentScroll = 0

    if (direction === 'next') {
      if (!this.isHigherBoundScrolling() && !this.props.infinite) return
      currentScroll = this.state.currentScroll - scrollStep
    } else if (direction === 'prev') {
      if (!this.isLowerBoundScrolling() && !this.props.infinite) return
      currentScroll = this.state.currentScroll + scrollStep
    }

    this.setState({ currentScroll, fade: true })
  }

  handlePrevClick = event => {
    event.preventDefault()
    this.go('prev')
  }

  handleNextClick = event => {
    event.preventDefault()
    this.go('next')
  }

  handleMove = (clientX, clientY) => {
    if (this.state.isMoving) return
    if (this.state.touchX === null && this.state.touchY === null) return

    const deltaX = this.state.touchX - clientX
    const deltaY = this.state.touchY - clientY

    // Check if the touchmove is left-right direction
    if (Math.abs(deltaX) > Math.abs(deltaY)) {
      // Check the direction
      if (deltaX > 20) {
        this.go('next')
        this.setState({ isMoving: true })
      } else if (deltaX < -20) {
        this.go('prev')
        this.setState({ isMoving: true })
      }
    }
  }

  handleTouchStart = event => {
    event.preventDefault()
    this.setState({
      touchX: event.touches[0].clientX,
      touchY: event.touches[0].clientY,
    })
  }

  handleTouchMove = event => {
    event.preventDefault()
    this.handleMove(event.touches[0].clientX, event.touches[0].clientY)
  }

  handleTouchEnd = event => {
    if (event) event.preventDefault()
    this.setState({ touchX: null, touchY: null, isMoving: false })
  }

  handleMouseDown = event => {
    event.preventDefault()
    this.setState({ touchX: event.clientX, touchY: event.clientY })
  }

  handleMouseMove = event => {
    event.preventDefault()
    this.handleMove(event.clientX, event.clientY)
  }

  handleMouseUp = event => {
    event.preventDefault()
    this.handleTouchEnd()
  }

  handleMouseLeave = event => {
    event.preventDefault()
    this.handleTouchEnd()
  }

  isLowerBoundScrolling = () => Math.abs(this.state.currentScroll) > this.state.extraOverflow / 2

  isHigherBoundScrolling = () => Math.abs(this.state.currentScroll) < this.state.maxScroll

  renderItems = () => {
    const items = []
    const preCloneItems = []
    const postCloneItems = []

    React.Children.forEach(this.props.children, (child, index) => {
      items.push(<CarouselListItems key={`original-${index}`}>{child}</CarouselListItems>)

      if (this.props.infinite && React.Children.count(this.props.children) > 1) {
        if (index >= this.props.children.length - this.props.nbRows * this.state.nbColumns) {
          preCloneItems.push(<CarouselListItems key={`preclone-${index}`}>{child}</CarouselListItems>)
        }

        if (index < this.props.nbRows * this.state.nbColumns) {
          postCloneItems.push(<CarouselListItems key={`postclone-${index}`}>{child}</CarouselListItems>)
        }
      }
    })

    return [...preCloneItems, ...items, ...postCloneItems]
  }

  render() {
    return (
      <CarouselWrapper ref={this.getWrapperRef} {...this.props}>
        {this.props.displayNav && (
          <CarouselButtonLeft
            show={this.props.infinite || this.isLowerBoundScrolling()}
            onClick={this.handlePrevClick}
            innerRef={this.getNavRef}
          />
        )}
        <CarouselTrack ref={this.getTrackRef} size={this.state.trackSize}>
          <CarouselList
            ref={this.getListRef}
            size={this.state.listSize}
            fade={this.state.fade}
            currentScroll={this.state.currentScroll}
            onTouchStart={this.props.displayNav ? undefined : this.handleTouchStart}
            onTouchMove={this.props.displayNav ? undefined : this.handleTouchMove}
            onTouchEnd={this.props.displayNav ? undefined : this.handleTouchEnd}
            onMouseDown={this.handleMouseDown}
            onMouseMove={this.handleMouseMove}
            onMouseUp={this.handleMouseUp}
            onMouseLeave={this.handleMouseLeave}
          >
            {this.renderItems()}
          </CarouselList>
        </CarouselTrack>
        {this.props.displayNav && (
          <CarouselButtonRight
            show={this.props.infinite || this.isHigherBoundScrolling()}
            onClick={this.handleNextClick}
          />
        )}
      </CarouselWrapper>
    )
  }
}

Carousel.propTypes = {
  /** maximum number of displayed rows */
  nbRows: PropTypes.number,
  /** maximum number of displayed columns (if there is no enough space, it will be forced to right value) */
  nbColumns: PropTypes.number,
  /** boolean to show navigation buttons */
  displayNav: PropTypes.bool,
  infinite: PropTypes.bool,
  /** boolean to show the next item in overflow */
  displayOverflow: PropTypes.bool,
  /** number of ms between transition, if equal to 0, auto play is disabled */
  autoPlay: PropTypes.number,
  /** set style of scrolling, `page` will scroll a group of item */
  navigationType: PropTypes.oneOf(['item', 'page']),
  children: PropTypes.node.isRequired,
  prevButton: PropTypes.node,
  nextButton: PropTypes.node,
}

Carousel.defaultProps = {
  nbRows: 1,
  nbColumns: 1,
  displayNav: false,
  autoPlay: 0,
  infinite: false,
  displayOverflow: true,
  navigationType: 'page',
}
Carousel.displayName = 'legacy.Carousel'

export default Carousel
export { Carousel }
