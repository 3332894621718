import * as React from 'react'
import * as PropTypes from 'prop-types'

import DropdownFilter from '@legacy/DropdownFilter'
import Panel from './Panel'
import { Label, Description, Detail } from '@legacy/form/Label'

import * as S from './styles'

/** Input component for text, number, or password. */
class PhoneInput extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      value: props.initialValue || '',
      open: false,
      currentCountryCode: props.initialCountryCode || props.callingPrefixes[0].code || '',
    }

    this.input = null
  }

  getCurrentCountry = () => {
    return (
      this.props.callingPrefixes.find(c => c.code === this.state.currentCountryCode) || this.props.callingPrefixes[0]
    )
  }

  getInputRef = ref => {
    this.input = ref
  }

  processPhoneNumber = value => {
    const currentCode = this.getCurrentCountry()

    // Remove all extra spaces and special characters
    return `+${currentCode.prefix}${value.replace(/\D|\s/gi, '')}`
  }

  handleChange = event => {
    const { onChange } = this.props

    this.setState(
      {
        value: event.currentTarget.value,
      },
      () => {
        if (onChange && typeof onChange === 'function') {
          const phoneNumber = this.processPhoneNumber(this.state.value)
          onChange(phoneNumber)
        }
      },
    )
  }

  toggleDropdown = event => {
    event.preventDefault()
    this.setState({ open: !this.state.open })
  }

  selectCountry = countryCode => {
    const { onChange } = this.props
    const { value } = this.state

    this.setState(
      {
        currentCountryCode: countryCode,
        open: false,
      },
      () => {
        this.input.focus()

        if (onChange && typeof onChange === 'function') {
          const phoneNumber = this.processPhoneNumber(value)
          onChange(phoneNumber)
        }
      },
    )
  }

  render() {
    const {
      label,
      description,
      useDefault,
      error,
      success,
      name,
      disabled,
      callingPrefixes,
      onChange, // eslint-disable-line
      initialValue, // eslint-disable-line
      initialCountryCode, // eslint-disable-line
      ...props
    } = this.props
    const { value, open, currentCountryCode, dropdownWidth } = this.state
    const currentCode = this.getCurrentCountry()

    return (
      <S.PhoneInputContainer>
        {label && <Label htmlFor={name}>{label}</Label>}
        {description && <Description>{description}</Description>}
        <S.PhoneInputWrapper>
          {callingPrefixes.length && (
            <S.CallingPrefixDropdown error={error} success={success} focused={open} disabled={disabled}>
              <S.Flag src={currentCode.flag} alt={currentCode.name} />
              <DropdownFilter
                inline
                useDefault={useDefault}
                disabled={disabled}
                name="callingprefixes"
                value={currentCountryCode}
                options={callingPrefixes.map(c => ({
                  value: c.code,
                  label: `${c.code.toUpperCase()} +${c.prefix}`,
                }))}
                onChange={event => this.selectCountry(event.currentTarget.value)}
                onClick={useDefault ? undefined : this.toggleDropdown}
              />
            </S.CallingPrefixDropdown>
          )}
          <S.PhoneInput
            type="tel"
            ref={this.getInputRef}
            name={name}
            id={name}
            value={value}
            error={error}
            success={success}
            focused={open}
            onChange={this.handleChange}
            disabled={disabled}
            paddingLeft={dropdownWidth}
            {...props}
          />
          <Panel
            open={open}
            callingPrefixes={callingPrefixes}
            currentCountryCode={currentCountryCode}
            onClickOutside={this.toggleDropdown}
            onClickItem={this.selectCountry}
          />
        </S.PhoneInputWrapper>
        {error && !success && <Detail error={!!error}>{error}</Detail>}
        {success && !error && <Detail success={!!success}>{success}</Detail>}
      </S.PhoneInputContainer>
    )
  }
}

PhoneInput.displayName = 'legacy.PhoneInput'
PhoneInput.propTypes = {
  /** name attribute of input element */
  name: PropTypes.string.isRequired,
  /** label of the element */
  label: PropTypes.string,
  /** description of the element */
  description: PropTypes.string,
  /** placeholder of the element */
  placeholder: PropTypes.string,
  /** error message */
  error: PropTypes.string,
  /** success message */
  success: PropTypes.string,
  /** disable the component */
  disabled: PropTypes.bool,
  /** browser will display an error if input is empty on form submitting */
  required: PropTypes.bool,
  /** if true, it will use a native select for country code */
  useDefault: PropTypes.bool,
  /** mandatory function to retrieve value */
  onChange: PropTypes.func,
  /** used for prefetched data */
  initialValue: PropTypes.string,
  /** used for selecting a country (if undefined, the first will display) */
  initialCountryCode: PropTypes.string,
  /** list of all selectable calling prefixes */
  callingPrefixes: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      code: PropTypes.string.isRequired,
      flag: PropTypes.string.isRequired,
      prefix: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    }),
  ),
}

export default PhoneInput
