import * as React from 'react'
import * as PropTypes from 'prop-types'
import styled from 'styled-components'

import I18nSettingsBox from '@legacy/I18nSettingsBox'
import { SecondaryLink } from '@legacy/Links'
import { DEFAULT_LANGUAGES, DEFAULT_CURRENCIES } from '../../constants/i18n'
import { Title5, Title6 } from '@legacy/Titles'
import { OwlOnlyLogo } from '@legacy/Logotypes'
import BCorp from './BCorp'

import * as colors from '@colors'
import * as bp from '@bp'

const Container = styled.div`
  background-color: ${colors.PRIMARY_WHITE};
  position: relative;
  width: 100%;
  padding: 0 20px 20px;

  @media screen and ${bp.LAPTOP} {
    padding: 0 30px 80px;
  }
`

const Wrapper = styled.div`
  max-width: 1020px;
  margin: auto;
  display: flex;
  flex-direction: column;
`

const BrandBox = styled.div`
  display: flex;
  justify-content: center;
  align-self: center;
  width: 100%;
  max-width: 480px;

  @media screen and ${bp.LAPTOP} {
    max-width: 280px;
    align-self: flex-end;
  }

  & > svg {
    font-size: 52px;
    transform: translate3d(0, -50%, 0);
  }
`

const Main = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;

  @media screen and ${bp.LAPTOP} {
    justify-content: space-between;
  }
`

const CompanyInfo = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 480px;

  @media screen and ${bp.LAPTOP} {
    max-width: 280px;
  }
`

const ColumnsLinks = styled.div`
  display: none;

  @media screen and ${bp.LAPTOP} {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  & > div {
    flex: 1;
  }
`

const BrandWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`

const PartnersWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  margin: 10px auto;

  & > * {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin: 5px auto;

    & > svg,
    & > img {
      font-size: 32px;
      height: 32px;
      max-width: 130px;
    }
  }
`

const Punchline = styled(Title5)`
  font-size: 18px;
  text-align: center;

  @media screen and ${bp.LAPTOP} {
    text-align: left;
  }
`

const Description = styled.p`
  display: none;
  font-size: 12px;
  font-weight: 300;

  @media screen and ${bp.LAPTOP} {
    display: block;
  }
`

const Separator = styled.div`
  background-color: ${colors.GREY_SHADE_5};
  width: 100%;
  height: 1px;
  margin: 20px auto;
`

const FooterColumnTitle = styled(Title5)`
  font-size: 15px;
  margin-bottom: 15px;
`

const FooterList = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
`

const CountryPartnerLogo = ({ name }) => {
  switch (name) {
    case 'bnp':
      return <img src="https://d1yggf0lcx0ykm.cloudfront.net/site/build/img/footer/bnp-paribas.svg" alt="BNP Baripas" />
    case 'bn-fr':
      // return <BanqueNationalFr />
      break
    case 'bn-en':
      // return <BanqueNationalEn />
      break
    default:
      return null
  }
}

CountryPartnerLogo.propTypes = {
  name: PropTypes.string,
}

class Footer extends React.Component {
  state = {
    currentLanguage: this.props.currentLanguage,
    currentCurrency: this.props.currentCurrency,
  }

  selectLanguage = value => {
    this.setState({ currentLanguage: value })

    if (this.props.onLanguageChange) {
      this.props.onLanguageChange(value)
    }
  }

  selectCurrency = value => {
    this.setState({ currentCurrency: value })

    if (this.props.onCurrencyChange) {
      this.props.onCurrencyChange(value)
    }
  }

  render() {
    const { translation } = this.props

    return (
      <Container>
        <Wrapper>
          <BrandBox>
            <OwlOnlyLogo />
          </BrandBox>
          <Main>
            <ColumnsLinks>
              <div>
                <FooterColumnTitle>About Ulule</FooterColumnTitle>
                <FooterList>
                  <li>
                    <SecondaryLink>How it works</SecondaryLink>
                  </li>
                  <li>
                    <SecondaryLink>Newsletter</SecondaryLink>
                  </li>
                  <li>
                    <SecondaryLink>Statistics</SecondaryLink>
                  </li>
                  <li>
                    <SecondaryLink>Hall of fame</SecondaryLink>
                  </li>
                  <li>
                    <SecondaryLink>Press</SecondaryLink>
                  </li>
                  <li>
                    <SecondaryLink>Team</SecondaryLink>
                  </li>
                </FooterList>
              </div>
              <div>
                <FooterColumnTitle>Finance your project</FooterColumnTitle>
                <FooterList>
                  <li>
                    <SecondaryLink>Online help</SecondaryLink>
                  </li>
                  <li>
                    <SecondaryLink>Forum</SecondaryLink>
                  </li>
                  <li>
                    <SecondaryLink>Terms of use</SecondaryLink>
                  </li>
                  <li>
                    <SecondaryLink>Privacy</SecondaryLink>
                  </li>
                  <li>
                    <SecondaryLink>Contact us</SecondaryLink>
                  </li>
                </FooterList>
              </div>
              <div>
                <FooterColumnTitle>Ulule pro</FooterColumnTitle>
                <FooterList>
                  <li>
                    <SecondaryLink>Official users</SecondaryLink>
                  </li>
                  <li>
                    <SecondaryLink>Developer API</SecondaryLink>
                  </li>
                </FooterList>
              </div>
            </ColumnsLinks>
            <CompanyInfo>
              <BrandWrapper>
                <Punchline>{this.props.punchline}</Punchline>
                <Description>{this.props.description}</Description>
              </BrandWrapper>
              <PartnersWrapper>
                <div>
                  <Title6 align="center">{translation['They are by our side']}</Title6>
                  <CountryPartnerLogo name={this.props.countryPartner} />
                </div>
                <div>
                  <Title6 align="center">{translation['Engaged company']}</Title6>
                  <BCorp />
                </div>
              </PartnersWrapper>
            </CompanyInfo>
          </Main>
          <Separator />
          <I18nSettingsBox
            currentLanguage={this.props.currentLanguage}
            currentCurrency={this.props.currentCurrency}
            languages={this.props.languages}
            currencies={this.props.currencies}
            onCurrencyClick={this.selectCurrency}
            onLanguageClick={this.selectLanguage}
          />
        </Wrapper>
      </Container>
    )
  }
}

Footer.defaultProps = {
  languages: DEFAULT_LANGUAGES,
  currencies: DEFAULT_CURRENCIES,
  currentLanguage: 'en',
  currentCurrency: 'USD',
  onLanguageChange: () => {},
  onCurrencyChange: () => {},
}

Footer.propTypes = {
  countryPartner: PropTypes.string,
  punchline: PropTypes.string,
  description: PropTypes.string,
  translation: PropTypes.object,
  languages: PropTypes.object,
  currencies: PropTypes.object,
  currentLanguage: PropTypes.string,
  currentCurrency: PropTypes.string,
  onLanguageChange: PropTypes.func,
  onCurrencyChange: PropTypes.func,
}

export default Footer
