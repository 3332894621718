import styled, { css } from 'styled-components'

import * as colors from '@colors'
import * as glyphs from '@glyphs/index'

export type CheckmarkStatus = 'error' | 'checked' | 'focused'

export const Checkmark = styled(glyphs.fill.Check).attrs({ size: 18 })<{
  disabled?: boolean
  status?: CheckmarkStatus
}>`
  border-radius: 2px;
  border: 2px solid ${colors.GREY_SHADE_4};
  box-sizing: border-box;
  padding: 2px;
  background-color: ${colors.PRIMARY_WHITE};
  vertical-align: baseline;

  /* override specificity of svg:not([fill]) in reset css */
  && {
    fill: transparent;
  }

  ${({ status }) => {
    if (status === 'checked') {
      return css`
        background-color: ${colors.PRIMARY_BLUE};
        border-color: ${colors.PRIMARY_BLUE};
        /* override specificity of svg:not([fill]) in reset css */
        && {
          fill: ${colors.PRIMARY_WHITE};
        }
      `
    }
    if (status === 'error') {
      return css`
        border-color: ${colors.PRIMARY_RED};
      `
    }
    if (status === 'focused') {
      return css`
        border-color: ${colors.GREY_SHADE_3};
      `
    }
    return ''
  }}

  ${({ disabled, status }) => {
    if (disabled) {
      return css`
        background-color: ${colors.GREY_SHADE_6};
        border-color: ${colors.GREY_SHADE_4};
        /* override specificity of svg:not([fill]) in reset css */
        && {
          fill: ${status !== 'checked' ? 'transparent' : colors.GREY_SHADE_4};
        }
      `
    }
  }}
`
