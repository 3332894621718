import * as React from 'react'
import * as PropTypes from 'prop-types'

import { FadeInAndOut } from '@legacy/UluleTransitions'
import * as S from './styles'

class ModalBehaviour extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      display: false,
      isOpen: false,
    }
  }

  handleKeyDown = event => {
    if (event.keyCode === 27 && this.props.isClosable) {
      this.handleCloseModal()
    }
  }

  handleOnExited = () => {
    this.setState({ display: false })
  }

  handleOpenModal = () => {
    document.addEventListener('keydown', this.handleKeyDown)

    this.setState({
      isOpen: true,
      display: true,
    })
  }

  handleCloseModal = () => {
    if (this.state.isOpen) {
      document.removeEventListener('keydown', this.handleKeyDown)

      this.setState({
        isOpen: false,
      })

      if (this.props.onClosing && typeof this.props.onClosing === 'function') {
        this.props.onClosing()
      }
    }
  }

  componentDidMount = () => {
    if (this.props.isOpen) {
      this.handleOpenModal()
    }
  }

  componentDidUpdate = prevProps => {
    if (this.props.isOpen && !prevProps.isOpen) {
      this.handleOpenModal()
    } else if (!this.props.isOpen && prevProps.isOpen) {
      this.handleCloseModal()
    }
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.handleKeyDown)
  }

  render() {
    const { isClosable, overlay, children } = this.props
    const { isOpen, display } = this.state
    const ModalWrapper = this.props.wrapper

    return (
      <ModalWrapper isOpen={display}>
        {overlay && (
          <FadeInAndOut inProp={isOpen} duration={200}>
            <S.ModalOverlay onClick={isClosable ? this.handleCloseModal : undefined} />
          </FadeInAndOut>
        )}
        {children({
          isOpen,
          onClickClose: this.handleCloseModal,
          onExited: this.handleOnExited,
        })}
      </ModalWrapper>
    )
  }
}

ModalBehaviour.defaultProps = {
  isOpen: false,
  overlay: true,
  isClosable: true,
  wrapper: S.ModalWrapper,
}

ModalBehaviour.propTypes = {
  isOpen: PropTypes.bool,
  overlay: PropTypes.bool,
  isClosable: PropTypes.bool,
  onClosing: PropTypes.func,
  children: PropTypes.func.isRequired,
  wrapper: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
}

export default ModalBehaviour
