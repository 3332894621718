import * as React from 'react'
import * as PropTypes from 'prop-types'

import * as S from './styles'

class Panel extends React.Component {
  render() {
    const { open, callingPrefixes, currentCountryCode, onClickOutside, onClickItem } = this.props

    if (!open) {
      return null
    }

    return (
      <S.PanelPositionner onClickOutside={onClickOutside}>
        <S.CountryCodePanel>
          {callingPrefixes.map((call, index) => (
            <S.CountryCodeItem
              onClick={() => onClickItem(call.code)}
              selected={currentCountryCode === call.code}
              key={index}
            >
              {call.code.toUpperCase()} <S.Prefix>+{call.prefix}</S.Prefix>
            </S.CountryCodeItem>
          ))}
        </S.CountryCodePanel>
      </S.PanelPositionner>
    )
  }
}

Panel.propTypes = {
  open: PropTypes.bool.isRequired,
  onClickOutside: PropTypes.func.isRequired,
  onClickItem: PropTypes.func.isRequired,
  currentCountryCode: PropTypes.string.isRequired,
  callingPrefixes: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      code: PropTypes.string.isRequired,
      flag: PropTypes.string.isRequired,
      prefix: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    }),
  ),
}

export default Panel
