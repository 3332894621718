import * as React from 'react'
import * as PropTypes from 'prop-types'
import styled from 'styled-components'

import * as colors from '@colors'

const TooltipWrapper = styled.div`
  box-sizing: border-box;
  width: 226px;
  border: 1px solid ${colors.GREY_SHADE_5};
  background-color: ${colors.PRIMARY_WHITE};
  box-shadow: 1px 2px 3px 1px rgba(0, 0, 0, 0.12);
  padding: 9px;
  position: absolute;
  bottom: 30px;
  left: 50%;
  transform: translateX(-50%);
  display: ${props => (props.isOpen ? 'block' : 'none')};

  span,
  p {
    font-size: 13px;
    line-height: 22px;
  }

  &::after {
    content: '';
    display: inline-block;
    height: 12px;
    width: 12px;
    box-shadow: 3px 3px 3px 0 rgba(0, 0, 0, 0.12);
    transform: rotate(45deg) translateY(50%);
    position: absolute;
    bottom: -1px;
    background-color: white;
    left: 50%;
  }
`

export const Tooltip = ({ children, className, isOpen }) => (
  <TooltipWrapper className={className} isOpen={isOpen}>
    {children}
  </TooltipWrapper>
)

Tooltip.propTypes = {
  className: PropTypes.string,
  /** children to show */
  children: PropTypes.object,
  /** show/hide tooltip */
  isOpen: PropTypes.bool,
}
