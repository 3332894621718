import * as React from 'react'
import * as PropTypes from 'prop-types'
import styled from 'styled-components'

import Icon from '@legacy/Icon'
import * as colors from '@colors'
import * as fonts from '@fonts'

const WrappedIcon = styled(Icon)`
  display: inline-block;
`

export const Wrapper = styled.div`
  background-color: ${colors.PRIMARY_WHITE};
  display: inline-flex;
  flex: 0 0 auto;
  justify-content: space-between;
  align-items: center;
  border: 1px solid;
  border-color: ${props => (props.focused ? colors.primary(props) : colors.PRIMARY_BLACK)};
  border-radius: 2px;
  color: ${props => (props.focused ? colors.primary(props) : colors.PRIMARY_BLACK)};
  font-family: ${fonts.PRIMARY_FONT};
  font-size: 15px;
  font-weight: 400;
  padding: 10px 15px;
  cursor: pointer;

  &:hover {
    border-color: ${props => colors.primary(props)};
    color: ${props => colors.primary(props)};
  }

  ${WrappedIcon /* sc-sel */} {
    margin-left: 10px;
  }
`

const FilterButton = ({ children, inline, disabled, focused, onClick }) => (
  <Wrapper inline={inline} focused={focused} disabled={disabled} onClick={onClick}>
    {children}
  </Wrapper>
)

FilterButton.defaultProps = {
  focused: false,
  inline: false,
  disabled: false,
}

FilterButton.propTypes = {
  /** changes the style of component (useful with text) */
  inline: PropTypes.bool,
  /** controls the focus */
  focused: PropTypes.bool,
  disabled: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
  children: PropTypes.any.isRequired,
}

export default FilterButton
