import * as React from 'react'
import styled from 'styled-components'
import * as PropTypes from 'prop-types'

import * as colors from '@colors'
import * as fonts from '@fonts'
import Icon from '../Icon'

import { isPreview, isProject, isDisabled, isSelected } from './state'

export const AddIcon = styled(Icon).attrs(props => ({ ...props, glyph: 'plus', size: 12 }))`
  color: ${colors.GREY_SHADE_4};
`

export const RemoveIcon = styled(Icon).attrs(props => ({ ...props, glyph: 'minus', size: 12 }))`
  color: ${colors.GREY_SHADE_4};
`

const ActionButton = styled.button`
  align-items: center;
  cursor: pointer;
  border: none;
  background: none;
  display: inline-flex;
  justify-content: center;
  margin: 0;
  padding: 0;
  font-weight: 500;
  font-family: ${fonts.PRIMARY_FONT};
  position: relative;

  &:hover,
  &:active,
  &:focus {
    outline: none;
  }
`

const Label = styled.span`
  font-size: 13px;
  line-height: 18px;
`

const AddLabel = styled(Label)`
  color: ${colors.PRIMARY_BLUE};
  margin-left: 6px;
`

const RemoveLabel = styled(Label)`
  color: ${colors.GREY_SHADE_3};
`

export const Action = ({ state, translations, className, withLabel = true }) => {
  if (isDisabled(state) || isPreview(state)) {
    return null
  }

  if (isProject(state)) {
    return (
      <ActionButton className={className}>
        <AddIcon />
        {withLabel && <AddLabel>{translations.choose}</AddLabel>}
      </ActionButton>
    )
  }

  if (isSelected(state)) {
    return (
      <ActionButton className={className}>
        {!withLabel && <RemoveIcon />}
        {withLabel && <RemoveLabel>{translations.remove}</RemoveLabel>}
      </ActionButton>
    )
  } else {
    return (
      <ActionButton className={className}>
        <AddIcon />
        {withLabel && <AddLabel>{translations.add}</AddLabel>}
      </ActionButton>
    )
  }
}

Action.propTypes = {
  state: PropTypes.object.isRequired,
  translations: PropTypes.shape({
    remove: PropTypes.string.isRequired,
    add: PropTypes.string.isRequired,
  }),
}
