import * as React from 'react'
import * as PropTypes from 'prop-types'
import styled from 'styled-components'

import * as colors from '@colors'
import * as fonts from '@fonts'

// NOTE: for Firefox & Edge compatibility
const encodeColor = color => color.replace(/#/, '%23')

/* prettier-ignore */
const Label = styled.span`
  background-color: ${props => (props.inline ? 'transparent' : colors.PRIMARY_WHITE)};
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" fill-rule="evenodd" clip-rule="evenodd" viewBox="0 0 32 32" width="8"><g transform="translate(0, 12)"><path d="M32 .9c-.32-.6-.96-.9-1.918-.9H1.918C.958 0 .319.3 0 .9 0 1.8 0 2.4.32 3l14.079 14.7c.642 0 .96.3 1.6.3.64 0 .96-.3 1.283-.602L31.362 3C32 2.4 32 1.8 32 .9" fill="${props => props.focused ? encodeColor(colors.primary(props)): encodeColor(colors.PRIMARY_BLACK)}"></path></g></svg>');
  background-repeat: no-repeat;
  background-position: center right 0;
  background-size: 8px 8px;
  color: ${props => (props.focused ? colors.primary(props) : colors.PRIMARY_BLACK)};
  font-family: ${fonts.PRIMARY_FONT};
  font-size: 15px;
  font-weight: 400;
  padding-right: calc(8px + 10px);
  cursor: pointer;
  width: ${props => (props.width ? `${props.width}px` : 'auto')};
  max-width: 200px;
  box-sizing: content-box;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  &:focus,
  &:hover {
    border-color: ${props => colors.primary(props)};
    color: ${props => colors.primary(props)};
    background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" fill-rule="evenodd" clip-rule="evenodd" viewBox="0 0 32 32" width="8"><g transform="translate(0, 12)"><path d="M32 .9c-.32-.6-.96-.9-1.918-.9H1.918C.958 0 .319.3 0 .9 0 1.8 0 2.4.32 3l14.079 14.7c.642 0 .96.3 1.6.3.64 0 .96-.3 1.283-.602L31.362 3C32 2.4 32 1.8 32 .9" fill="${props => encodeColor(colors.primary(props))}"></path></g></svg>');
  }
`

class DropdownLabel extends React.Component {
  getLabelByValue = () => {
    const { value, translation } = this.props

    if (value && Array.isArray(value) && value.length >= 2) {
      return translation && translation.multiselect
    }

    if (value && !value.length) {
      return translation && translation.placeholder
    }

    return value && value[0] && value[0].label
  }

  render() {
    const { onClick, width } = this.props
    return (
      <Label width={width} onClick={onClick}>
        {this.getLabelByValue()}
      </Label>
    )
  }
}

DropdownLabel.propTypes = {
  /** array of objects to display */
  value: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string,
    }),
  ).isRequired,
  /** function that will display the panel/list section */
  onClick: PropTypes.func.isRequired,
  /** object of string used for translation */
  translation: PropTypes.shape({
    multiselect: PropTypes.string,
    placeholder: PropTypes.string,
  }),
  /** width of the label */
  width: PropTypes.number,
}

export default DropdownLabel
