import * as React from 'react'
import * as PropTypes from 'prop-types'
import styled from 'styled-components'

import * as colors from '@colors'
import * as fonts from '@fonts'

import ClickOutside from '../core/ClickOutside'
import ShadowBox from '@legacy/ShadowBox'
import FilterButton from './FilterButton'

const Container = styled.div`
  display: inline-block;
  position: relative;
`

const DropdownBox = styled(ShadowBox)`
  position: absolute;
  top: calc(100% + 10px);
  right: 0;
  display: inline-flex;
  min-width: 140px;
  padding: 10px;
`

const List = styled.ul`
  list-style-type: none;
  margin: 0;
  padding: 0;
`

const Option = styled.li`
  padding: 10px 0;
  cursor: pointer;
  color: ${props => (props.active ? colors.primary(props) : colors.PRIMARY_BLACK)};
  font-family: ${fonts.PRIMARY_FONT};
  font-size: 13px;
  font-weight: 500;

  &:hover {
    color: ${props => colors.primary(props)};
  }
`

class Dropdown extends React.Component {
  state = {
    value: this.props.value || this.props.options[0].value,
    opened: this.props.opened,
  }

  toggleDropdown = () => {
    this.setState({ opened: !this.state.opened })
  }

  handleClickOption = value => {
    if (this.state.value !== value) {
      this.setState({ opened: false, value })
      if (this.props.onChange) {
        const newOption = this.getOptionByValue(value)
        this.props.onChange(newOption)
      }
    }
  }

  getOptionByValue = value => {
    const found = this.props.options.find(option => option.value === value)

    if (!found) {
      return this.props.options[0]
    }

    return found
  }

  render() {
    const { options } = this.props
    const currentOption = this.getOptionByValue(this.state.value)

    return (
      <Container>
        <FilterButton inline onClick={this.toggleDropdown}>
          {currentOption.label}
        </FilterButton>
        {this.state.opened && (
          <ClickOutside onClickOutside={this.toggleDropdown}>
            <DropdownBox>
              <List>
                {options.map((option, index) => (
                  <Option
                    key={index}
                    onClick={() => this.handleClickOption(option.value)}
                    active={this.state.value === option.value}
                  >
                    {option.label}
                  </Option>
                ))}
              </List>
            </DropdownBox>
          </ClickOutside>
        )}
      </Container>
    )
  }
}

Dropdown.displayName = 'legacy.Dropdown'

Dropdown.defaultProps = {
  opened: false,
}

Dropdown.propTypes = {
  /** current value of the dropdown, by default the first option */
  value: PropTypes.string,
  /** list of options availables in dropdown */
  options: PropTypes.array,
  /** trigger when a different option is clicked */
  onChange: PropTypes.func,
  /** controls the opening of dropdown */
  opened: PropTypes.bool,
}

export default Dropdown
