import styled from 'styled-components'

import * as colors from '@colors'

const ProjectCardPicture = styled.img`
  background-color: ${colors.PRIMARY_WHITE};
  bottom: 0;
  display: block;
  left: 0;
  max-height: 100%;
  max-width: 100%;
  object-fit: cover;
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
`

export default ProjectCardPicture
