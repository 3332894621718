import styled, { css } from 'styled-components'
import * as colors from '@colors'

export const Flag = styled.img<React.ImgHTMLAttributes<HTMLImageElement>>`
  width: 20px;
  height: 15px;
  margin: 0 8px 0 4px;
`
Flag.displayName = 'S.image.Flag'

type AvatarProps = {
  /** @default 'small' */
  type?: 'extra-small' | 'small' | 'medium' | 'large' | 'extra-large'
} & React.ImgHTMLAttributes<HTMLImageElement>

export const Avatar = styled.img<AvatarProps>`
  object-fit: cover;
  border-radius: 50%;
  background-image: ${props => (props.src ? `url(${props.src})` : undefined)};
  background-position: center;
  background-size: cover;
  border: 2px solid ${colors.PRIMARY_WHITE};
  ${({ type = 'small' }) => {
    if (type === 'small') {
      return css`
        width: 36px;
        height: 36px;
      `
    } else if (type === 'extra-small') {
      return css`
        width: 30px;
        height: 30px;
      `
    } else if (type === 'large') {
      return css`
        width: 90px;
        height: 90px;
      `
    } else if (type === 'extra-large') {
      return css`
        width: 160px;
        height: 160px;
      `
    } else {
      return css`
        width: 52px;
        height: 52px;
      `
    }
  }}
`
Avatar.displayName = 'S.image.Avatar'

type PastilleProps = {
  /** @default 'medium' */
  size?: 'extraSmall' | 'small' | 'medium'
  /** @default false */
  noBorder?: boolean
} & React.HTMLAttributes<HTMLDivElement>

export const Pastille = styled.div<PastilleProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  box-shadow: ${({ noBorder = false }) => (noBorder ? 'none !important' : '0 0 0 5px ' + colors.PRIMARY_WHITE)};

  * {
    max-width: 80px;
    max-height: 80px;
    text-align: center;
  }

  ${({ size = 'medium' }) => {
    if (size === 'extraSmall') {
      return css`
        height: 40px;
        width: 40px;
        box-shadow: 0 0 0 2px ${colors.PRIMARY_WHITE};
      `
    }
    if (size === 'small') {
      return css`
        height: 60px;
        width: 60px;
        box-shadow: 0 0 0 2px ${colors.PRIMARY_WHITE};
      `
    }
    if (size === 'medium') {
      return css`
        width: 120px;
        height: 120px;
        box-shadow: 0 0 0 5px ${colors.PRIMARY_WHITE};
      `
    }
  }}
`
Pastille.displayName = 'S.image.Pastille'
