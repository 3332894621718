import styled, { css } from 'styled-components'

import * as breakpoints from '@bp'
import * as colors from '@colors'
import * as fonts from '@fonts'

export const Description = styled.span`
  font-family: ${fonts.PRIMARY_FONT}, sans-serif;
  font-weight: 300;
  font-size: 15px;
  line-height: 22px;
  color: ${colors.PRIMARY_BLACK};
`

// TODO: rename application wide to Field or Wrapper
export const Label = styled.div`
  display: flex;
  flex-direction: column;
`

// TODO: rename application wide to Label
export const ActualLabel = styled.label``

export const Status = styled.div`
  display: flex;
  flex-direction: row;
`

export const TitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: baseline;

  @media screen and ${breakpoints.TABLET} {
    flex-direction: row;
  }
`

export const Title = styled.span`
  font-family: ${fonts.PRIMARY_FONT};
  font-weight: 400;
  font-size: 15px;
  line-height: 20px;
  color: ${colors.PRIMARY_BLACK};
  margin-right: 15px;
`

export const Context = styled.span`
  font-family: ${fonts.PRIMARY_FONT};
  font-weight: 400;
  font-size: 10px;
  line-height: 14px;
  color: ${colors.GREY_SHADE_3};
  text-transform: uppercase;
  white-space: pre;
  overflow: hidden;
`

export const Message = styled.div<{ type?: 'error' | 'success' }>`
  font-family: ${fonts.PRIMARY_FONT};
  font-weight: 500;
  font-size: 13px;
  line-height: 22px;
  ${({ type }) => {
    if (type === 'error') {
      return css`
        color: ${colors.SECONDARY_RED};
      `
    }
    if (type === 'success') {
      return css`
        color: ${colors.PRIMARY_GREEN};
      `
    }
  }}
`
