import styled, { css } from 'styled-components'

import * as colors from '@colors'
import * as fonts from '@fonts'
import * as glyphs from '@glyphs/index'

import { hexToRgb } from '../../utils/colors'

export type Status = 'selected' | 'disabled' | 'default'

export const Label = styled.div<{ status?: Status }>`
  display: inline;
  width: 100%;
  font-family: ${fonts.PRIMARY_FONT};
  font-size: 13px;
  line-height: 18px;
  padding-right: 12px;
  ${({ status }) => {
    if (status === 'disabled') {
      return css`
        color: ${colors.GREY_SHADE_4};
      `
    }
    if (status === 'selected') {
      return css`
        color: ${colors.PRIMARY_BLUE};
      `
    }
    return css`
      color: ${colors.PRIMARY_BLACK};
    `
  }}
`

export const MonoSelectedIcon = styled(glyphs.fill.CheckCircle).attrs({ size: 12 })<{
  status: Status
}>`
  margin: 0 6px 0 0;
  stroke: ${colors.PRIMARY_WHITE};
  stroke-width: 2px;
  ${({ status }) => {
    if (status === 'selected') {
      return css`
        color: ${colors.PRIMARY_BLUE};
      `
    }
    if (status === 'disabled') {
      return css`
        color: transparent;
      `
    }
    if (status === 'default') {
      return css`
        color: transparent;
      `
    }
  }}
`

export const MultiSelectedIcon = styled(glyphs.stroke.Check).attrs({ size: 12 })<{
  status: Status
}>`
  margin: 0 6px 0 0;
  border-style: solid;
  border-width: 2px;
  stroke-width: 4px;
  color: ${colors.PRIMARY_WHITE};
  ${({ status }) => {
    if (status === 'selected') {
      return css`
        stroke: currentColor;
        border-color: ${colors.PRIMARY_BLUE};
        background-color: ${colors.PRIMARY_BLUE};
      `
    }
    if (status === 'disabled') {
      return css`
        border-color: ${colors.GREY_SHADE_5};
        stroke: transparent;
      `
    }
    if (status === 'default') {
      return css`
        border-color: ${colors.GREY_SHADE_4};
        stroke: transparent;
      `
    }
  }}
`

export const Wrapper = styled.li<{ focused: boolean; filtered: boolean }>`
  display: flex;
  align-items: center;
  padding: 6px 16px 6px 10px;
  user-select: none;
  outline: none;
  ${({ focused }) => {
    if (focused) {
      return css`
        background-color: ${hexToRgb(colors.PRIMARY_BLUE, 0.05)};
      `
    } else {
      return css`
        background-color: ${colors.PRIMARY_WHITE};
      `
    }
  }}
  ${({ filtered }) => {
    if (filtered) {
      return css`
        /* don't use display:none keep width of dropdown even if largest item is filtered.
        Instead, reduce vertical dimension to 0 (height and padding) and hide overflow */
        visibility: hidden;
        height: 0;
        overflow: hidden;
        padding: 0 16px 0 10px;
      `
    }
  }}

  ${MonoSelectedIcon}, ${MultiSelectedIcon} {
    flex: 0 0 12px;
  }
`
