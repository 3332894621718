import styled from 'styled-components'

import * as colors from '@colors'
import * as fonts from '@fonts'
import { hexToRgb } from '../../utils/colors'

import { StyledAvatar } from '@legacy/Avatar'

export const Sponsor = styled.span`
  font-family: ${fonts.PRIMARY_FONT};
  font-weight: 500;
  font-size: 13px;
  display: flex;
  color: ${props => props.mainColor || 'inherit'};
  flex-flow: row nowrap;
  flex: 0 0 auto;
  align-items: center;
  justify-content: center;
  white-space: nowrap;

  ${StyledAvatar /* sc-sel */} {
    margin-right: 5px;
  }
`

export const SponsorLink = styled(Sponsor).attrs(props => ({
  color: props.mainColor || colors.primary(props),
}))`
  color: ${props => props.color};
  display: inline-flex;
  text-decoration: none;

  &:hover {
    color: ${props => hexToRgb(props.color, 0.85)};
  }
`

export const Description = styled.span`
  position: relative;
  display: flex;
  flex: 1 1 auto;
  align-items: center;
  color: ${colors.PRIMARY_WHITE};
  padding: 10px;
  z-index: 10;

  &::before {
    content: '';
    transform: skewX(-5deg);
    width: 101%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
  }
`

export const Container = styled.a.attrs(props => ({
  color: props.mainColor || colors.primary(props),
}))`
  background-color: ${colors.PRIMARY_WHITE};
  position: relative;
  display: flex;
  align-items: stretch;
  flex-flow: row nowrap;
  width: 100%;
  height: 54px;
  font-family: ${fonts.PRIMARY_FONT};
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  text-decoration: none;
  overflow: hidden;
  border: 1px solid ${colors.GREY_SHADE_5};
  padding: 0;
  margin: 0;

  ${Description /* sc-sel */} {
    margin-left: 10px;
  }

  ${Description /* sc-sel */}::before {
    background-color: ${props => props.color};
  }

  &:hover {
    text-decoration: none;

    ${Sponsor /* sc-sel */} {
      transition: all 250ms ease-in-out;
      color: ${props => (props.href ? hexToRgb(props.color, 0.85) : undefined)};
    }

    ${Description /* sc-sel */}::before {
      transition: all 250ms ease-in-out;
      background-color: ${props => (props.href ? hexToRgb(props.color, 0.85) : undefined)};
    }
  }

  /* stylelint-disable-next-line */
  ${Sponsor /* sc-sel */} {
    padding: 10px;
    padding-right: 0;
    color: ${props => props.color};
  }
`

export const TinyContainer = styled.a.attrs(props => ({
  color: props.mainColor || colors.primary(props),
}))`
  background-color: ${props => props.color};
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 50px;
  font-family: ${fonts.PRIMARY_FONT};
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  text-decoration: none;
  padding: 0;
  margin: 0;

  &:hover {
    text-decoration: none;
    transition: all 250ms ease-in-out;
    background-color: ${props => (props.href ? hexToRgb(props.color, 0.85) : undefined)};
  }

  /* stylelint-disable-next-line */
  ${Sponsor /* sc-sel */} {
    padding: 10px;
    color: ${colors.PRIMARY_WHITE};
  }
`
