import styled from 'styled-components'

export const SkewedContainer = styled.div<{ skewedBorder: 'bottom' | 'top' }>`
  margin-top: -20px;
  padding: 35px 0 60px;
  transform-origin: top left;
  transform: skew(0, -5deg);
`
SkewedContainer.displayName = 'S.container.SkewedContainer'
// WARNING: the risk is that on some browsers the content can be blurred
// an alternative is to use ::before / ::after to generate a triangle

export const SkewedContent = styled.div`
  transform: skew(0, 5deg);
`
SkewedContent.displayName = 'S.container.SkewedContent'
