import styled from 'styled-components'

import * as colors from '@colors'

const ProjectCardTitle = styled.h2`
  color: ${props => colors.primary(props)};
  font-size: 16px;
  font-weight: 600;
  line-height: 23px;
  margin: 0;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`

export default ProjectCardTitle
