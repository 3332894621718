import * as React from 'react'
import styled from 'styled-components'
import * as PropTypes from 'prop-types'

import ShadowBox from '../ShadowBox'

const DropdownBox = styled(ShadowBox)`
  position: absolute;
  top: calc(100% + 10px);
  display: inline-flex;
  min-width: 140px;
  padding: 10px;
  z-index: 2;
  ${props => (props.alignLeft ? `left: 0;` : `right: 0;`)}
`

const Panel = ({ children, ...props }) => <DropdownBox {...props}>{children}</DropdownBox>

Panel.defaultProps = {
  alignLeft: false,
}

Panel.propTypes = {
  /** align dropdown box to the left. If falsy, will align to right (default) */
  alignLeft: PropTypes.bool,
  /** children of the components */
  children: PropTypes.any,
}

export default Panel
