import * as React from 'react'

import { reducer } from './reducer'
import { scrollToVisibility } from './scrollToVisibility'

export function useNavigableList(listRef, active, handleFocus, focused) {
  const [state, dispatch] = React.useReducer(reducer, {
    registered: [],
    typeAhead: { timestamp: 0, query: '' },
  })

  const focusedItem = state.registered.find(item => item.identifier === focused) || state.head

  React.useEffect(() => {
    if (active) {
      window.addEventListener('keydown', handleKeyDown)

      return () => {
        window.removeEventListener('keydown', handleKeyDown)
      }
    }
  }, [active, focusedItem])

  React.useEffect(() => {
    if (focusedItem !== undefined) {
      scrollToVisibility(listRef.current, focusedItem.ref.current)
      handleFocus(focusedItem.identifier)
    }
  }, [focusedItem])

  React.useEffect(() => {
    const query = state.typeAhead.query
    const match = Object.values(state.registered).find(option => option.label.startsWith(query))
    if (match !== undefined) {
      handleFocus(match.identifier)
    }
  }, [state.typeAhead.query])

  return {
    useNavigableItem,
  }

  function handleKeyDown(event) {
    if (focusedItem === undefined) {
      return
    }

    if (event.key === 'ArrowUp') {
      event.preventDefault()
      let toFocus = focusedItem && focusedItem.previous
      while (toFocus !== undefined && toFocus.disabled) {
        toFocus = toFocus.previous
      }

      if (toFocus) {
        handleFocus(toFocus.identifier)
      }
      return
    }
    if (event.key === 'ArrowDown') {
      event.preventDefault()
      let toFocus = focusedItem && focusedItem.next
      while (toFocus !== undefined && toFocus.disabled) {
        toFocus = toFocus.next
      }

      if (toFocus) {
        handleFocus(toFocus.identifier)
      }
      return
    }

    if (event.key.length === 1) {
      // only printable chars
      dispatch({ type: 'type-ahead', key: event.key })
    }
  }

  function useNavigableItem(item) {
    React.useEffect(() => {
      dispatch({ type: 'register', item })
      return () => {
        if (isFocused(item)) {
          handleFocus(undefined)
        }
        dispatch({ type: 'deregister', identifier: item.identifier })
      }
    }, [])

    if (isDisabled(item.identifier) !== item.disabled) {
      dispatch({ type: 'disable', identifier: item.identifier, disabled: item.disabled })
    }

    return {
      focus: () => {
        if (!item.disabled && !isFocused(item)) {
          handleFocus(item.identifier)
        }
      },
      isFocused: () => isFocused(item),
    }
  }

  function isFocused(item) {
    if (focusedItem === undefined) {
      return false
    }
    return focusedItem.identifier === item.identifier
  }

  function isDisabled(identifier) {
    const item = state.registered.find(item => item.identifier === identifier)
    if (item === undefined) {
      return undefined
    }
    return item.disabled
  }
}
