/* eslint-disable react/prop-types */
import * as React from 'react'
import styled from 'styled-components'

import * as colors from '@colors'
import * as fonts from '@fonts'
import Icon from '../../../Icon'

const SelectButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border: 1px solid ${colors.GREY_SHADE_5};
  padding: 14px 13px;
  user-select: none;
  background: ${colors.PRIMARY_WHITE};
  height: 50px;
  box-sizing: border-box;

  &:hover {
    border: 1px solid ${colors.PRIMARY_BLUE};
    color: ${colors.PRIMARY_BLUE};
  }
`

export const SelectLabel = styled.span`
  color: ${colors.PRIMARY_BLACK};
  font-family: ${fonts.PRIMARY_FONT};
  line-height: 20px;
  font-size: 15px;
  margin-right: 20px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`

const CaretDown = styled(Icon).attrs({ size: 10, glyph: 'caret-down' })`
  color: ${colors.GREY_SHADE_4};
`

export function SelectButton({ label, className }) {
  return (
    <SelectButtonWrapper className={className}>
      <SelectLabel>{label}</SelectLabel>
      <CaretDown />
    </SelectButtonWrapper>
  )
}
