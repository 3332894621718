import * as React from 'react'

export function useLoseFocus(ref, onLoseFocus, deps) {
  // ref to avoid blur running after mousedown
  const hasRunLock = React.useRef(false)
  // ref to detect if mousedown outside was done after mousedown inside
  const lastClickTarget = React.useRef()

  React.useEffect(() => {
    if (ref.current !== null) {
      ref.current.addEventListener('blur', handleLoseFocus, { capture: true })
    }
    window.addEventListener('mousedown', handleClickOutside)

    return () => {
      if (ref.current !== null) {
        ref.current.removeEventListener('blur', handleLoseFocus, { capture: true })
      }
      window.removeEventListener('mousedown', handleClickOutside)
    }
  }, deps)

  // If click done outside the element (and last click was inside), prevent `handleLoseFocus` to run and run onLoseFocus
  function handleClickOutside(event) {
    if (ref.current === null) {
      return
    }
    if (!ref.current.contains(event.target) && ref.current.contains(lastClickTarget.current || null)) {
      hasRunLock.current = true
      onLoseFocus()
    }
    lastClickTarget.current = event.target
  }

  // If a child blurs out and an element outside gain focus (the focus leaves the element or its children), and `handleClickOutside` has not already run, run onLoseFocus
  function handleLoseFocus(event) {
    if (ref.current === null) {
      return
    }
    if (!ref.current.contains(event.relatedTarget)) {
      if (!hasRunLock.current) {
        onLoseFocus()
      }
      hasRunLock.current = false
    }
  }
}
