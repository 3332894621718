import styled from 'styled-components'

import * as colors from '@colors'

const ShadowBox = styled.div`
  background-color: ${colors.PRIMARY_WHITE};
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
`

export default ShadowBox
