import * as React from 'react'
import styled from 'styled-components'

import * as COLORS from '@colors'

import { Icon } from '../Icon'
import { SpecificIconProps } from '../types'

export const DoubleDose = styled(function DoubleDose(props: SpecificIconProps): React.ReactElement<SpecificIconProps> {
  return (
    <Icon {...props} name="double-dose" viewBox="0 0 48 48">
      <circle cx="24" cy="24" r="24" fill={COLORS.PRIMARY_SAND} />
      <g fill="none" fillRule="evenodd" stroke={COLORS.GREY_SHADE_7}>
        <path strokeWidth="2" d="M25 17a1 1 0 011 1h0v14a3 3 0 01-3 3h0-10a3 3 0 01-3-3h0V18a1 1 0 011-1h0z" />
        <path strokeWidth="0" fill={COLORS.GREY_SHADE_2} d="M12 26h12v6a1 1 0 01-1 1H13a1 1 0 01-1-1v-6z" />
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          d="M15 13v-1.71l1-2.58V7M20 13v-1.71l1-2.58V7"
        />
        <g>
          <path
            fill={COLORS.PRIMARY_SAND}
            strokeWidth="2"
            d="M35 21a1 1 0 011 1h0v14a3 3 0 01-3 3h0-10a3 3 0 01-3-3h0V22a1 1 0 011-1h0z"
          />
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M36 34a4.5 4.5 0 100-9" />
          <path strokeWidth="0" fill={COLORS.GREY_SHADE_2} d="M22 30h12v6a1 1 0 01-1 1H23a1 1 0 01-1-1v-6z" />
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M25 17v-1.71l1-2.58V11M30 17v-1.71l1-2.58V11"
          />
        </g>
      </g>
    </Icon>
  )
})``

export const Espresso = styled(function Espresso(props: SpecificIconProps): React.ReactElement<SpecificIconProps> {
  return (
    <Icon {...props} name="espresso" viewBox="0 0 48 48">
      <circle cx="24" cy="24" r="24" fill={COLORS.PRIMARY_SAND} />
      <g fill="none" fillRule="evenodd" stroke={COLORS.GREY_SHADE_7}>
        <path strokeWidth="2" d="M30 18a1 1 0 011 1h0v14a3 3 0 01-3 3h0-10a3 3 0 01-3-3h0V19a1 1 0 011-1h0z" />
        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M31 31a4.5 4.5 0 100-9" />
        <path strokeWidth="0" fill={COLORS.GREY_SHADE_2} d="M17 27h12v6a1 1 0 01-1 1H18a1 1 0 01-1-1v-6z" />
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          d="M20 14v-1.71l1-2.58V8M25 14v-1.71l1-2.58V8"
        />
      </g>
    </Icon>
  )
})``

export const Lungo = styled(function Lungo(props: SpecificIconProps): React.ReactElement<SpecificIconProps> {
  return (
    <Icon {...props} name="lungo" viewBox="0 0 48 48">
      <circle cx="24" cy="24" r="24" fill={COLORS.PRIMARY_SAND} />
      <g fill="none" fillRule="evenodd" stroke={COLORS.GREY_SHADE_7}>
        <path strokeWidth="2" d="M33 18a1 1 0 011 1h0v14a3 3 0 01-3 3h0-16a3 3 0 01-3-3h0V19a1 1 0 011-1h0z" />
        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M34 31a4.5 4.5 0 100-9" />
        <path strokeWidth="0" fill={COLORS.GREY_SHADE_2} d="M14 25h18v8a1 1 0 01-1 1H15a1 1 0 01-1-1v-8z" />
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          d="M18 14v-1.71l1-2.58V8M23 14v-1.71l1-2.58V8M28 14v-1.71l1-2.58V8"
        />
      </g>
    </Icon>
  )
})``

export const Mystery = styled(function Mystery(props: SpecificIconProps): React.ReactElement<SpecificIconProps> {
  return (
    <Icon {...props} name="mystery" viewBox="0 0 48 48">
      <circle cx="24" cy="24" r="24" fill={COLORS.PRIMARY_SAND} />
      <g fill="none" fillRule="evenodd" stroke={COLORS.GREY_SHADE_7}>
        <path strokeWidth="2" d="M33 18a1 1 0 011 1h0v14a3 3 0 01-3 3h0-16a3 3 0 01-3-3h0V19a1 1 0 011-1h0z" />
        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M34 31a4.5 4.5 0 100-9" />
        <path
          strokeWidth="0"
          fill={COLORS.GREY_SHADE_7}
          fillRule="nonzero"
          d="M23.06 29.84l.04-.87c0-.75.35-1.07 1.12-1.3a3.3 3.3 0 002.41-3.2c0-1-.34-1.8-1.02-2.4a3.6 3.6 0 00-2.5-.92c-.96 0-1.76.32-2.43.99-.64.64-.97 1.5-.97 2.56h1.69c0-1.08.76-1.81 1.71-1.81.97 0 1.79.64 1.79 1.58 0 .52-.22.95-.68 1.27-.5.34-1.33.73-1.72.95-.18.12-.39.27-.6.46-.38.36-.54.94-.54 1.82v.87h1.7zm-.83 3.31c.68 0 1.2-.47 1.2-1.14 0-.66-.52-1.15-1.2-1.15-.66 0-1.2.5-1.2 1.15 0 .67.54 1.14 1.2 1.14z"
        />
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          d="M18 14v-1.71l1-2.58V8M23 14v-1.71l1-2.58V8M28 14v-1.71l1-2.58V8"
        />
      </g>
    </Icon>
  )
})``
