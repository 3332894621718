import * as PropTypes from 'prop-types'
import styled from 'styled-components'

import { BasicLink } from '@legacy/Links'

import * as fonts from '@fonts'
import * as colors from '@colors'

export const Title1 = styled.h1`
  font-family: ${fonts.PRIMARY_FONT};
  font-weight: 500;
  font-size: 26px;
  color: ${colors.PRIMARY_BLACK};
  margin: 0;
  text-align: ${props => props.align || 'left'};

  ${BasicLink /* sc-sel */} {
    font-size: 26px;
  }
`

Title1.propTypes = {
  align: PropTypes.oneOf(['left', 'center', 'right', 'justify']),
  as: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
}

export const Title2 = styled.h2`
  font-family: ${fonts.PRIMARY_FONT};
  font-weight: 500;
  font-size: 18px;
  color: ${colors.PRIMARY_BLACK};
  margin: 0;
  text-align: ${props => props.align || 'left'};

  ${BasicLink /* sc-sel */} {
    font-size: 18px;
  }
`

Title2.propTypes = {
  align: PropTypes.oneOf(['left', 'center', 'right', 'justify']),
  as: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
}

export const Title3 = styled.h3`
  font-family: ${fonts.PRIMARY_FONT};
  font-weight: 400;
  font-size: 17px;
  color: ${colors.PRIMARY_BLACK};
  margin: 0;
  text-align: ${props => props.align || 'left'};

  ${BasicLink /* sc-sel */} {
    font-size: 17px;
  }
`

Title3.propTypes = {
  align: PropTypes.oneOf(['left', 'center', 'right', 'justify']),
  as: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
}

export const Title4 = styled.h4`
  font-family: ${fonts.SECONDARY_FONT};
  font-weight: 500;
  font-size: 38px;
  color: ${colors.PRIMARY_GREEN};
  margin: 0;
  text-align: ${props => props.align || 'left'};

  ${BasicLink /* sc-sel */} {
    font-size: 18px;
  }
`

Title4.propTypes = {
  align: PropTypes.oneOf(['left', 'center', 'right', 'justify']),
  as: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
}

export const Title5 = styled.h5`
  font-family: ${fonts.PRIMARY_FONT};
  font-weight: 500;
  font-size: 24px;
  color: ${colors.PRIMARY_BLACK};
  margin: 0;
  text-align: ${props => props.align || 'left'};

  ${BasicLink /* sc-sel */} {
    font-size: 24px;
  }
`

Title5.propTypes = {
  align: PropTypes.oneOf(['left', 'center', 'right', 'justify']),
  as: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
}

export const Title6 = styled.h6`
  font-family: ${fonts.PRIMARY_FONT};
  font-weight: 500;
  font-size: 10px;
  text-transform: uppercase;
  color: ${colors.GREY_SHADE_3};
  margin: 0 0 5px;
  text-align: ${props => props.align || 'left'};

  ${BasicLink /* sc-sel */} {
    font-size: 10px;
  }
`

Title6.propTypes = {
  align: PropTypes.oneOf(['left', 'center', 'right', 'justify']),
  as: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
}
