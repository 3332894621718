import * as React from 'react'
import styled from 'styled-components'
import * as PropTypes from 'prop-types'

import Icon from './Icon'

import * as colors from '@colors'
import * as fonts from '@fonts'
import * as bp from '@bp'

const ErrorWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  /* stylelint-disable-next-line */
  background: linear-gradient(
    180deg,
    rgba(247, 247, 245, 1) 0%,
    rgba(255, 255, 255, 1) 50%,
    rgba(229, 232, 236, 1) 100%
  );

  /* only for small screen */
  @media (max-width: 768px) {
    /* stylelint-disable-next-line */
    background: ${props => `url(${props.fallback})`} center center no-repeat,
      linear-gradient(180deg, rgba(247, 247, 245, 1) 0%, rgba(255, 255, 255, 1) 50%, rgba(229, 232, 236, 1) 100%);
    background-size: cover;
  }
`

const ErrorBgVideo = styled.video`
  width: 100%;
  height: 100%;
  position: absolute;
  background-size: cover;
  transform: translate(-50%, -50%);
  object-fit: cover;
  left: 50%;
  top: 50%;
  min-width: 100%;
  min-height: 100%;

  /* only for big screen */
  @media (max-width: 768px) {
    display: none;
  }
`

const ErrorName = styled.h1`
  font-weight: 700;
  font-size: 25px;
  font-family: ${fonts.PRIMARY_FONT};
  color: ${colors.PRIMARY_BLACK};
  text-align: center;
  position: absolute;
  top: 20px;
  left: 50%;
  transform: translate(-50%);
  margin: 0;

  &::after {
    content: '';
    width: 100%;
    height: 4px;
    background-color: ${colors.PRIMARY_BLACK};
    position: absolute;
    display: block;
    margin-top: 2px;
  }

  @media ${bp.TABLET} {
    font-size: 35px;
  }

  @media ${bp.LAPTOP} {
    font-size: 45px;
  }
`

const ErrorContentWrapper = styled.div`
  width: 100%;
  height: auto;
  padding: 0 20px 20px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media ${bp.TABLET} {
    padding: 0 20px 30px;
  }
`

const ErrorContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  & > p {
    margin: 0;
  }

  & > p + p {
    margin-top: 10px;
  }
`

const ErrorTitle = styled.span`
  display: inline;
  background-color: ${props => props.backgroundColor};
  font-family: ${fonts.PRIMARY_FONT};
  color: ${colors.PRIMARY_WHITE};
  font-weight: 700;
  text-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  font-size: 34px;
  line-height: 1.2;
  margin: 0;
  padding: 0 5px;
  box-decoration-break: clone;
  white-space: pre-wrap;

  @media ${bp.TABLET} {
    font-size: 45px;
  }

  @media ${bp.LAPTOP} {
    font-size: 65px;
  }
`

const ErrorSubtitle = styled(ErrorTitle)`
  margin-top: 10px;
  font-size: 18px;

  @media ${bp.TABLET} {
    font-size: 26px;
  }

  @media ${bp.LAPTOP} {
    font-size: 32px;
  }
`

const ErrorHomeButton = styled.a`
  color: ${colors.PRIMARY_BLACK};
  text-decoration: none;
  font-family: ${fonts.PRIMARY_FONT};
  font-weight: 700;
  font-size: 15px;
  flex: 0;
  position: relative;

  &::after {
    content: '';
    width: 100%;
    height: 4px;
    background-color: ${colors.PRIMARY_BLACK};
    position: absolute;
    display: block;
    margin-top: 2px;
    left: 50%;
    transform: translateX(-50%);
  }

  &:hover {
    text-shadow: 0 1px 3px rgba(0, 0, 0, 0.24), 0 1px 2px rgba(0, 0, 0, 0.48);
  }

  @media ${bp.TABLET} {
    margin-top: 50px;
    font-size: 22px;
  }

  @media ${bp.LAPTOP} {
    font-size: 30px;
  }
`

const ErrorInputSearch = styled.input`
  border-radius: 2px;
  height: 50px;
  padding: 0 20px;
  outline: none;
  background-color: ${colors.PRIMARY_WHITE};
  font-family: ${fonts.PRIMARY_FONT};
  font-size: 15px;
  color: ${colors.PRIMARY_BLACK};
  border: 1px solid ${colors.GREY_SHADE_5};
  flex: 1;
  max-width: 300px;

  &:focus {
    border: 1px solid ${props => colors.primary(props)};
  }

  &::placeholder {
    color: ${colors.GREY_SHADE_3};
  }
`

const ErrorSubmitButton = styled.button`
  width: 50px;
  height: 50px;
  background-color: ${colors.PRIMARY_BLACK};
  color: ${colors.PRIMARY_WHITE};
  padding: 0 16px;
  border: none;
  border-radius: 2px;
  transition: all 200ms ease-in-out;
  margin-left: 10px;
  cursor: pointer;
  flex: 0;

  &:hover {
    background-color: ${colors.PRIMARY_BLACK_HOVER};
  }
`

const ErrorSearchForm = styled.form`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
`

const ErrorBodyWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  position: relative;
  flex-direction: column;
`

const ErrorButtonWrapper = styled.div`
  margin-top: 20px;

  @media ${bp.TABLET} {
    margin-top: 50px;
  }
`

/** ErrorPage is used to display fancy error pages */
const ErrorPage = ({ color, translations, search, urls, bg }) => (
  <ErrorWrapper fallback={bg && bg.image}>
    <ErrorBgVideo autoPlay loop muted preload="auto">
      {bg && bg.video && bg.video.webm && <source src={bg.video.webm} type="video/webm" />}
      {bg && bg.video && bg.video.mp4 && <source src={bg.video.mp4} type="video/mp4" />}
    </ErrorBgVideo>
    <ErrorBodyWrapper>
      <div>{translations && translations.errorName && <ErrorName>{translations.errorName}</ErrorName>}</div>
      <ErrorContentWrapper>
        <div>
          {search && search.enable && (
            <ErrorSearchForm method="GET" action={search.action}>
              <ErrorInputSearch
                name="search"
                type="text"
                placeholder={translations && translations.searchPlaceholder}
              />
              <ErrorSubmitButton type="submit">
                <Icon glyph="search" size={20} />
              </ErrorSubmitButton>
            </ErrorSearchForm>
          )}
          <ErrorContent>
            <p>
              <ErrorTitle backgroundColor={color}>{translations && translations.title}</ErrorTitle>
            </p>
            <p>
              <ErrorSubtitle backgroundColor={color}>{translations && translations.subtitle}</ErrorSubtitle>
            </p>
          </ErrorContent>
        </div>
        {urls && urls.home && (
          <ErrorButtonWrapper>
            <ErrorHomeButton href={urls && urls.home}>{translations && translations.link}</ErrorHomeButton>
          </ErrorButtonWrapper>
        )}
      </ErrorContentWrapper>
    </ErrorBodyWrapper>
  </ErrorWrapper>
)

ErrorPage.propTypes = {
  /** color of the background for sentences */
  color: PropTypes.string.isRequired,
  translations: PropTypes.shape({
    errorName: PropTypes.string,
    title: PropTypes.string,
    subtitle: PropTypes.string,
    link: PropTypes.string,
    searchPlaceholder: PropTypes.string,
  }),
  search: PropTypes.shape({
    enable: PropTypes.bool.isRequired,
    action: PropTypes.string,
    onFocus: PropTypes.func,
    onSubmit: PropTypes.func,
    onUpdate: PropTypes.func,
  }),
  urls: PropTypes.shape({
    home: PropTypes.string,
  }),
  bg: PropTypes.shape({
    video: PropTypes.shape({
      mp4: PropTypes.string,
      webm: PropTypes.string,
    }).isRequired,
    image: PropTypes.string.isRequired,
  }),
}

export default ErrorPage
ErrorPage.displayName = 'legacy.ErrorPage'
