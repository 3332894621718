import styled, { css } from 'styled-components'

import * as colors from '@colors'
import { Icon } from '@glyphs/index'

export const DropdownStyle = css`
  background-color: ${colors.PRIMARY_WHITE};
  box-shadow: 1px 4px 3px 1px rgba(0, 0, 0, 0.12);
`

export const Dropdown = styled.div`
  ${DropdownStyle}
`

export const MenuDropdownTitle = styled.div<{ isOpen: boolean }>`
  ${Icon} {
    margin-right: 7px;
  }

  a {
    ${({ isOpen }) => {
      if (isOpen) {
        return css`
          color: ${colors.PRIMARY_BLUE};
        `
      }
    }};
  }
`
