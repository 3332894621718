import * as React from 'react'
import * as PropTypes from 'prop-types'

import * as S from './styles'
import { Detail } from './Label'

const RadioGroupContext = React.createContext()

/** Wrapper component used for RadioOption */
export class RadioGroup extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      value: props.initialValue || '',
    }
  }

  handleChange = value => {
    const { onChange } = this.props

    if (value) {
      this.setState(
        {
          value,
        },
        () => {
          if (onChange && typeof onChange === 'function') {
            onChange(value)
          }
        },
      )
    }
  }

  render() {
    const { children, name, small, error } = this.props
    const { value } = this.state

    return (
      <RadioGroupContext.Provider
        value={{
          onChange: this.handleChange,
          value,
          name,
          small,
        }}
      >
        {small ? <S.SmallRadioGroup>{children}</S.SmallRadioGroup> : children}
        {error && <Detail error={!!error}>{error}</Detail>}
      </RadioGroupContext.Provider>
    )
  }
}

RadioGroup.propTypes = {
  /** name attribute of input element */
  name: PropTypes.string.isRequired,
  /**	allow to prefill one of the option */
  initialValue: PropTypes.string,
  /** mandatory function to retrieve value */
  onChange: PropTypes.func,
  /** browser will display an error if input is empty on form submitting */
  required: PropTypes.bool,
  /** list of RadioOption */
  children: PropTypes.any,
  /** if small inline list */
  small: PropTypes.bool,
  /** error message */
  error: PropTypes.string,
}

/** Radio component used for single choice. */
export const RadioOption = ({ label, description, smallOption, error, disabled, value, ...props }) => (
  <RadioGroupContext.Consumer>
    {({ onChange, value: currentValue, name, small }) => {
      const isSelected = value === currentValue
      const handleClick = !disabled ? () => onChange(value) : undefined
      const isSmall = smallOption || small

      return (
        <S.RadioContainer onClick={handleClick} error={error} checked={isSelected} disabled={disabled} small={isSmall}>
          <S.Radio type="radio" name={name} id={value} value={value} error={error} {...props} />
          <S.RadioButton small={small} checked={isSelected} disabled={disabled}>
            {isSelected && <S.RadioButtonFill small={isSmall} />}
          </S.RadioButton>
          <S.RadioInfoContainer>
            {label && (
              <S.RadioInfoLabel htmlFor={name} isSmall={isSmall} disabled={disabled} checked={isSelected}>
                {label}
              </S.RadioInfoLabel>
            )}
            {description && <S.RadioInfoDescription>{description}</S.RadioInfoDescription>}
          </S.RadioInfoContainer>
        </S.RadioContainer>
      )
    }}
  </RadioGroupContext.Consumer>
)

RadioOption.propTypes = {
  /** label for radio button */
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.object]).isRequired,
  /** purpose of this element */
  description: PropTypes.string,
  /** value of the option */
  value: PropTypes.string.isRequired,
  /** Small size */
  smallOption: PropTypes.bool,
  /** error message */
  error: PropTypes.string,
  /** disable the option */
  disabled: PropTypes.bool,
}
