import * as _autocomplete from './form/autocomplete'
import * as _button from './button'
import * as _channelCard from './channelCard'
import * as _checkbox from './form/checkbox'
import * as _container from './container'
import * as _controls from './form/controls'
import * as _copy from './copy'
import * as _dropdown from './dropdown'
import * as _field from './form/field'
import * as _heading from './heading'
import * as _image from './image'
import * as _input from './form/input'
import * as _jewel from './jewel'
import * as _link from './link'
import * as _miniChannelCard from './card/miniChannelCard' // TODO: Group w/ `channelCard`
import * as _modal from './modal'
import * as _notification from './notification'
import * as _option from './form/option'
import * as _password from './form/password'
import * as _phone from './form/phone'
import * as _projectCard from './card/project'
import * as _radio from './form/radio'
import * as _reward from './reward'
import * as _select from './form/select'
import * as _spinner from './spinner'
import * as _tab from './tab'
import * as _tag from './tag'
import * as _textarea from './form/textarea'
import * as _tooltip from './tooltip'
import * as _userCard from './userCard'
import * as _userLabel from './userLabel'

export const autocomplete = _autocomplete
export const button = _button
export const channelCard = _channelCard
export const card = {
  channel: _miniChannelCard,
  project: _projectCard,
}
export const checkbox = _checkbox
export const container = _container
export const controls = _controls
export const copy = _copy
export const dropdown = _dropdown
export const field = _field
export const heading = _heading
export const image = _image
export const input = _input
export const jewel = _jewel
export const link = _link
export const modal = _modal
export const notification = _notification
export const option = _option
export const password = _password
export const phone = _phone
export const radio = _radio
export const reward = _reward
export const select = _select
export const spinner = _spinner
export const tab = _tab
export const tag = _tag
export const textarea = _textarea
export const tooltip = _tooltip
export const userCard = _userCard
export const userLabel = _userLabel
