import styled, { css } from 'styled-components'

import * as bp from '@bp'
import * as colors from '@colors'

export const List = styled.ul<{ shadowWidth: number; scrollable: boolean }>`
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: space-between;
  overflow-x: auto;
  overflow-y: hidden;

  &::after {
    content: '';
    background-image: linear-gradient(to right, rgba(255, 255, 255, 0), #fff 50%);
    display: block;
    height: 100%;
    position: absolute;
    pointer-events: none;
    right: 0;
    top: 0;
    z-index: 2;
    width: ${({ shadowWidth }) => shadowWidth}px;
    ${({ scrollable }) => {
      if (scrollable) {
        return css`
          display: block;
        `
      } else {
        return css`
          display: none;
        `
      }
    }}
  }
`

export const Item = styled.li<{ active: boolean; disabled?: boolean }>`
  display: flex;
  align-items: center;
  padding: 0 15px;
  position: relative;

  &::after {
    content: '';
    background-color: ${colors.PRIMARY_BLACK};
    bottom: 0;
    height: 4px;
    left: 50%;
    position: absolute;
    transform: translateX(-50%);

    ${({ active }) => {
      if (active) {
        return css`
          width: 100%;
          opacity: 1;
        `
      } else {
        return css`
          width: 0;
          opacity: 0;
          transition: all 0.2s ease;
        `
      }
    }}
  }

  @media screen and ${bp.TABLET} {
    ${({ active }) => {
      if (!active) {
        return css`
          &:hover {
            &::after {
              width: 100%;
              opacity: 1;
            }
          }
        `
      }
    }}

    ${({ disabled }) => {
      if (disabled) {
        return css`
          &:hover {
            &::after {
              width: 100%;
              opacity: 0.4;
            }
          }
        `
      }
    }}
  }
`
