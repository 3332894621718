import * as React from 'react'
import * as PropTypes from 'prop-types'
import styled from 'styled-components'

import * as colors from '@colors'
import * as fonts from '@fonts'

import ClickOutside from '../core/ClickOutside'
import Panel from './dropdown/Panel'
import { Title6 } from '@legacy/Titles'

// NOTE: for Firefox & Edge compatibility
const encodeColor = color => color.replace(/#/, '%23')

const Container = styled.span`
  display: inline-flex;
  position: relative;
  flex-direction: ${props => (props.error ? 'column' : 'initial')};
  ${props => props.disabled && `cursor: not-allowed;`}
`

const getBackgroundColor = ({ disabled, inline }) => {
  if (disabled) {
    return colors.GREY_SHADE_6
  }

  if (inline) {
    return 'transparent'
  }

  return colors.PRIMARY_WHITE
}

const getBorderColor = props => {
  if (props.disabled) {
    return colors.GREY_SHADE_5
  }

  if (props.error) {
    return colors.SECONDARY_RED
  }

  if (props.focused) {
    return colors.primary(props)
  }

  return colors.PRIMARY_BLACK
}

const getBorder = ({ disabled, error, inline }) => {
  if (disabled) {
    return '1px solid'
  }

  if (error) {
    return '2px dashed'
  }

  if (inline) {
    return '0'
  }

  return '10px 15px'
}

const getColor = props => {
  if (props.disabled) {
    return colors.GREY_SHADE_3
  }

  if (props.focused && !props.useDefault) {
    return colors.primary(props)
  }

  return colors.PRIMARY_BLACK
}

/* prettier-ignore */
const Select = styled.select`
  appearance: none;
  outline: 0;
  background-color: ${props => getBackgroundColor(props)};
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" fill-rule="evenodd" clip-rule="evenodd" viewBox="0 0 32 32" width="8"><g transform="translate(0, 13)"><path d="M32 .9c-.32-.6-.96-.9-1.918-.9H1.918C.958 0 .319.3 0 .9 0 1.8 0 2.4.32 3l14.079 14.7c.642 0 .96.3 1.6.3.64 0 .96-.3 1.283-.602L31.362 3C32 2.4 32 1.8 32 .9" fill="${props => encodeColor(getColor(props))}"></path></g></svg>');
  background-repeat: no-repeat;
  background-position: ${props => (props.inline ? 'center right 0px' : 'center right 15px')};
  background-size: 8px 8px;
  border: ${props => getBorder(props)};
  border-color: ${props => getBorderColor(props)};
  border-radius: ${props => (props.inline ? 0 : '2px')};
  color: ${props => getColor(props)};
  font-family: ${fonts.PRIMARY_FONT};
  font-size: 15px;
  font-weight: 400;
  padding: ${props => (props.inline ? 0 : '10px 15px')};
  padding-right: ${props => (props.inline ? 'calc(8px + 10px)' : 'calc(15px + 8px + 10px)')};
  cursor: pointer;
  width: ${props => props.width ? `${props.width}px` : 'auto'};
  box-sizing: ${props => (props.autosize || props.inline) ? 'content-box' : 'border-box'};
  ${props => props.disabled && `pointer-events: none;`}

  &:focus,
  &:hover {
    border-color: ${props => colors.primary(props)};
    color: ${props => !props.useDefault && colors.primary(props)};
    background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" fill-rule="evenodd" clip-rule="evenodd" viewBox="0 0 32 32" width="8"><g transform="translate(0, 13)"><path d="M32 .9c-.32-.6-.96-.9-1.918-.9H1.918C.958 0 .319.3 0 .9 0 1.8 0 2.4.32 3l14.079 14.7c.642 0 .96.3 1.6.3.64 0 .96-.3 1.283-.602L31.362 3C32 2.4 32 1.8 32 .9" fill="${props => encodeColor(colors.primary(props))}"></path></g></svg>');
  }
`

const List = styled.span`
  display: flex;
  flex-flow: column nowrap;
  margin: 0;
  padding: 0;
`

const Option = styled.span`
  padding: 10px 0;
  cursor: pointer;
  color: ${props => (props.active ? colors.primary(props) : colors.PRIMARY_BLACK)};
  font-family: ${fonts.PRIMARY_FONT};
  font-size: 13px;
  font-weight: 500;

  &:hover {
    color: ${props => colors.primary(props)};
  }
`

const SizerContainer = styled.span`
  position: absolute;
  top: 0;
  left: 0;
  visibility: hidden;
  height: 0;
  white-space: pre;
  font-family: ${fonts.PRIMARY_FONT};
  font-size: 15px;
  font-weight: 400;
`

const SelectLabel = styled(Title6)`
  background-color: ${colors.PRIMARY_WHITE};
  color: ${props => (props.focused ? colors.primary(props) : colors.GREY_SHADE_3)};
  padding: 5px 10px;
  position: absolute;
  top: 0;
  left: 5px;
  transform: translate(0, -50%);
  box-sizing: content-box;
  cursor: pointer;
  pointer-events: none;

  ${Container /* sc-sel */}:hover & {
    color: ${props => colors.primary(props)};
  }

  ${Select /* sc-sel */}:focus & {
    color: ${props => colors.primary(props)};
  }
`

const ErrorText = styled.div`
  font-family: ${fonts.PRIMARY_FONT}, sans-serif;
  font-weight: 500;
  font-size: 13px;
  color: ${props => (props.error ? `${colors.SECONDARY_RED}` : `${colors.PRIMARY_GREEN}`)};
  margin-top: 7px;
`

class DropdownFilter extends React.Component {
  static defaultProps = {
    useDefault: false,
    inline: false,
    autosize: false,
    focused: false,
    disabled: false,
    required: false,
  }

  /**
   * @param {object} props
   * @param {boolean} [props.alignLeft] - align dropdown box to the left. If falsy, will align to right (default)
   * @param {string} props.name - name attribute of HTML5 select
   * @param {string} [props.label] - label displayed in top of component
   * @param {string} props.value - current value of the dropdown, by default the first option
   * @param {any[]} props.options - list of options availables in dropdown
   * @param {boolean} [props.useDefault] - if true, on click it will open native select dropdown
   * @param {boolean} [props.focused] - controls the focus
   * @param {boolean} [props.disabled]
   * @param {boolean|string} [props.error] - error indicator or message
   * @param {boolean} [props.required]
   * @param {boolean} [props.inline] - changes the style of component (useful with text)
   * @param {boolean} [props.autosize] - controls if the component is fitted to the current value (true) or the longest value in options (false)
   * @param {() => void} props.onChange - trigger when a different option is clicked
   * @param {() => void} [props.onFocus]
   * @param {() => void} [props.onBlur]
   * @param {() => void} [props.onClick]
   */
  constructor(props) {
    super(props)
    this.state = {
      opened: false,
      focused: this.props.focused,
      sizerWidth: 0,
    }
  }

  componentDidMount() {
    this.mounted = true
    this.updateSizerWidth()
  }

  componentDidUpdate(prevProps) {
    if (prevProps.focused !== this.props.focused) {
      this.setState({ focused: this.props.focused })
    }

    if (prevProps.value !== this.props.value) {
      this.updateSizerWidth()
    }
  }

  componentWillUnmount() {
    this.mounted = false
  }

  preventNative = event => {
    if (!event.defaultPrevented && !this.props.useDefault) {
      event.preventDefault()
    }
  }

  handleClick = event => {
    if (this.props.onClick) {
      this.props.onClick(event)
    }

    if (event.defaultPrevented || this.props.useDefault) {
      return
    }

    event.preventDefault()
    event.stopPropagation()
    this.openMenu()
  }

  handleClickOption = event => {
    const value = event.target.dataset.value

    if (this.state.value !== value) {
      this.setState({ opened: false, focused: false })

      if (this.props.onChange) {
        const newOption = this.getOptionByValue(value)
        this.props.onChange(newOption)
      }
    }
  }

  getOptionByValue = value => {
    const found = this.props.options.find(option => option.value === value)

    if (!found) {
      return this.props.options[0]
    }

    return found
  }

  openMenu = () => {
    this.setState({ opened: true, focused: true })
  }

  closeMenu = () => {
    this.setState({ opened: false, focused: false })
  }

  getSizerRef = ref => {
    this.sizer = ref
  }

  getLabelRef = ref => {
    this.label = ref
  }

  updateSizerWidth = () => {
    if ((!this.props.autosize && !this.props.inline) || !this.mounted || !this.sizer) {
      return
    }

    let newSizerWidth = this.sizer.scrollWidth * (1 + 15 / 100)

    if (this.label && this.label.scrollWidth > newSizerWidth) {
      newSizerWidth = this.label.scrollWidth * (1 + 15 / 100)
    }

    this.setState({ sizerWidth: Math.floor(newSizerWidth) })
  }

  renderMenu() {
    const { alignLeft, value, options } = this.props

    return (
      <ClickOutside onClickOutside={this.closeMenu}>
        <Panel as="span" alignLeft={alignLeft}>
          <List>
            {options.map(option => (
              <Option
                key={option.value}
                data-value={option.value}
                onClick={this.handleClickOption}
                active={value === option.value}
              >
                {option.label}
              </Option>
            ))}
          </List>
        </Panel>
      </ClickOutside>
    )
  }

  render() {
    const {
      autosize,
      name,
      label,
      value,
      options,
      disabled,
      required,
      onChange,
      inline,
      onBlur,
      onFocus,
      useDefault,
      error,
    } = this.props
    const { focused, sizerWidth, opened } = this.state
    const displayLabel = label && !inline

    return (
      <Container disabled={disabled} error={error}>
        {displayLabel && (
          <SelectLabel
            as="label"
            htmlFor={name}
            focused={focused}
            ref={this.getLabelRef}
            onMouseDown={!useDefault ? this.preventNative : undefined}
            onClick={!useDefault ? this.handleClick : undefined}
          >
            {label}
          </SelectLabel>
        )}
        <Select
          autosize={autosize}
          inline={inline}
          name={name}
          onMouseDown={this.preventNative}
          onClick={this.handleClick}
          onBlur={onBlur}
          onFocus={onFocus}
          onChange={onChange}
          value={value}
          disabled={disabled}
          required={required}
          focused={focused}
          useDefault={useDefault}
          width={sizerWidth}
          error={error}
        >
          {options.map(option => (
            <option key={`option-${option.value}`} value={option.value}>
              {option.label}
            </option>
          ))}
        </Select>
        <SizerContainer ref={this.getSizerRef}>{this.getOptionByValue(value).label}</SizerContainer>
        {opened && this.renderMenu()}
        {error && typeof error === 'string' && <ErrorText error={!!error}>{error}</ErrorText>}
      </Container>
    )
  }
}

DropdownFilter.defaultProps = {
  alignLeft: false,
  useDefault: false,
  inline: false,
  autosize: false,
  focused: false,
  disabled: false,
  required: false,
  onChange: () => {},
}

DropdownFilter.propTypes = {
  /** align dropdown box to the left. If falsy, will align to right (default) */
  alignLeft: PropTypes.bool,
  /** name attribute of HTML5 select */
  name: PropTypes.string.isRequired,
  /** label displayed in top of component */
  label: PropTypes.string,
  /** current value of the dropdown, by default the first option */
  value: PropTypes.string.isRequired,
  /** list of options availables in dropdown */
  options: PropTypes.array.isRequired,
  /** if true, on click it will open native select dropdown */
  useDefault: PropTypes.bool,
  /** controls the focus */
  focused: PropTypes.bool,
  disabled: PropTypes.bool,
  /** error indicator or message */
  error: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  required: PropTypes.bool,
  /** changes the style of component (useful with text) */
  inline: PropTypes.bool,
  /** controls if the component is fitted to the current value (true) or the longest value in options (false) */
  autosize: PropTypes.bool,
  /** trigger when a different option is clicked */
  onChange: PropTypes.func.isRequired,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  onClick: PropTypes.func,
}

export default DropdownFilter
DropdownFilter.displayName = 'legacy.DropdownFilter'
