import * as React from 'react'
import styled, { css } from 'styled-components'

import * as colors from '@colors'
import * as fonts from '@fonts'

export const Base = styled.div<React.HTMLAttributes<HTMLDivElement>>`
  color: ${colors.PRIMARY_BLACK};
  font-family: ${fonts.PRIMARY_FONT};
  margin: 0;
  padding: 0;
`

export const XXXL = styled(Base)<React.HTMLAttributes<HTMLDivElement>>`
  font-size: 76px;
  font-style: italic;
  font-weight: 900;
  line-height: 76px;
`
XXXL.displayName = 'S.heading.XXXL'

export const XXL = styled(Base)<React.HTMLAttributes<HTMLDivElement>>`
  font-size: 65px;
  line-height: 65px;
  font-weight: 900;
`
XXL.displayName = 'S.heading.XXL'

export const XL = styled(Base)<React.HTMLAttributes<HTMLDivElement>>`
  font-size: 44px;
  line-height: 44px;
  font-weight: 900;
`
XL.displayName = 'S.heading.XL'

export const _largeStyle = css`
  font-size: 30px;
  line-height: 31px;
  font-weight: 900;
`

export const L = styled(Base)<React.HTMLAttributes<HTMLDivElement>>`
  ${_largeStyle}
`
L.displayName = 'S.heading.L'

export const serifM = styled(Base)<React.HTMLAttributes<HTMLDivElement>>`
  font-size: 26px;
  line-height: 35px;
  font-family: ${fonts.SECONDARY_FONT};
`
serifM.displayName = 'S.heading.serifM'

export const _mediumStyle = css`
  font-size: 26px;
  line-height: 34px;
  font-weight: 500;
`

export const M = styled(Base)<React.HTMLAttributes<HTMLDivElement>>`
  ${_mediumStyle}
`
M.displayName = 'S.heading.M'

export const _smallStyle = css`
  font-size: 23px;
  line-height: 30px;
  font-weight: 500;
`

export const S = styled(Base)<React.HTMLAttributes<HTMLDivElement>>`
  ${_smallStyle}
`
S.displayName = 'S.heading.S'

export const _xSmallStyle = css`
  font-size: 19px;
  line-height: 26px;
  font-weight: 600;
`

export const XS = styled(Base)<React.HTMLAttributes<HTMLDivElement>>`
  ${_xSmallStyle}
`
XS.displayName = 'S.heading.XS'

export const _xxSmallStyle = css`
  font-size: 18px;
  line-height: 24px;
  font-weight: 500;
`

export const XXS = styled(Base)<React.HTMLAttributes<HTMLDivElement>>`
  ${_xxSmallStyle}
`
XXS.displayName = 'S.heading.XXS'

export const _xxxSmallStyle = css`
  font-size: 16px;
  line-height: 22px;
  font-weight: 600;
`

export const XXXS = styled(Base)<React.HTMLAttributes<HTMLDivElement>>`
  ${_xxxSmallStyle}
`
XXXS.displayName = 'S.heading.XXXS'

export const _xxxxSmallStyle = css`
  font-size: 15px;
  line-height: 18px;
  font-weight: 700;
  color: ${colors.GREY_SHADE_2};
  text-transform: uppercase;
`

export const XXXXS = styled(Base)<React.HTMLAttributes<HTMLDivElement>>`
  ${_xxxxSmallStyle}
`
XXXXS.displayName = 'S.heading.XXXXS'

export const Card1 = styled(Base)<React.HTMLAttributes<HTMLDivElement>>`
  font-size: 13px;
  line-height: 18px;
  font-weight: 500;
  color: ${colors.GREY_SHADE_1};
`
Card1.displayName = 'S.heading.Card1'

export const Card2 = styled(Base)<React.HTMLAttributes<HTMLDivElement>>`
  font-size: 12px;
  line-height: 16px;
  font-weight: 400;
  color: ${colors.GREY_SHADE_1};
`
Card2.displayName = 'S.heading.Card2'

export const Card3 = styled(Base)<React.HTMLAttributes<HTMLDivElement>>`
  font-size: 12px;
  line-height: 16px;
  font-weight: 300;
  color: ${colors.GREY_SHADE_1};
`
Card3.displayName = 'S.heading.Card3'

export const XXXXXS = styled(Base)<React.HTMLAttributes<HTMLDivElement>>`
  font-size: 10px;
  line-height: 12px;
  font-weight: 500;
  color: ${colors.GREY_SHADE_1};
  text-transform: uppercase;
`
XXXXXS.displayName = 'S.heading.XXXXXS'
