import styled, { css } from 'styled-components'
import * as colors from '@colors'

export type RadiomarkStatus = 'checked' | 'focused'

type RadiomarkProps = {
  size?: number
  status?: RadiomarkStatus
  disabled?: boolean
}

export const Radiomark = styled.span<RadiomarkProps>`
  display: inline-block;
  color: inherit;
  fill: currentColor;
  vertical-align: baseline;
  border: 2px solid ${colors.GREY_SHADE_4};
  position: relative;
  border-radius: 50%;
  background-color: ${colors.PRIMARY_WHITE};
  box-sizing: border-box;
  ${({ size = 18 }) => css`
    width: ${size}px;
    height: ${size}px;
  `}
  ${({ status }) => {
    if (status === 'checked') {
      return css`
        border-color: ${colors.PRIMARY_BLUE};
      `
    }
    if (status === 'focused') {
      return css`
        border-color: ${colors.GREY_SHADE_3};
      `
    }
  }}
  ${({ disabled }) =>
    disabled &&
    css`
      border-color: ${colors.GREY_SHADE_4};
      background-color: ${colors.GREY_SHADE_6};
    `}

  &::before {
    content: "";
    position: absolute;
    top: 3px;
    left: 3px;
    border-radius: 50%;
    background-color: ${colors.PRIMARY_BLUE};
    ${({ size = 18 }) => css`
      width: ${size - 10}px;
      height: ${size - 10}px;
    `}
    ${({ status }) => {
      if (status !== 'checked') {
        return css`
          display: none;
        `
      }
    }}
    ${({ disabled }) =>
      disabled &&
      css`
        background-color: ${colors.GREY_SHADE_4};
      `}
  }
`

export const SmallGroup = styled.div`
  display: inline-flex;
  flex-direction: column;
`

export const BigGroup = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`
