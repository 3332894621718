import styled from 'styled-components'

import * as colors from '@colors'

const ProjectCardReverse = styled.div`
  background-color: ${props => (props.isSelected ? colors.PRIMARY_GREEN : colors.primary(props))};
  color: ${colors.PRIMARY_WHITE};
  font-size: 15px;
  font-weight: 300;
  line-height: 20px;
  padding: 15px 20px 20px;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  overflow: hidden;
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-between;

  span {
    display: block;
  }

  strong {
    font-weight: 500;
  }
`

const ProjectCardReverseData = styled.small`
  font-size: 12px;
  display: block;
  line-height: normal;
`

export { ProjectCardReverse, ProjectCardReverseData }
