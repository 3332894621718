import * as BREAKPOINTS from '@bp'
import * as COLORS from '@colors'
import * as FONTS from '@fonts'

import { RegularText, ImportantText, DiscreteText, BoringText } from './Text'
import { PrimaryButton, SecondaryButton, TertiaryButton, ButtonWithIcon } from './Buttons'
import SocialButton from './SocialButton'
import { CallToAction, SecondaryCallToAction } from './CallToAction'
import ProgressEgg from './ProgressEgg'
import ProjectCard from './ProjectCard'
import HorizontalProjectCard from './ProjectCard/HorizontalProjectCard'
import PartnerCard from './PartnerCard'
import CategoryCard from './CategoryCard'
import IllustratedTitle from './IllustratedTitle'
import { Title1, Title2, Title3, Title4, Title5, Title6 } from './Titles'
import { PrimaryLink, SecondaryLink, TertiaryLink } from './Links'
import Icon from './Icon'
import Avatar from './Avatar'
import { LocaleMoneyDisplayer } from '../core/Accounting'
import I18nSettingsBox from './I18nSettingsBox'
import Footer from './Footer'
import Dropdown from './Dropdown'
import FilterButton from './FilterButton'
import DropdownFilter from './DropdownFilter'
import LoadMoreButton from './LoadMoreButton'
import FlashMessage from './FlashMessage'
import Pastille from './Pastille'
import Badge from './Badge'
import VideoLoader from './VideoLoader'
import SponsorshipBanner from './sponsorship/Banner'
import TinySponsorshipBanner from './sponsorship/TinyBanner'
import Sponsor from './sponsorship/Sponsor'
import UserCard from './UserCard'
import { ClassicModal, NotificationModal, Dialog } from './Modal'
import Carousel from './Carousel'
import { RewardCard } from './RewardCard/RewardCard'
import { Variant } from './RewardCard/Variant'
import { Select as SimpleSelect } from './components/form/select/Select'
import { Option as SimpleOption } from './components/form/select/Option'
import ErrorPage from './ErrorPage'
import Spinner from './Spinner'
import Tabs from './Tabs'
import Input from './form/Input'
import PhoneInput from './form/PhoneInput/PhoneInput'
import { RadioOption, RadioGroup } from './form/Radio'
import Checkbox from './form/Checkbox'
import Textarea from './form/Textarea'
import Select from './form/Select'
import MultiselectDropdown from './dropdown/MultiselectDropdown'
import ListFilter from './dropdown/ListFilter'
import TextEllipsis from '../core/TextEllipsis'
import Tag from './Tag'
import ClickOutside from '../core/ClickOutside'
import Jewel from './Jewel'
import RoundButton from './RoundButton'
import { Tooltip } from './Tooltip'

if (typeof window !== 'undefined') {
  window.OWL_KIT_COMPONENTS_VERSION = 'OWL_KIT_VERSION / OWL_KIT_ENV'
}

// eslint-disable-next-line no-undef
if (process.env.NODE_ENV !== 'production' && typeof window !== 'undefined') {
  console.warn('[owl-kit-components] You currently use the non-production bundle of the library.') // eslint-disable-line no-console
  console.info(`owl-kit-components version: ${window.OWL_KIT_COMPONENTS_VERSION}`) // eslint-disable-line no-console
}

export {
  // Constants
  BREAKPOINTS,
  COLORS,
  FONTS,
  // Components
  RegularText,
  ImportantText,
  DiscreteText,
  BoringText,
  Avatar,
  PrimaryButton,
  SecondaryButton,
  TertiaryButton,
  LoadMoreButton,
  ButtonWithIcon,
  SocialButton,
  CallToAction,
  SecondaryCallToAction,
  ProgressEgg,
  ProjectCard,
  HorizontalProjectCard,
  PartnerCard,
  CategoryCard,
  Title1,
  Title2,
  Title3,
  Title4,
  Title5,
  Title6,
  IllustratedTitle,
  PrimaryLink,
  SecondaryLink,
  TertiaryLink,
  Icon,
  Pastille,
  LocaleMoneyDisplayer,
  Footer,
  I18nSettingsBox,
  FilterButton,
  Dropdown,
  DropdownFilter,
  FlashMessage,
  Badge,
  SponsorshipBanner,
  TinySponsorshipBanner,
  Sponsor,
  UserCard,
  VideoLoader,
  ClassicModal,
  NotificationModal,
  Dialog,
  Carousel,
  RewardCard,
  Variant,
  ErrorPage,
  Spinner,
  Tabs,
  Input,
  PhoneInput,
  RadioOption,
  RadioGroup,
  Checkbox,
  Textarea,
  Select,
  MultiselectDropdown,
  ListFilter,
  TextEllipsis,
  Tag,
  ClickOutside,
  Jewel,
  RoundButton,
  SimpleSelect,
  SimpleOption,
  Tooltip,
}
