export function reducer(state, action) {
  switch (action.type) {
    case 'register': {
      const linkedItem = state.registered.find(linkeditem => linkeditem.identifier === action.item.identifier)
      if (linkedItem !== undefined) {
        return state
      }

      const newLinkedItem = {
        ...action.item,
        next: undefined,
        previous: undefined,
      }

      const nextState = {
        ...state,
        registered: [...state.registered, newLinkedItem],
      }
      if (nextState.head === undefined) {
        nextState.head = newLinkedItem
      }

      if (nextState.tail === undefined) {
        nextState.tail = newLinkedItem
      } else {
        nextState.tail.next = newLinkedItem
        newLinkedItem.previous = nextState.tail
        nextState.tail = newLinkedItem
      }

      return nextState
    }
    case 'deregister': {
      const linkedItemIndex = state.registered.findIndex(linkedItem => linkedItem.identifier === action.identifier)
      if (linkedItemIndex === -1) {
        return state
      }

      const linkedItem = state.registered[linkedItemIndex]
      let head = state.head
      let tail = state.tail
      if (linkedItem.previous) {
        linkedItem.previous.next = linkedItem.next
      }
      if (linkedItem.next) {
        linkedItem.next.previous = linkedItem.previous
      }
      if (head && linkedItem.identifier === head.identifier) {
        head = linkedItem.next
      }
      if (tail && linkedItem.identifier === tail.identifier) {
        tail = linkedItem.previous
      }

      return {
        ...state,
        head,
        tail,
        registered: [...state.registered.slice(0, linkedItemIndex), ...state.registered.slice(linkedItemIndex + 1)],
      }
    }
    case 'disable': {
      const linkedItemIndex = state.registered.findIndex(linkedItem => linkedItem.identifier === action.identifier)
      const linkedItem = state.registered[linkedItemIndex]

      if (linkedItem === undefined || linkedItem.disabled === action.disabled) {
        return state
      }

      return {
        ...state,
        registered: [
          ...state.registered.slice(0, linkedItemIndex),
          { ...linkedItem, disabled: action.disabled },
          ...state.registered.slice(linkedItemIndex + 1),
        ],
      }
    }
    case 'type-ahead': {
      const now = Date.now()
      let query = state.typeAhead.query
      if (now - state.typeAhead.timestamp < 500) {
        query += action.key
      } else {
        query = action.key
      }
      return {
        ...state,
        typeAhead: { timestamp: now, query },
      }
    }
    default: {
      return state
    }
  }
}
