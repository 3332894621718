import styled from 'styled-components'
import * as colors from '@colors'

export const Status = styled.span`
  margin-left: auto;
  color: ${colors.GREY_SHADE_3};
  font-size: 10px;
  line-height: 16px;
  text-transform: uppercase;
  text-align: right;
  flex: 0 0 auto;
`
export const StatusBaseline = styled(Status)`
  align-self: baseline;
`
