import * as React from 'react'
import * as PropTypes from 'prop-types'

import * as S from './styles'

import Avatar from '@legacy/Avatar'

const Sponsor = ({ name, avatarUrl, link, mainColor }) => {
  const content = (
    <React.Fragment>
      <Avatar src={avatarUrl} alt={name} size={38} border />
      {name}
    </React.Fragment>
  )

  if (link) {
    return (
      <S.SponsorLink as="a" mainColor={mainColor} href={link} target="_blank" rel="noopener">
        {content}
      </S.SponsorLink>
    )
  }

  return <S.Sponsor mainColor={mainColor}>{content}</S.Sponsor>
}

Sponsor.propTypes = {
  avatarUrl: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  /** url will open in new tab */
  link: PropTypes.string,
  /** color of component */
  mainColor: PropTypes.string,
}

export default Sponsor
Sponsor.displayName = 'legacy.Sponsor'
