import * as React from 'react'
import styled from 'styled-components'
import * as PropTypes from 'prop-types'

import * as colors from '@colors'

export const StyledAvatar = styled.img.attrs(props => ({
  size: props.size ? `${props.size}px` : '1em',
}))`
  object-fit: cover;
  border-radius: 50%;
  background-image: ${props => (props.src ? `url(${props.src})` : undefined)};
  background-position: center;
  background-size: cover;
  width: ${props => props.size};
  height: ${props => props.size};
  border: ${props => (props.border ? `2px solid ${colors.PRIMARY_WHITE}` : undefined)};
  box-shadow: ${props => props.shadow && `#0006 0 2px 4px`};
`

/** Beautiful picture chosen by user */
const Avatar = props => <StyledAvatar {...props} />

Avatar.propTypes = {
  /** if not defined, size will be equal to 1em */
  size: PropTypes.number,
  /** if true, add a border */
  border: PropTypes.bool,
  /** if true, add a shadow */
  shadow: PropTypes.bool,
  /** url of the avatar */
  src: PropTypes.string,
  as: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
}
Avatar.displayName = 'legacy.Avatar'

export default Avatar
