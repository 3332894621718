import styled, { keyframes, css } from 'styled-components'

import * as colors from '@colors'

const loading = keyframes`
  from {
    opacity: 0.2;
    transform: scale(0.8);
  }

  50% {
    opacity: 1;
    transform: scale(1);
  }

  to {
    opacity: 0.2;
    transform: scale(0.8);
  }
`

export const Dot = styled.span<{ small?: boolean; delay?: number }>`
  animation: ${loading} 1.2s infinite ease-in-out both;
  animation-delay: ${({ delay = 0 }) => delay}ms;
  background-color: ${props => colors.primary(props)};
  border-radius: 50%;
  display: inline-block;
  overflow: hidden;
  margin: 0;
  padding: 0;
  text-indent: -9999px;
  ${({ small = false }) => {
    if (small) {
      return css`
        width: 6px;
        height: 6px;
        margin: 0 3px;
      `
    } else {
      return css`
        width: 10px;
        height: 10px;
        margin: 0 4px;
      `
    }
  }}
`

export const Spinner = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`
