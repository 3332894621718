import * as React from 'react'
import styled from 'styled-components'

import { Action, AddIcon } from './Action'
import * as s from './state'
import * as S from './styles'

import * as breakpoints from '@bp'
import * as colors from '@colors'
import { hexToRgb } from '../../utils/colors'

const SmallVariantListWrapper = styled.ul`
  list-style: none;
  margin: 25px 0 0 0;
  padding: 0;

  @media screen and not ${breakpoints.MOBILE_L} {
    margin: 25px -21px 0 -21px;
  }
`

const Description = styled.div`
  margin-left: ${({ disabled }) => (disabled ? -4 : 0)}%;
  padding-left: ${({ disabled }) => (disabled ? 25 : 0)}px;
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
`

const FlexAction = styled(Action)`
  flex: 0 0 25px;
  margin-left: -4%;
`

const VariantWrapper = styled.li`
  border: 1px solid;
  border-color: ${({ state }) => (s.isSelected(state) ? colors.PRIMARY_BLUE : colors.GREY_SHADE_5)};
  z-index: ${({ state }) => (s.isSelected(state) ? 2 : 1)};
  position: relative;
  padding: 4%;
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  max-width: ${({ state }) => (s.isLarge(state) ? '320px' : '100%')};
  margin: ${({ state }) => (s.isLarge(state) ? '0 auto' : 'auto')};
  box-sizing: border-box;

  @media screen and ${breakpoints.MOBILE_L} {
    max-width: 100%;
    margin: auto;
  }

  /* stylelint-disable-next-line */
  cursor: ${({ state }) => (!s.isDisabled(state) ? 'pointer' : 'default')};

  &:not(:first-child) {
    margin-top: -1px;
  }

  &:not(:last-child) {
    margin-bottom: -1px;
  }

  &:hover {
    z-index: 2;
    /* stylelint-disable-next-line */
    border-color: ${({ state }) => (s.isDisabled(state) ? colors.GREY_SHADE_5 : hexToRgb(colors.PRIMARY_BLUE, 0.5))};

    ${FlexAction} {
      ${AddIcon} {
        color: ${colors.PRIMARY_BLUE};
      }
    }
  }
`

const HtmlDescription = styled.div`
  font-size: 13px;
  line-height: 18px;
  margin: 0 10px 0 0;

  /* HACK: ugly hack to keep legacy consistency on project page */
  /* stylelint-disable-next-line */
  color: ${({ state }) =>
    s.isDisabled(state) ? colors.GREY_SHADE_3 : s.isPreview(state) ? colors.PRIMARY_BLACK : '#525252'};
  font-weight: 300;

  & p {
    margin: 0;
  }

  & strong {
    font-weight: 500;
  }
`

const SimpleDescription = styled.h2`
  flex: 1 1 auto;
  font-weight: 500;
  font-size: 13px;
  line-height: 18px;
  margin: 0 10px 0 0;

  /* HACK: ugly hack to keep legacy consistency on project page */
  /* stylelint-disable-next-line */
  color: ${({ state }) =>
    s.isDisabled(state) ? colors.GREY_SHADE_3 : s.isPreview(state) ? colors.PRIMARY_BLACK : '#525252'};
`

const VariantGroup = styled.div`
  width: 100%;
  display: inline-flex;
  align-items: center;
`

const SoonSoldOut = styled.span`
  text-transform: uppercase;
  color: ${colors.PRIMARY_BLUE};
  font-size: 10px;
`

const SoldOut = styled.span`
  text-transform: uppercase;
  color: ${colors.GREY_SHADE_3};
  font-size: 10px;
`

export const VariantList = ({ children }) => {
  const items = React.Children.map(
    children,
    ({ props: { variant, translations, handlers, isSelected, mode, isProjectFinished, isLarge } }) => {
      const state = s.variantState(variant, mode, isSelected, isProjectFinished, isLarge)

      let status = ''
      if (variant && variant.stock && variant.stock.total && variant.stock.total !== undefined) {
        status = `${variant.stock.taken} / ${variant.stock.total}`
      } else if (variant && variant.stock && variant.stock.taken) {
        status = translations.supporters
      }

      const onClick = () => {
        if (state.isDisabled || s.isPreview(state)) {
          return
        }
        if (s.isProjectFinished(state) || s.isProject(state)) {
          handlers.onClick()
        } else {
          if (!s.isSelected(state)) {
            handlers.onAdd()
          } else {
            handlers.onRemove()
          }
        }
      }

      return (
        <VariantWrapper onClick={onClick} state={state}>
          <VariantGroup>
            <FlexAction withLabel={false} state={state} translations={translations} handlers={handlers} />
            <Description disabled={s.isDisabled(state)}>
              {!s.isProjectFinished(state) && s.isSoonSoldOut(state) && (
                <SoonSoldOut>{translations.soonSoldOut}</SoonSoldOut>
              )}
              {!s.isProjectFinished(state) && s.isDisabled(state) && <SoldOut>{translations.soldOut}</SoldOut>}
              {variant.description.__html !== undefined ? (
                <HtmlDescription state={state} dangerouslySetInnerHTML={variant.description} />
              ) : (
                <SimpleDescription state={state}>{variant.description}</SimpleDescription>
              )}
            </Description>
            <S.StatusBaseline>{status}</S.StatusBaseline>
          </VariantGroup>
        </VariantWrapper>
      )
    },
  )

  return <SmallVariantListWrapper>{items}</SmallVariantListWrapper>
}

VariantList.propTypes = {
  children: (props, propName, componentName) => {
    const prop = props[propName]
    if (prop.type && prop.type !== 'Variant') {
      return new Error(
        `Invalid prop '${propName}' supplied to '${componentName}'. Only <Variant> component can be chlidren of <Reward>.`,
      )
    }
  },
}
