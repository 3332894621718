import * as React from 'react'
import * as PropTypes from 'prop-types'
import styled from 'styled-components'

import * as fonts from '@fonts'
import * as colors from '@colors'

import TextEllipsis from '../core/TextEllipsis'

const PartnerCardPicture = styled.div`
  background-color: ${colors.GREY_SHADE_4};
  background-image: ${props => (props.url ? `url(${props.url})` : undefined)};
  background-size: cover;
  background-position: center;
  height: 122px;
  width: 100%;
`

const PartnerCardCategory = styled.span`
  background-color: ${props => colors.primary(props)};
  border-radius: 2px;
  color: ${colors.PRIMARY_WHITE};
  font-size: 13px;
  font-style: ${fonts.PRIMARY_FONT};
  font-weight: 500;
  left: -5px;
  line-height: 18px;
  padding: 3px 15px;
  position: relative;
  top: 10px;
`

const PartnerCardBrand = styled.h3`
  color: ${colors.GREY_SHADE_2};
  font-size: 10px;
  font-weight: 700;
  line-height: 14px;
  margin: 0 0 6px 0;
  overflow: hidden;
  text-overflow: ellipsis;
  text-transform: uppercase;
  white-space: nowrap;
`

const PartnerCardDescription = styled.p`
  color: ${colors.PRIMARY_BLACK};
  font-family: ${fonts.PRIMARY_FONT};
  font-size: 13px;
  font-weight: 400;
  line-height: 20px;
  margin: 0;
  height: calc(3 * 22px); /* 3 lines max */
`

const PartnerCardBody = styled.div`
  padding: 12px;
  display: block;

  &:hover {
    opacity: 0.9;
  }
`

const PartnerCardContainer = styled.a`
  cursor: pointer;
  text-decoration: none;
`

const PartnerCardWrapper = styled.div`
  background-color: ${colors.PRIMARY_WHITE};
  border: 1px solid ${colors.GREY_SHADE_5};
  width: 250px;
  flex: 1 1 auto;
  box-shadow: none;
  transition: all 150ms ease-out;

  &:hover {
    transition: all 200ms ease-in-out;
    box-shadow: 2px 2px 14px ${colors.GREY_SHADE_5};
  }
`

/** PartnerCard component should be use mainly to show a summary of a partner action. */
const PartnerCard = ({ picture, category, brand, description, absoluteUrl, ...extra }) => (
  <PartnerCardWrapper>
    <PartnerCardContainer href={absoluteUrl} {...extra}>
      <PartnerCardPicture url={picture}>
        {category && <PartnerCardCategory>{category}</PartnerCardCategory>}
      </PartnerCardPicture>
      <PartnerCardBody>
        <PartnerCardBrand>{brand}</PartnerCardBrand>
        <PartnerCardDescription>
          <TextEllipsis lines={3} tag="span" ellipsisChars="..." useJsOnly>
            {description}
          </TextEllipsis>
        </PartnerCardDescription>
      </PartnerCardBody>
    </PartnerCardContainer>
  </PartnerCardWrapper>
)

PartnerCard.propTypes = {
  /** url of the partner */
  picture: PropTypes.string,
  /** category of the partner */
  category: PropTypes.string,
  /** brand of the partner */
  brand: PropTypes.string,
  /** short description of the partner action */
  description: PropTypes.string,
  /** url of the brand on ulule */
  absoluteUrl: PropTypes.string,
}

export default PartnerCard
PartnerCard.displayName = 'legacy.PartnerCard'
