import styled, { css } from 'styled-components'
import * as colors from '@colors'
import * as input from './input'

export const Wrapper = styled(input.ComposedWrapper)`
  position: relative;

  &::after {
    content: '';
    display: block;
    flex: 0 0 1px;
    order: 1;
    background-color: ${colors.GREY_SHADE_5};
  }

  &:focus-within::after {
    background-color: ${colors.PRIMARY_BLUE};
  }

  &&::after {
    ${({ disabled }) => {
      if (disabled) {
        return css`
          background-color: ${colors.GREY_SHADE_5};
        `
      }
    }}
  }
`

export const Input = styled(input.Input)`
  width: 100%;
  order: 2;
`

export const CountryPrefix = styled.span`
  color: ${colors.GREY_SHADE_3};
`

export const CountryName = styled.span``
