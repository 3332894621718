import * as React from 'react'
import * as PropTypes from 'prop-types'
import styled from 'styled-components'

import * as bp from '@bp'
import * as colors from '@colors'
import * as fonts from '@fonts'

const BasicCTA = styled.span`
  align-items: center;
  border-radius: 2px;
  box-sizing: border-box;
  cursor: pointer;
  display: ${props => (props.fixed ? 'inline-flex' : 'flex')};
  flex: ${props => (props.fixed ? 0 : 1)} 0 auto;
  font-family: ${fonts.PRIMARY_FONT};
  font-size: 15px;
  font-weight: 700;
  height: 50px;
  justify-content: center;
  min-width: 240px;
  padding: 0 16px;
  text-align: center;
  text-transform: uppercase;
  transition: background-color 150ms ease-in-out, color 100ms ease-in;
  width: 100%;

  @media screen and ${bp.TABLET} {
    width: auto;
  }
`

/**
 * Primary CallToAction
 */

const PrimaryCTA = styled(BasicCTA)`
  background-color: ${props => colors.primary(props)};
  color: ${colors.PRIMARY_WHITE};

  &:hover {
    background-color: ${props => colors.primaryHover(props)};
  }
`

export const CallToAction = props => <PrimaryCTA {...props} />

CallToAction.defaultProps = {
  fixed: false,
}

CallToAction.propTypes = {
  as: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  /** describes whether or not the button should take all available horizontal space */
  fixed: PropTypes.bool,
  children: PropTypes.node.isRequired,
  /** action to perform on click event */
  onClick: PropTypes.func,
}

/**
 * Secondary CallToAction
 */

const SecondaryCTA = styled(BasicCTA)`
  color: ${props => colors.primary(props)};
  border: 2px solid;

  &:hover {
    color: ${props => colors.primaryHover(props)};
  }
`

export const SecondaryCallToAction = props => <SecondaryCTA {...props} />

SecondaryCallToAction.defaultProps = {
  fixed: false,
}

SecondaryCallToAction.propTypes = {
  as: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  /** describes whether or not the button should take all available horizontal space */
  fixed: PropTypes.bool,
  children: PropTypes.node.isRequired,
  /** action to perform on click event */
  onClick: PropTypes.func,
}
