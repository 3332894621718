import * as React from 'react'
import * as PropTypes from 'prop-types'

import * as S from './styles'

import Avatar from '@legacy/Avatar'

const TinySponsorshipBanner = ({ sponsor, link, translations, mainColor }) => {
  const content = (
    <React.Fragment>
      <S.Sponsor mainColor={mainColor}>
        <Avatar src={sponsor.avatarUrl} alt={sponsor.name} size={38} />
        {translations['support']}
      </S.Sponsor>
    </React.Fragment>
  )

  if (link) {
    return (
      <S.TinyContainer href={link} target="_blank" rel="noopener" mainColor={mainColor}>
        {content}
      </S.TinyContainer>
    )
  }

  return <S.TinyContainer mainColor={mainColor}>{content}</S.TinyContainer>
}

TinySponsorshipBanner.propTypes = {
  sponsor: PropTypes.shape({
    avatarUrl: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  }).isRequired,
  /** url will open in new tab */
  link: PropTypes.string,
  translations: PropTypes.shape({
    support: PropTypes.string.isRequired,
  }).isRequired,
  /** color of component */
  mainColor: PropTypes.string,
}

export default TinySponsorshipBanner
TinySponsorshipBanner.displayName = 'legacy.TinySponsorshipBanner'
