import * as React from 'react'
import * as PropTypes from 'prop-types'
import styled from 'styled-components'

import * as fonts from '@fonts'
import * as colors from '@colors'
import { hexToRgb } from '../utils/colors'

import Icon from './Icon'
import LabelWithIcon from './LabelWithIcon'

const LightIcon = styled(Icon)`
  color: ${colors.PRIMARY_WHITE};
`

const SocialButtonWrapper = styled.span`
  box-sizing: border-box;
  min-width: 50px;
  height: 50px;
  flex: 1 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: ${fonts.PRIMARY_FONT};
  font-size: 17px;
  font-weight: 500;
  cursor: pointer;
  color: ${colors.PRIMARY_WHITE};
  background-color: ${props => props.bgColor};
  border-radius: 2px;
  padding: 0 16px;
  transition: background-color 150ms ease-in-out;

  &:hover {
    background-color: ${props => hexToRgb(props.bgColor, 0.85)};
  }
`

const getProps = platform => {
  switch (platform) {
    case 'facebook':
      return {
        color: colors.FACEBOOK,
        icon: <LightIcon glyph="social-facebook" size={24} />,
      }
    case 'twitter':
      return {
        color: colors.TWITTER,
        icon: <LightIcon glyph="social-twitter" size={24} />,
      }
    case 'instagram':
      return {
        color: colors.INSTAGRAM,
        icon: <LightIcon glyph="social-instagram" size={24} />,
      }
    case 'messenger':
      return {
        color: colors.MESSENGER,
        icon: <LightIcon glyph="social-messenger" size={24} />,
      }
    case 'linkedin':
      return {
        color: colors.LINKEDIN,
        icon: <LightIcon glyph="social-linkedin" size={24} />,
      }
    default:
      throw new Error('You have to use a supported platform for SocialButton')
  }
}

const SocialButton = ({ platform, onClick, label, ...props }) => {
  const { color, icon, label: defaultLabel } = getProps(platform)

  return (
    <SocialButtonWrapper bgColor={color} onClick={onClick} {...props}>
      {label ? <LabelWithIcon icon={icon} label={label || defaultLabel} /> : icon}
    </SocialButtonWrapper>
  )
}

SocialButton.propTypes = {
  platform: PropTypes.oneOf(['facebook', 'twitter', 'messenger', 'instagram', 'linkedin']).isRequired,
  /**	if props is passed, it replace default platform label */
  label: PropTypes.string,
  onClick: PropTypes.func,
}

export default SocialButton
