import * as React from 'react'
import * as PropTypes from 'prop-types'
import styled from 'styled-components'

import * as fonts from '@fonts'
import * as colors from '@colors'

import Icon from './Icon'
import LabelWithIcon from './LabelWithIcon'

const BasicButton = styled.div`
  box-sizing: border-box;
  width: ${props => (props.fullWidth ? '100%' : 'auto')};
  height: 50px;
  display: inline-flex;
  flex: 0 0 auto;
  align-items: center;
  justify-content: center;
  font-family: ${fonts.PRIMARY_FONT};
  font-size: 13px;
  font-weight: 500;
  padding: 0 16px;
  border-radius: 2px;
  cursor: pointer;
  transition: all 200ms ease-in-out;
`

export const StyledPrimaryButton = styled(BasicButton)`
  color: ${colors.PRIMARY_WHITE};
  background-color: ${props => colors.primary(props)};

  &:hover {
    background-color: ${props => colors.primaryHover(props)};
  }
`

/** This component should be use for calling to an important action into the section. */
export const PrimaryButton = props => <StyledPrimaryButton {...props} />

PrimaryButton.propTypes = {
  /** action to perform on click event */
  onClick: PropTypes.func,
  children: PropTypes.node,
  as: PropTypes.oneOfType([PropTypes.string, PropTypes.elementType]),
}

export const StyledSecondaryButton = styled(BasicButton)`
  color: ${props => colors.primary(props)};
  background-color: ${colors.PRIMARY_WHITE};
  border: 2px solid;
  border-color: ${props => colors.primary(props)};

  &:hover {
    color: ${props => colors.primaryHover(props)};
    border-color: ${props => colors.primaryHover(props)};
  }
`

/** This component should be used for calling to a less important action into the section in side of a PrimaryButton for example. */
export const SecondaryButton = props => <StyledSecondaryButton {...props} />

SecondaryButton.propTypes = {
  /** action to perform on click event */
  onClick: PropTypes.func,
  children: PropTypes.node.isRequired,
  as: PropTypes.oneOfType([PropTypes.string, PropTypes.elementType]),
}

export const StyledTertiaryButton = styled(BasicButton)`
  color: ${colors.PRIMARY_WHITE};
  background-color: ${colors.PRIMARY_BLACK};

  &:hover {
    background-color: ${colors.PRIMARY_BLACK_HOVER};
  }
`

/** This component should be used for specific template of page. */
export const TertiaryButton = props => <StyledTertiaryButton {...props} />

TertiaryButton.propTypes = {
  /** action to perform on click event */
  onClick: PropTypes.func,
  children: PropTypes.node.isRequired,
  as: PropTypes.oneOfType([PropTypes.string, PropTypes.elementType]),
}

const BodyButton = styled.div`
  display: inline-block;
  font-family: ${fonts.PRIMARY_FONT};
  font-size: 15px;
  font-weight: 300;
  line-height: 1.7;
  color: ${colors.PRIMARY_WHITE};
  padding: 14px 120px;
  border-radius: 2px;
  cursor: pointer;
  text-align: center;
`

export const BodyButtonGreen = styled(BodyButton)`
  color: ${colors.PRIMARY_WHITE};
  background-color: ${colors.PRIMARY_GREEN};

  &:hover {
    background-color: ${colors.PRIMARY_GREEN_HOVER};
  }
`

export const BodyButtonBlue = styled(BodyButton)`
  color: ${colors.PRIMARY_WHITE};
  background-color: ${props => colors.primary(props)};

  &:hover {
    background-color: ${props => colors.primaryHover(props)};
  }
`

const BigButtonWrapper = styled.div`
  font-family: ${fonts.PRIMARY_FONT};
  font-weight: 300;
  line-height: 1.4;
  color: ${colors.PRIMARY_WHITE};
  background-color: ${props => colors.primary(props)};
  border-radius: 2px;
  width: 260px;
  height: 90px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  &:hover {
    background-color: ${props => colors.primaryHover(props)};
  }

  & span:first-child {
    font-size: 22px;
    font-weight: 500;
  }

  & span:last-child {
    font-size: 13px;
    font-weight: 400;
  }
`

export const BigButton = ({ more, children }) => (
  <BigButtonWrapper>
    <span>{children}</span>
    {more && <span>{more}</span>}
  </BigButtonWrapper>
)

BigButton.propTypes = {
  more: PropTypes.any,
  children: PropTypes.any.isRequired,
}

const LightIcon = styled(Icon)`
  color: ${colors.GREY_SHADE_4};
  transition: color 100ms ease-in;
`

const ButtonWithIconWrapper = styled.span`
  box-sizing: border-box;
  min-width: 160px;
  height: 50px;
  flex: 1 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: ${fonts.PRIMARY_FONT};
  font-size: 17px;
  font-weight: 500;
  cursor: pointer;
  color: ${props => colors.primary(props)};
  background-color: ${colors.PRIMARY_WHITE};
  border-radius: 2px;
  border: 2px solid;
  border-color: ${props => colors.primary(props)};
  padding: 0 16px;
  transition: all 150ms ease-in-out;

  &:hover {
    color: ${colors.PRIMARY_WHITE};
    background-color: ${props => colors.primaryHover(props)};
    border-color: transparent;

    ${LightIcon /* sc-sel */} {
      color: ${colors.PRIMARY_WHITE};
    }
  }
`

export const ButtonWithIcon = ({ glyph, label, onClick, ...props }) => (
  <ButtonWithIconWrapper onClick={onClick} {...props}>
    <LabelWithIcon icon={<LightIcon glyph={glyph} size={18} />} label={label} />
  </ButtonWithIconWrapper>
)

ButtonWithIcon.propTypes = {
  /** name of the glyph (related to Icon component) */
  glyph: PropTypes.node.isRequired,
  /** text added on side of icon */
  label: PropTypes.node.isRequired,
  /** action to perform on click event */
  onClick: PropTypes.func,
  as: PropTypes.oneOfType([PropTypes.string, PropTypes.elementType]),
}
