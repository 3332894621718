import styled, { css } from 'styled-components'

import * as bp from '@bp'
import * as colors from '@colors'
import * as fonts from '@fonts'
import * as glyphs from '@glyphs/index'
import * as zindex from '@zindex'
import { hexToRgb } from '../utils/colors'

export const BaseNotification = styled.div`
  font-family: ${fonts.PRIMARY_FONT};
  font-weight: 300;
  font-size: 16px;
  line-height: 24px;
`

const fixedNotificationColor = {
  alert: colors.SECONDARY_YELLOW,
  error: colors.PRIMARY_RED,
  info: colors.PRIMARY_BLUE,
  success: colors.PRIMARY_GREEN,
}

export type CalloutType = keyof typeof fixedNotificationColor

export const Callout = styled(BaseNotification)<{ type: CalloutType; size: string }>`
  color: ${colors.PRIMARY_BLACK};
  ${({ type }) => {
    return css`
      background-color: ${hexToRgb(fixedNotificationColor[type], type == 'info' ? 0.1 : 0.15)};
      ${glyphs.Icon} {
        color: ${fixedNotificationColor[type]};
      }
    `
  }}

  ${props => {
    if (props.size === 'small') {
      return css`
        padding: 8px;
        font-size: 13px;
        font-weight: 600;
        line-height: 18px;
      `
    }
    if (props.size === 'medium') {
      return css`
        padding: 18px 15px;
      `
    }
  }}
`

export const CalloutBackground = styled.div`
  background-color: white;
`

export const CalloutWrapper = styled.div<{ size: string }>`
  display: flex;
  flex-direction: row;
  align-items: flex-start;

  ${glyphs.Icon} {
    flex: 0 0 18px;
    position: relative;
    ${props => {
      if (props.size === 'small') {
        return css`
          margin-right: 6px;
          top: 0;
        `
      } else if (props.size === 'medium') {
        return css`
          margin-right: 15px;
          top: 4px;
        `
      }
    }};
  }
`

const flashNotificationColor = {
  success: colors.PRIMARY_GREEN,
  info: colors.PRIMARY_BLACK,
  alert: colors.SECONDARY_RED,
  notification: colors.PRIMARY_BLUE,
}

export type FlashNotificationType = keyof typeof flashNotificationColor

export const FlashNotification = styled(BaseNotification)<{ type: FlashNotificationType }>`
  z-index: ${zindex.TOAST};
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  padding: 18px 36px;
  color: ${colors.PRIMARY_WHITE};
  ${({ type }) => {
    return css`
      background-color: ${flashNotificationColor[type]};
    `
  }}
`

export const FlashNotificationWrapper = styled.div`
  text-align: center;

  ${glyphs.stroke.Close} {
    color: ${colors.PRIMARY_WHITE};
    position: absolute;
    right: 18px;
    top: 8px;
  }

  @media screen and ${bp.LAPTOP} {
    ${glyphs.stroke.Close} {
      position: absolute;
      right: 18px;
      color: ${colors.PRIMARY_WHITE};
      top: 50%;
      transform: translateY(-50%);
    }
  }
`

// TODO : Maybe we need to export this in other file
export const CenterSlot = styled.div``
export const LeftSlot = styled.div``
export const RightSlot = styled.div``

export const FlashNotificationChildren = styled.span<{ isMultipleContent: boolean }>`
  display: flex;
  justify-content: ${({ isMultipleContent }) => (isMultipleContent ? 'space-between' : 'center')};
  width: 100%;
  flex-wrap: wrap;
  align-items: center;
  flex-direction: column;

  @media screen and ${bp.LAPTOP} {
    flex-wrap: nowrap;
    display: flex;
    flex-direction: row;
  }

  ${CenterSlot} {
    flex: 1;
    flex-direction: row;

    @media screen and ${bp.LAPTOP_L} {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
    }
  }
`
