export function scrollToVisibility(container, target) {
  if (!container || !target) return

  const targetPos = target.offsetTop
  const targetHeight = target.clientHeight
  const containerHeight = container.clientHeight
  const containerScrolled = container.scrollTop

  if (targetPos < containerScrolled) {
    // target over scroll window, must scroll up to see it
    const y = targetPos
    container.scroll(0, y)
  } else if (targetPos + targetHeight > containerScrolled + containerHeight) {
    // target under scroll window, must scroll down to see it
    const y = targetPos + targetHeight - containerHeight
    container.scroll(0, y)
  }
}
