import * as React from 'react'
import * as PropTypes from 'prop-types'
import styled from 'styled-components'

import { Title2 } from '@legacy/Titles'
import TextEllipsis from '../core/TextEllipsis'

import * as colors from '@colors'
import * as fonts from '@fonts'

const BadgeWrapper = styled.a`
  max-width: 300px;
  min-width: 230px;
  flex: 1 1 auto;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  text-decoration: none;
  color: black;
  border: 1px solid ${colors.GREY_SHADE_5};
  min-height: 308px;
  position: relative;

  &:hover {
    transition: all 200ms ease-in-out;
    box-shadow: 2px 2px 14px ${colors.GREY_SHADE_5};
  }
`

const BadgeContent = styled.div`
  border-top: none;
  padding: 15px;
  position: relative;
  padding-bottom: 35px;
`

const BadgeTitle = styled(Title2)`
  color: ${props => props.color};
  font-size: 18px;
  font-weight: 500;
  line-height: 21px;
  text-align: center;
  margin: 0;
`

const BadgeInfos = styled.div`
  position: absolute;
  background-color: ${props => props.color};
  color: ${colors.PRIMARY_WHITE};
  padding: 4px 14px;
  border-radius: 1px;
  left: 50%;
  bottom: -5px;
  font-family: ${fonts.PRIMARY_FONT};
  transform: translateX(-50%);

  span {
    font-size: 12px;
    font-weight: bold;
    line-height: 18px;
  }

  svg {
    height: 12px;
    margin-top: -1px;
    margin-right: 5px;
  }
`

const BadgeDescription = styled.p`
  font-size: 15px;
  font-weight: 300;
  line-height: 20px;
  text-align: center;
  width: 100%;
  height: 100%;
  overflow: hidden;
  font-family: ${fonts.PRIMARY_FONT};
`

const BadgeIcon = styled.div`
  background-color: ${props => props.color};
  display: flex;
  justify-content: center;
  align-items: center;
  height: 165px;

  img {
    width: 130px;
    height: 130px;
  }
`

/** Badge component is used for badges on user page */
const Badge = ({ name, icon, description, color, total, url, className }) => (
  <BadgeWrapper href={url} className={className}>
    <BadgeIcon color={color}>
      <img src={icon} alt={name} />
    </BadgeIcon>
    <BadgeContent>
      <BadgeTitle color={color}>{name}</BadgeTitle>
      <TextEllipsis lines={3} tag={'div'} ellipsisChars={'...'} tagClass={'className'} useJsOnly={true}>
        <BadgeDescription>{description}</BadgeDescription>
      </TextEllipsis>
    </BadgeContent>
    {total !== null && (
      <BadgeInfos color={color}>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
          <path
            fill="#fff"
            d="M21 18.28H10.56A8.35 8.35 0 0 0 2 26.42v3.32A2.16 2.16 0 0 0 4 32h.29a2.17 2.17 0 0 0 2.31-2 2.12 2.12 0 0 0 0-.28v-3a3.74 3.74 0 0 1 3.6-3.89h11.2a3.89 3.89 0 0 1 3.93 3.84v3a2.16 2.16 0 0 0 2 2.29h.29A2.17 2.17 0 0 0 30 30a2.12 2.12 0 0 0 0-.28v-3a9 9 0 0 0-9-8.44zM15.88 0a8.49 8.49 0 0 0-8.55 8.41 8.3 8.3 0 0 0 8.37 8.23h.19a8.49 8.49 0 0 0 8.56-8.41A8.58 8.58 0 0 0 15.88 0zm0 12.11A3.84 3.84 0 0 1 12 8.46a4 4 0 0 1 3.93-3.89 3.89 3.89 0 0 1 3.93 3.84 3.82 3.82 0 0 1-3.98 3.7z"
          />
        </svg>
        <span>{total}</span>
      </BadgeInfos>
    )}
  </BadgeWrapper>
)

Badge.propTypes = {
  name: PropTypes.string,
  icon: PropTypes.string,
  description: PropTypes.string,
  color: PropTypes.string,
  total: PropTypes.number,
  /** url target on click */
  url: PropTypes.string,
  className: PropTypes.string,
}
Badge.displayName = 'legacy.Badge'

export default Badge
