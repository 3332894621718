import { hexToRgb } from '../utils/colors'

export { hexToRgb }

/**
 * Colors palette
 *
 * Please respect these rules:
 * - all color constants should correctly named (human named)
 * - the named should be capitalized
 * - constant have to be exported
 * - constant must be sorted in a logical category
 */

// Shades of blue
export const BLUE_SHADE_1 = '#D1EDF7'
export const BLUE_SHADE_2 = '#004E69'
export const BLUE_SHADE_3 = '#E7F8FC'

// Shades of red
export const RED_SHADE_1 = '#FDDCE3'
export const RED_SHADE_2 = '#8B0020'

// Primary colors
export const PRIMARY_BLACK = '#232221'
export const PRIMARY_BLACK_HOVER = '#363636' // TODO: Update with new value?
export const PRIMARY_BLUE = '#18A5D6'
export const PRIMARY_BLUE_HOVER = '#007199'
export const PRIMARY_GREEN = '#66DC88' // NOTE: Deprecated
export const PRIMARY_GREEN_HOVER = '#64D088' // NOTE: Deprecated
export const PRIMARY_RED = '#F01446'
export const PRIMARY_RED_HOVER = '#BD0A33'
export const PRIMARY_SAND = '#F6F1EB'
export const PRIMARY_WHITE = '#FFFFFF'

// Secondary colors
export const SECONDARY_YELLOW = '#F7C884' // NOTE: Deprecated
export const SECONDARY_INDIGO = '#3E51B5' // NOTE: Deprecated
export const SECONDARY_PURPLE = '#8B66C5' // NOTE: Deprecated
export const SECONDARY_RED = '#FF4336' // NOTE: Deprecated
export const SECONDARY_PINK = '#FF5858' // NOTE: Deprecated

// Shades of grey
export const GREY_SHADE_1 = '#59544E'
export const GREY_SHADE_2 = '#807A74'
export const GREY_SHADE_3 = '#999999'
export const GREY_SHADE_4 = '#BDBDBD'
export const GREY_SHADE_5 = '#E3DDD3'
export const GREY_SHADE_6 = '#F5F6F9'
export const GREY_SHADE_7 = '#B8AFA4'

// Brand accent colors
export const BRAND_ACCENT_GREEN = '#02715C'
export const BRAND_ACCENT_PURPLE = '#9E3F78'
export const BRAND_ACCENT_YELLOW = '#FDCE03'

// Social colors
export const FACEBOOK = '#3B5998'
export const MESSENGER = '#057FF5'
export const TWITTER = '#55ACEE'
export const INSTAGRAM = '#E1306C'
export const LINKEDIN = '#0077B5'
export const YOUTUBE = '#E52D27'
export const WHATSAPP = '#2DBEA5'
export const BANDCAMP = '#60A8B9'
export const SOUNDCLOUD = '#FF4300'
export const SPOTIFY = '#1ED660'
export const TWITCH = '#6C2498'
export const VIMEO = '#00B9D8'

// Helper for the colors utils
const PRIMARY_COLORS = {
  'primary-black': PRIMARY_BLACK,
  'primary-blue': PRIMARY_BLUE,
  'primary-red': PRIMARY_RED,
  'primary-white': PRIMARY_WHITE,
  // NOTE: Deprecated
  'primary-green': PRIMARY_GREEN,
}

const PRIMARY_HOVER = {
  'primary-black': PRIMARY_BLACK_HOVER,
  'primary-blue': PRIMARY_BLUE_HOVER,
  'primary-red': PRIMARY_RED_HOVER,
  // NOTE: Deprecated
  'primary-green': PRIMARY_GREEN_HOVER,
}

const PRIMARY_HOVER_HEX = {
  [PRIMARY_BLACK.toLowerCase()]: PRIMARY_BLACK_HOVER,
  [PRIMARY_BLUE.toLowerCase()]: PRIMARY_BLUE_HOVER,
  [PRIMARY_RED.toLowerCase()]: PRIMARY_RED_HOVER,
  // NOTE: Deprecated
  [PRIMARY_GREEN.toLowerCase()]: PRIMARY_GREEN_HOVER,
}

// Utils
export const primary = props => {
  const primaryColor = props.theme && props.theme.colors && props.theme.colors.primary
  if (primaryColor && primaryColor.charAt(0) === '#') {
    return primaryColor
  }

  if (PRIMARY_COLORS[primaryColor]) {
    return PRIMARY_COLORS[primaryColor]
  }

  return PRIMARY_BLUE
}

export const primaryHover = props => {
  const color = props.theme && props.theme.colors && props.theme.colors.primary

  if (!color) {
    return PRIMARY_BLUE_HOVER
  }

  if (PRIMARY_HOVER[color]) {
    return PRIMARY_HOVER[color]
  }

  const lowerCaseColor = color.toLowerCase()
  if (lowerCaseColor && lowerCaseColor.charAt(0) === '#') {
    if (PRIMARY_HOVER_HEX[lowerCaseColor]) {
      return PRIMARY_HOVER_HEX[lowerCaseColor]
    }

    return hexToRgb(lowerCaseColor, 0.85)
  }

  return PRIMARY_BLUE_HOVER
}
