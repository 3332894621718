import * as React from 'react'
import * as PropTypes from 'prop-types'
import styled from 'styled-components'

import * as colors from '@colors'
import { hexToRgb } from '../../utils/colors'

// TODO: FIXME: We definitely need better names for these
import * as s from './state'
import * as S from './styles'
import * as styles from '@styles/index'

import { Action, AddIcon } from './Action'
import { Option, OptionWrapper } from '../components/form/select/Option'
import { Select } from '../components/form/select/Select'
import { Variant } from './Variant'

const FlexAction = styled(Action)`
  flex: 0 0 25px;
  margin-left: -4%;
  pointer-events: none;
  background-color: white;
  z-index: 10;
`

const VariantGroup = styled.div`
  width: 100%;
  display: inline-flex;
  align-items: center;
`

const SimpleDescription = styled.h2`
  flex: 1 1 auto;
  font-weight: 500;
  font-size: 13px;
  line-height: 18px;
  margin: 0 10px 0 0;

  /* HACK: ugly hack to keep legacy consistency on project page */
  /* stylelint-disable-next-line */
  color: ${({ state }) =>
    s.isDisabled(state) ? colors.GREY_SHADE_3 : s.isPreview(state) ? colors.PRIMARY_BLACK : '#525252'};
`

const Description = styled.div`
  margin-left: ${({ disabled }) => (disabled ? -4 : 0)}%;
  padding-left: ${({ disabled }) => (disabled ? 25 : 0)}px;
  flex: 1 1 auto;
`

const SoonSoldOut = styled.span`
  text-transform: uppercase;
  color: ${colors.PRIMARY_BLUE};
  font-weight: 300;
  font-size: 10px;
`

const FlexOption = styled(Option)`
  border: 1px solid;
  border-color: ${({ state }) => (s.isSelected(state) ? colors.PRIMARY_BLUE : colors.GREY_SHADE_5)};
  z-index: ${({ state }) => (s.isSelected(state) ? 2 : 1)};
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  /* stylelint-disable-next-line */
  cursor: ${({ state }) => (!s.isDisabled(state) ? 'pointer' : 'default')};
  background-color: ${colors.PRIMARY_WHITE};
  margin: -1px 0;

  &:first-child {
    margin-top: 0;
  }

  &:last-child {
    margin-bottom: 0;
  }

  &:hover {
    z-index: 2;
    /* stylelint-disable-next-line */
    border-color: ${({ state }) => (s.isDisabled(state) ? colors.GREY_SHADE_5 : hexToRgb(colors.PRIMARY_BLUE, 0.5))};

    ${FlexAction} {
      ${AddIcon} {
        color: ${colors.PRIMARY_BLUE};
      }
    }
  }

  ${styles.option.Label} {
    width: 100%;
  }

  ${OptionWrapper} {
    padding: 4%;
  }
`

const StyledSelect = styled(Select)`
  margin: 25px 0 0 0;
  width: 100%;
`

export const VariantSelect = ({ multiple, children, placeholder }) => {
  const options = React.Children.map(
    children,
    ({ props: { variant, translations, mode, isSelected, handlers, isProjectFinished } }) => {
      const state = s.variantState(variant, mode, isSelected, isProjectFinished)

      let status = ''
      if (variant && variant.stock && variant.stock.total && variant.stock.total !== undefined) {
        status = `${variant.stock.taken} / ${variant.stock.total}`
      } else if (variant && variant.stock && variant.stock.taken) {
        status = translations.supporters
      }

      const handleChange = () => {
        if (state.isDisabled || s.isPreview(state)) {
          return
        }
        if (s.isProjectFinished(state) || s.isProject(state)) {
          handlers.onClick()
        } else {
          if (!s.isSelected(state)) {
            handlers.onAdd()
          } else {
            handlers.onRemove()
          }
        }
      }

      const label = typeof variant.description === 'string' ? variant.description : variant.description._html

      return (
        <FlexOption label={label} state={state} value={variant} onToggle={handleChange} disabled={state.isDisabled}>
          <VariantGroup>
            <FlexAction withLabel={false} state={state} translations={translations} handlers={handlers} />
            <Description disabled={s.isDisabled(state)}>
              {!s.isProjectFinished(state) && s.isSoonSoldOut(state) && (
                <SoonSoldOut>{translations.soonSoldOut}</SoonSoldOut>
              )}
              {!s.isProjectFinished(state) && s.isDisabled(state) && <S.Status>{translations.soldOut}</S.Status>}
              <SimpleDescription state={state}>{variant.description}</SimpleDescription>
            </Description>
            {status && <S.StatusBaseline>{status}</S.StatusBaseline>}
          </VariantGroup>
        </FlexOption>
      )
    },
  )

  return (
    <StyledSelect multiple={multiple} placeholder={placeholder} selected={multiple ? [] : undefined}>
      {options}
    </StyledSelect>
  )
}

VariantSelect.propTypes = {
  /** wether we allow multiple selection or not */
  multiple: PropTypes.bool,
  placeholder: PropTypes.string,
  children: (props, propName, componentName) => {
    const isVariant = props[propName].every(prop => prop.type && prop.type === Variant)
    if (!isVariant) {
      return new Error(
        `Invalid prop '${propName}' supplied to '${componentName}'. Only <Variant> component can be children of <Reward>.`,
      )
    }
  },
}
