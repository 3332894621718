import styled from 'styled-components'

import * as colors from '@colors'
import * as fonts from '@fonts'

export const DiscreetText = styled.span<{ light: boolean }>`
  color: ${colors.GREY_SHADE_2};
  font-size: 12px;
  font-weight: ${({ light = false }) => (light ? 300 : 400)};
`

export const ProjectCardTitle = styled.h2<{ theme?: { color: { primary: string } } }>`
  color: ${props => colors.primary(props)};
  font-size: 16px;
  font-weight: 600;
  line-height: 23px;
  margin: 0;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`

export const HorizontalCardWrapper = styled.a`
  color: ${colors.PRIMARY_BLACK};
  cursor: pointer;
  display: flex;
  font-family: ${fonts.PRIMARY_FONT};
  line-height: 1.23;
  min-width: 280px;
  text-decoration: none;
  width: 100%;

  ${ProjectCardTitle} {
    margin-bottom: 5px;
  }

  &:hover ${ProjectCardTitle} {
    text-decoration: underline;
  }
`
