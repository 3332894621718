import * as React from 'react'
import * as PropTypes from 'prop-types'
import styled from 'styled-components'

import * as fonts from '@fonts'
import * as colors from '@colors'

const UserCardWrapper = styled.a`
  border: 1px solid ${colors.GREY_SHADE_5};
  padding: 10px;
  position: relative;
  max-width: 180px;
  color: ${colors.PRIMARY_BLACK};
  text-decoration: none;
  font-family: ${fonts.PRIMARY_FONT};
  box-sizing: border-box;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  overflow: hidden;

  &:hover {
    transition: all 200ms ease-in-out;
    box-shadow: 2px 2px 14px ${colors.GREY_SHADE_5};
  }
`

const UserCardImg = styled.img`
  border-radius: 50%;
  border-style: none;
  display: block;
  margin: 0 auto 10px auto;
  max-width: 160px;
  min-width: 100px;
  width: 160px;
`

const UserCardName = styled.span`
  text-align: center;
  display: block;
  font-weight: 500;
  color: ${colors.PRIMARY_BLACK};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

/** This component aims to serve Ulule business. It shows the support of a user to a project. */
const UserCard = ({ name, url, avatar }) => (
  <UserCardWrapper href={url}>
    <UserCardImg src={avatar} alt="avatar" />
    <UserCardName>{name}</UserCardName>
  </UserCardWrapper>
)

UserCard.propTypes = {
  /** avatar of the contributor */
  avatar: PropTypes.string,
  /** name of the contributor */
  name: PropTypes.string,
  /** absolute url to the user page */
  url: PropTypes.string,
}

export default UserCard
UserCard.displayName = 'legacy.UserCard'
