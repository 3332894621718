import * as React from 'react'
import * as PropTypes from 'prop-types'

import styled from 'styled-components'

import Icon from '../Icon'

import * as colors from '@colors'

export const CarouselButton = styled.div`
  color: ${colors.PRIMARY_WHITE};
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${colors.PRIMARY_BLUE};
  cursor: pointer;
  opacity: ${props => (props.show ? 1 : 0)};
  pointer-events: ${props => (props.show ? 'auto' : 'none')};

  &:focus {
    outline: 0;
    background-color: ${colors.PRIMARY_BLUE_HOVER};
    box-shadow: 2px 2px 14px ${colors.GREY_SHADE_5};
  }

  &:hover {
    background-color: ${colors.PRIMARY_BLUE_HOVER};
  }
`

export const CarouselButtonRight = ({ onClick, show, innerRef }) => (
  <CarouselButton onClick={onClick} show={show} role="button" tabIndex={0} ref={innerRef}>
    <Icon size={13} glyph="caret-right-light" />
  </CarouselButton>
)

CarouselButtonRight.propTypes = {
  innerRef: PropTypes.func,
  show: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
}

export const CarouselButtonLeft = ({ onClick, show, innerRef }) => (
  <CarouselButton onClick={onClick} show={show} role="button" tabIndex={0} ref={innerRef}>
    <Icon size={13} glyph="caret-left-light" />
  </CarouselButton>
)

CarouselButtonLeft.propTypes = {
  innerRef: PropTypes.func,
  show: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
}
