import styled from 'styled-components'

import * as heading from './heading'
import * as copy from './copy'
import * as COLORS from '@colors'
import * as BREAKPOINT from '@bp'
import * as glyphs from '@glyphs/index'

export const Quantity = styled(heading.XXXXXS)`
  color: ${COLORS.GREY_SHADE_3};
  text-transform: uppercase;
  text-align: right;
`

export const SoldOut = styled(heading.XXXXXS)`
  color: ${COLORS.GREY_SHADE_3};
  margin: 4px 0 -4px;
`

export const SoonSoldOut = styled(SoldOut)`
  color: ${COLORS.PRIMARY_BLUE};
`

export const Description = styled(copy.S)`
  color: ${COLORS.PRIMARY_BLACK};
  font-weight: 300;

  ul {
    padding-left: 40px;
  }

  ul,
  ol {
    margin: 1em 0;
    padding-left: 40px;

    li {
      line-height: 1.618;
    }

    ul,
    ol {
      margin: 0;
    }
  }

  strong {
    font-weight: bold;
  }

  & img {
    max-width: 100%;
  }
`

export const Title = styled(heading.XXXS)`
  color: ${COLORS.PRIMARY_BLACK};
`

export const Picture = styled.img<{ format: 'wide' | 'narrow' }>`
  max-width: 100%;

  @media screen and ${BREAKPOINT.TABLET} {
    max-width: ${({ format }) => (format === 'wide' ? '70%' : '100%')};
  }
`

export const Conversion = styled(heading.Card3)`
  color: ${COLORS.GREY_SHADE_2};
  margin-left: 12px;
  font-weight: 500;

  ${glyphs.Currency} {
    margin-right: 4px;
    color: ${COLORS.GREY_SHADE_4};
    vertical-align: bottom;
  }
`

export const OriginalPrice = styled(heading.XS)``

export const Price = styled.div`
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  color: ${COLORS.PRIMARY_BLUE};

  ${Conversion} {
    position: relative;
    top: 1px;
  }

  ${OriginalPrice} {
    color: inherit;
  }
`

export const Action = styled(heading.Card1)`
  ${glyphs.Icon} {
    color: ${COLORS.GREY_SHADE_4};
    position: relative;
    top: -2px;
    margin-right: 6px;
  }
`

export const ActiveLabel = styled.span`
  color: ${COLORS.PRIMARY_BLUE};
`

export const InactiveLabel = styled.span`
  color: ${COLORS.GREY_SHADE_3};
`
