import styled, { css } from 'styled-components'

import * as colors from '@colors'
import * as fonts from '@fonts'
import * as inputStyle from './input'

export type Status = 'error' | 'success' | 'default'

export const TextArea = styled.textarea<{ status?: Status }>`
  border-radius: 2px;
  padding: 15px;
  font-family: ${fonts.PRIMARY_FONT};
  font-size: 15px;
  font-weight: 400;
  outline-color: ${colors.PRIMARY_BLUE};
  background-color: ${colors.PRIMARY_WHITE};
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;

  &:focus {
    ${inputStyle.focusedBorder};
    outline: none;
  }

  &::placeholder {
    font-family: ${fonts.PRIMARY_FONT};
    color: ${colors.GREY_SHADE_3};
    font-size: 15px;
    font-weight: 400;
  }

  ${({ status }) => {
    if (status === 'error') {
      return inputStyle.errorBorder
    }
    if (status === 'success') {
      return inputStyle.successBorder
    }
    return inputStyle.defaultBorder
  }}

  &:disabled {
    ${inputStyle.defaultBorder}
    color: ${colors.GREY_SHADE_3};
    background-color: ${colors.GREY_SHADE_6};
  }
`

export const Count = styled.span<{ isError: boolean }>`
  font-size: 10px;
  font-family: ${fonts.PRIMARY_FONT};
  font-weight: 500;
  ${({ isError }) => {
    if (isError) {
      return css`
        color: ${colors.SECONDARY_RED};
      `
    } else {
      return css`
        color: ${colors.GREY_SHADE_1};
      `
    }
  }}
`
