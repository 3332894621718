import * as React from 'react'
import * as PropTypes from 'prop-types'
import styled from 'styled-components'

import accounting from 'accounting'

const CURRENCY_CONFIG = {
  USD: {
    symbol: '$',
  },
  CAD: {
    symbol: '$CA',
  },
  AUD: {
    symbol: '$AU',
  },
  NZD: {
    symbol: 'NZD',
  },
  GBP: {
    symbol: '£',
  },
  DKK: {
    symbol: 'kr',
  },
  SEK: {
    symbol: 'kr',
  },
  NOK: {
    symbol: 'kr',
  },
  EUR: {
    symbol: '€',
  },
  CHF: {
    symbol: 'CHF',
  },
}

export const NUMBER_FORMAT_CONFIG = {
  en: {
    format: '%s%v',
    thousand: ',',
    decimal: '.',
  },
  fr: {
    format: '%v %s',
    thousand: ' ',
    decimal: ',',
  },
  it: {
    format: '%s%v',
    decimal: ',',
    thousand: '.',
  },
  nl: {
    format: '%s %v',
    decimal: ',',
    thousand: '.',
  },
  de: {
    format: '%v %s',
    decimal: ',',
    thousand: '.',
  },
  es: {
    format: '%v %s',
    decimal: ',',
    thousand: '.',
  },
  ca: {
    format: '%v %s',
    decimal: ',',
    thousand: '.',
  },
  pt: {
    format: '%s%v',
    decimal: ',',
    thousand: '.',
  },
}

export class LocaleMoneyFormatProvider extends React.Component {
  render() {
    const currencyConfig = CURRENCY_CONFIG[this.props.currency] || {}
    const numberFormatConfig = NUMBER_FORMAT_CONFIG[this.props.lang] || {}

    return this.props.children({
      ...currencyConfig,
      ...numberFormatConfig,
    })
  }
}

LocaleMoneyFormatProvider.defaultProps = {
  currency: 'USD',
  lang: 'en',
}

LocaleMoneyFormatProvider.propTypes = {
  currency: PropTypes.string,
  lang: PropTypes.string,
  children: PropTypes.func.isRequired,
}

export const FormatedMoney = ({ amount, symbol, precision, thousand, decimal, format }) => (
  <React.Fragment>{accounting.formatMoney(amount, symbol, precision, thousand, decimal, format)}</React.Fragment>
)

FormatedMoney.defaultProps = {
  amount: 0,
  precision: 0,
}

FormatedMoney.propTypes = {
  amount: PropTypes.number,
  symbol: PropTypes.string.isRequired,
  precision: PropTypes.number,
  thousand: PropTypes.string.isRequired,
  decimal: PropTypes.string.isRequired,
  format: PropTypes.string.isRequired,
}

export class MoneyExchangeProvider extends React.Component {
  render() {
    const { rates, amount, from, to, children } = this.props

    if (from === to) return children(amount)

    const inBaseCurrency = amount / rates[from]
    const convertedAmount = inBaseCurrency * rates[to]

    return children(convertedAmount)
  }
}

MoneyExchangeProvider.defaultProps = {
  amount: 0,
}

MoneyExchangeProvider.propTypes = {
  amount: PropTypes.number,
  from: PropTypes.string.isRequired,
  to: PropTypes.string.isRequired,
  rates: PropTypes.object,
  children: PropTypes.func.isRequired,
}

// NOTE: number must be a non-breaking word
const Wrapper = styled.span`
  display: inline-block;
  text-decoration: inherit;
  color: inherit;
`

/** Money, money ! */
export class LocaleMoneyDisplayer extends React.Component {
  render() {
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    if (targetCurrency && (!rates || rates.length === 0)) {
      throw new Error('If targetCurrency props is passed, you have to pass rates props also.')
    }

    const { rates, amount, currency, targetCurrency, localeFormat, precision } = this.props

    return (
      <MoneyExchangeProvider rates={rates} amount={amount} from={currency} to={targetCurrency || currency}>
        {value => (
          <LocaleMoneyFormatProvider lang={localeFormat} currency={targetCurrency || currency}>
            {options => (
              <Wrapper>
                <FormatedMoney amount={value} precision={precision} {...options} />
              </Wrapper>
            )}
          </LocaleMoneyFormatProvider>
        )}
      </MoneyExchangeProvider>
    )
  }
}

LocaleMoneyDisplayer.defaultProps = {
  amount: 0,
  localeFormat: 'en',
}

LocaleMoneyDisplayer.propTypes = {
  precision: PropTypes.number,
  amount: PropTypes.number,
  currency: PropTypes.string.isRequired,
  targetCurrency: PropTypes.string,
  localeFormat: PropTypes.string.isRequired,
  rates: PropTypes.object,
}
LocaleMoneyDisplayer.displayName = 'legacy.LocaleMoneyDisplayer'
