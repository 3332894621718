import styled, { css } from 'styled-components'

import * as colors from '@colors'
import * as fonts from '@fonts'

export const focusedBorder = css`
  border: 1px solid ${colors.PRIMARY_BLUE};
`

export const errorBorder = css`
  border: 1px dashed ${colors.SECONDARY_RED};
`

export const successBorder = css`
  border: 1px solid ${colors.PRIMARY_GREEN};
`

export const defaultBorder = css`
  border: 1px solid ${colors.GREY_SHADE_5};
`

export type Status = 'error' | 'success' | 'default'

export const Input = styled.input<{ status?: Status }>`
  height: 50px;
  border-radius: 2px;
  padding: 15px;
  font-family: ${fonts.PRIMARY_FONT};
  font-size: 15px;
  font-weight: 400;
  outline-color: ${colors.PRIMARY_BLUE};
  background-color: ${colors.PRIMARY_WHITE};
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;

  &:focus {
    ${focusedBorder};
    outline: none;
  }

  &::placeholder {
    font-family: ${fonts.PRIMARY_FONT};
    color: ${colors.GREY_SHADE_3};
    font-size: 15px;
    font-weight: 400;
  }

  ${({ status }) => {
    if (status === 'error') {
      return errorBorder
    }
    if (status === 'success') {
      return successBorder
    }
    return defaultBorder
  }}

  &:disabled {
    ${defaultBorder}
    color: ${colors.GREY_SHADE_3};
    background-color: ${colors.GREY_SHADE_6};
  }
`

export const HiddenFocusableInput = styled.input`
  position: absolute;
  display: inline-block;
  cursor: pointer;
  opacity: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
`

export const ComposedWrapper = styled.div<{ disabled?: boolean; status?: Status }>`
  display: flex;
  flex-direction: row;
  ${defaultBorder}
  background-color: ${colors.PRIMARY_WHITE};

  ${Input}, ${Input}:focus {
    background-color: transparent;
    border: none;
  }

  &:focus-within {
    ${focusedBorder}
  }

  ${({ status }) => {
    if (status === 'error') {
      return errorBorder
    }
    if (status === 'success') {
      return successBorder
    }
    return defaultBorder
  }}

  &[disabled] {
    ${defaultBorder}
    background-color: ${colors.GREY_SHADE_6};
  }
`

export const Count = styled.span<{ isError: boolean }>`
  font-size: 10px;
  font-family: ${fonts.PRIMARY_FONT};
  font-weight: 500;
  ${({ isError }) => {
    if (isError) {
      return css`
        color: ${colors.SECONDARY_RED};
      `
    } else {
      return css`
        color: ${colors.GREY_SHADE_1};
      `
    }
  }}
`
