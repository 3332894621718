const SELECTED = 'selected'
const UNSELECTED = 'unselected'
const PROJECT = 'project'

export const rewardState = (reward, variants, mode, isSelected, isProjectFinished, isLarge) => {
  const hasVariants = variants.length > 0
  const rewardStockTotal = hasVariants
    ? variants.reduce(
        (stock, variant) => (variant.stock.total === null || stock === null ? null : variant.stock.total + stock),
        0,
      )
    : reward.stock.total
  const rewardStockTaken = hasVariants
    ? variants.reduce(
        (stock, variant) => (variant.stock.total === null || stock === null ? null : variant.stock.taken + stock),
        0,
      )
    : reward.stock.taken
  const rewardStockRemaining = rewardStockTotal - rewardStockTaken

  const allVariantsTaken =
    variants && variants.every(variant => variant.stock && variant.stock.taken >= variant.stock.total)

  return {
    isPreview: mode === 'preview',
    isDisabled:
      isProjectFinished ||
      (rewardStockTotal !== null && (hasVariants ? allVariantsTaken : rewardStockTaken >= rewardStockTotal)),
    isSoonSoldOut:
      rewardStockTotal !== null ? rewardStockRemaining <= 5 && rewardStockTotal !== rewardStockRemaining : false,
    hasVariants,
    isProjectFinished,
    isLarge,
    type: mode === 'project' ? PROJECT : isSelected ? SELECTED : UNSELECTED,
  }
}

export const variantState = (variant, mode, isSelected, isProjectFinished, isLarge) => {
  const rewardStockRemaining = variant.stock.total - variant.stock.taken
  return {
    isPreview: mode === 'preview',
    isDisabled: variant.stock.total !== null ? variant.stock.taken >= variant.stock.total : false,
    isSoonSoldOut:
      variant.stock.total !== null ? rewardStockRemaining <= 5 && variant.stock.total !== rewardStockRemaining : false,
    hasVariants: false,
    isProjectFinished,
    isDropdown: false,
    isLarge,
    type: mode === 'project' ? PROJECT : isSelected ? SELECTED : UNSELECTED,
  }
}

export const isSoonSoldOut = ({ isPreview, isDisabled, isSoonSoldOut }) => {
  return !isPreview && !isDisabled && isSoonSoldOut
}

export const isProject = ({ isPreview, isDisabled, type }) => {
  return !isPreview && !isDisabled && type === PROJECT
}

export const isSelected = ({ isPreview, isDisabled, type }) => {
  return !isPreview && !isDisabled && type === SELECTED
}

export const isUnselected = ({ isPreview, isDisabled, type }) => {
  return !isPreview && !isDisabled && type === UNSELECTED
}

export const isDisabled = ({ isPreview, isDisabled, isProjectFinished }) => {
  return isProjectFinished || (!isPreview && isDisabled)
}

export const isPreview = ({ isPreview }) => isPreview

export const isLarge = ({ isLarge }) => isLarge

export const hasVariants = ({ hasVariants }) => hasVariants

export const isProjectFinished = ({ isProjectFinished }) => isProjectFinished
