import styled from 'styled-components'

import * as fonts from '@fonts'
import * as colors from '@colors'

const ProjectCardTag = styled.span`
  background-color: ${colors.PRIMARY_GREEN};
  border-radius: 2px;
  color: ${colors.PRIMARY_WHITE};
  font-size: 13px;
  font-style: ${fonts.PRIMARY_FONT};
  font-weight: 400;
  padding: 3px 10px;
  position: absolute;
  top: -22px;
  left: 50%;
  transform: translate(-50%);
  display: flex;
  align-items: center;

  svg {
    font-size: 12px;
    margin-right: 5px;
  }
`

export default ProjectCardTag
