import * as React from 'react'
import * as PropTypes from 'prop-types'

import * as S from './styles'
import { Detail } from './Label'

/** Input component for text, number, password, etc... */
class Input extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      value: props.initialValue || '',
      currentCount: props.initialValue ? props.initialValue.length : 0,
    }
  }

  handleChange = event => {
    const { onChange, onChangeEvent } = this.props

    if (onChangeEvent && typeof onChangeEvent === 'function') {
      onChangeEvent(event)
    }

    this.setState(
      {
        value: event.currentTarget.value,
        currentCount: event.currentTarget.value.length,
      },
      () => {
        if (onChange && typeof onChange === 'function') {
          onChange(this.state.value)
        }
      },
    )
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.value !== undefined && this.props.value !== prevState.value) {
      this.setState({ value: this.props.value, currentCount: this.props.value.length })
    }
  }

  render() {
    const {
      type,
      label,
      placeholder,
      description,
      error,
      success,
      name,
      disabled,
      maxCount,
      onChange, // eslint-disable-line
      initialValue, // eslint-disable-line
      value: valueProp, // eslint-disable-line
      ...props
    } = this.props

    const { value, currentCount } = this.state

    return (
      <React.Fragment>
        <S.InputContainer>
          {label && <S.InputLabel htmlFor={name}>{label}</S.InputLabel>}
          {description && <S.InputDescription>{description}</S.InputDescription>}
          <React.Fragment>
            <S.Input
              error={error}
              success={success}
              placeholder={placeholder}
              onChange={this.handleChange}
              name={name}
              id={name}
              value={value}
              type={type}
              disabled={disabled}
              {...props}
            />
            {maxCount >= 0 && (
              <S.InputCounter maxCount={maxCount} currentCount={currentCount}>
                {currentCount} / {maxCount}
              </S.InputCounter>
            )}
          </React.Fragment>
        </S.InputContainer>
        {error && !success && <Detail error={!!error}>{error}</Detail>}
        {success && !error && <Detail success={!!success}>{success}</Detail>}
      </React.Fragment>
    )
  }
}

Input.defaultProps = {
  type: 'text',
}

Input.propTypes = {
  /** HTML5 valid type of input (e.g.: text, email, number, date, search, tel...) */
  type: PropTypes.string,
  /** name attribute of input element */
  name: PropTypes.string.isRequired,
  /** label of the element */
  label: PropTypes.string,
  /** description of the element */
  description: PropTypes.string,
  /** placeholder of the element */
  placeholder: PropTypes.string,
  /** error message */
  error: PropTypes.string,
  /** success message */
  success: PropTypes.string,
  /** disable the component */
  disabled: PropTypes.bool,
  /** browser will display an error if input is empty on form submitting */
  required: PropTypes.bool,
  /** max possible characters */
  maxCount: PropTypes.number,
  /** mandatory function to retrieve value */
  onChange: PropTypes.func,
  /** function to retrieve change event */
  onChangeEvent: PropTypes.func,
  /** used for prefetched data */
  initialValue: PropTypes.string,
  /** used to update the input value from outside */
  value: PropTypes.string,
}

export default Input
