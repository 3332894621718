import * as React from 'react'
import * as PropTypes from 'prop-types'
import styled, { keyframes } from 'styled-components'

import * as colors from '@colors'

const loading = keyframes`
  from {
    opacity: 0.2;
    transform: scale(0.8);
  }

  50% {
    opacity: 1;
    transform: scale(1);
  }

  to {
    opacity: 0.2;
    transform: scale(0.8);
  }
`

const Dot = styled.span`
  animation: ${loading} 1.2s infinite ease-in-out both;
  background-color: ${props => colors.primary(props)};
  border-radius: 50%;
  display: inline-block;
  overflow: hidden;
  margin: 0;
  padding: 0;
  text-indent: -9999px;
`

const SpinnerWrapper = styled.span`
  display: flex;
  align-items: center;

  ${Dot /* sc-sel */} {
    width: ${props => (props.small ? '6px' : '10px')};
    height: ${props => (props.small ? '6px' : '10px')};
    margin: ${props => (props.small ? '0 3px' : '0 4px')};

    &:first-child {
      animation-delay: -320ms;
    }

    &:nth-child(2) {
      animation-delay: -160ms;
    }
  }
`

/** Should be use when the loading time will going to be long :) */
const Spinner = ({ small }) => (
  <SpinnerWrapper small={small}>
    <Dot />
    <Dot />
    <Dot />
  </SpinnerWrapper>
)

Spinner.defaultProps = {
  small: false,
}

Spinner.propTypes = {
  small: PropTypes.bool,
}

Spinner.displayName = 'legacy.Spinner'

export default Spinner
