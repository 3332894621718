import * as React from 'react'

export const OwlOnlyLogo = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="136" height="136" viewBox="0 0 140 140">
    <g fill="none">
      <path
        d="M96.866 105.612c-11.983 0-22.375-6.793-27.548-16.736a1.707 1.707 0 00-3.044 0c-5.172 9.943-15.565 16.736-27.548 16.736-17.879 0-32.227-15.117-30.96-33.267 1.06-15.177 13.269-27.51 28.435-28.71 13.295-1.05 24.993 6.286 30.39 17.278-2.378 2.714-3.832 6.257-3.832 10.15 0 4.276 1.749 8.136 4.557 10.932a.686.686 0 00.96 0c2.809-2.796 4.557-6.656 4.557-10.933 0-3.892-1.454-7.435-3.832-10.15 5.397-10.99 17.096-18.327 30.39-17.276 15.167 1.199 27.375 13.532 28.434 28.71 1.268 18.149-13.08 33.266-30.96 33.266"
        fill="#FFF"
      />
      <path
        d="M96.866 105.612c-11.983 0-22.375-6.793-27.548-16.736a1.707 1.707 0 00-3.044 0c-5.172 9.943-15.565 16.736-27.548 16.736-17.879 0-32.227-15.117-30.96-33.267 1.06-15.177 13.269-27.51 28.435-28.71 13.295-1.05 24.993 6.286 30.39 17.278-2.378 2.714-3.832 6.257-3.832 10.15 0 4.276 1.749 8.136 4.557 10.932a.686.686 0 00.96 0c2.809-2.796 4.557-6.656 4.557-10.933 0-3.892-1.454-7.435-3.832-10.15 5.397-10.99 17.096-18.327 30.39-17.276 15.167 1.199 27.375 13.532 28.434 28.71 1.268 18.149-13.08 33.266-30.96 33.266m37.49-76.476a2.808 2.808 0 00-3.975 0l-4.615 4.614C114.023 13.975 92.463.713 67.796.713S21.57 13.975 9.827 33.75l-4.614-4.614a2.802 2.802 0 00-1.988-.823 2.806 2.806 0 00-2.81 2.811v36.97c0 37.215 30.167 67.383 67.381 67.383s67.382-30.168 67.382-67.382V31.124c0-.718-.275-1.439-.823-1.988"
        fill="#000"
      />
      <path
        d="M37.62 50.382c6.77.65 12.09 6.334 12.135 13.277.048 7.418-5.925 13.475-13.344 13.525-7.42.05-13.476-5.92-13.523-13.34-.05-7.004 5.28-12.764 12.115-13.43l.002-.022c-10.298.682-18.413 9.261-18.344 19.718.074 10.86 8.938 19.601 19.792 19.528 10.86-.074 19.599-8.934 19.528-19.796-.07-10.37-8.171-18.794-18.361-19.46"
        fill="#18A5D6"
      />
      <path
        d="M39.254 52.808c1.18 1.119 1.96 2.233 1.949 4.055-.01 2.626-2.232 4.76-4.953 4.772-2.597-.017-4.791-2.222-4.807-4.815-.012-2.12 1.213-3.295 1.872-3.926l.29-.282c.743-.716 1.328-1.288 1.397-2.199-6.834.667-12.165 6.428-12.114 13.43.047 7.42 6.103 13.393 13.523 13.341 7.42-.05 13.392-6.106 13.344-13.524-.046-6.945-5.366-12.628-12.135-13.279l-.107-.004c.07.809.916 1.68 1.74 2.431"
        fill="#18A5D6"
      />
      <path
        d="M39.254 52.808c1.18 1.119 1.96 2.233 1.949 4.055-.01 2.626-2.232 4.76-4.953 4.772-2.597-.017-4.791-2.222-4.807-4.815-.012-2.12 1.213-3.295 1.872-3.926l.29-.282c.743-.716 1.328-1.288 1.397-2.199-6.834.667-12.165 6.428-12.114 13.43.047 7.42 6.103 13.393 13.523 13.341 7.42-.05 13.392-6.106 13.344-13.524-.046-6.945-5.366-12.628-12.135-13.279l-.107-.004c.07.809.916 1.68 1.74 2.431"
        fill="#004E69"
      />
      <path
        d="M100.463 50.383l.002.018c6.895.608 12.289 6.397 12.239 13.443-.048 7.42-6.104 13.392-13.524 13.34-7.42-.05-13.391-6.107-13.344-13.525.046-6.937 5.357-12.616 12.119-13.275-10.182.673-18.275 9.095-18.345 19.458-.07 10.862 8.667 19.722 19.528 19.796 10.854.073 19.719-8.668 19.793-19.528.07-10.5-8.112-19.105-18.468-19.727"
        fill="#F01446"
      />
      <path
        d="M102.203 52.808c1.18 1.118 1.959 2.232 1.949 4.055-.01 2.625-2.231 4.76-4.953 4.772-2.598-.018-4.79-2.222-4.807-4.814-.011-2.12 1.213-3.296 1.871-3.927l.292-.283c.75-.724 1.342-1.299 1.4-2.227-6.761.658-12.073 6.338-12.119 13.275-.047 7.418 5.924 13.474 13.345 13.525 7.42.051 13.475-5.92 13.522-13.34.051-7.046-5.343-12.836-12.237-13.444.083.802.919 1.665 1.737 2.408"
        fill="#F01446"
      />
      <path
        d="M102.203 52.808c1.18 1.118 1.959 2.232 1.949 4.055-.01 2.625-2.231 4.76-4.953 4.772-2.598-.018-4.79-2.222-4.807-4.814-.011-2.12 1.213-3.296 1.871-3.927l.292-.283c.75-.724 1.342-1.299 1.4-2.227-6.761.658-12.073 6.338-12.119 13.275-.047 7.418 5.924 13.474 13.345 13.525 7.42.051 13.475-5.92 13.522-13.34.051-7.046-5.343-12.836-12.237-13.444.083.802.919 1.665 1.737 2.408"
        fill="#8B0020"
      />
      <path d="M97.972 50.382l-.017.001h.017" fill="#FFF" />
    </g>
  </svg>
)

export const HorizontalLogo = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 506.93 157" width="104px" height="32.1px">
    <path
      d="M112.37 122.21a36.15 36.15 0 01-32.09-19.5 2 2 0 00-3.55 0 36.17 36.17 0 11.37-32.58 17.91 17.91 0 00.84 24.56.8.8 0 001.12 0 17.91 17.91 0 00.84-24.56 36.16 36.16 0 1132.46 52.07z"
      fill="#fff"
    />
    <path
      d="M156 33.11a3.27 3.27 0 00-4.63 0L146 38.49a78.49 78.49 0 00-135 0l-5.41-5.38A3.27 3.27 0 000 35.43V78.5a78.5 78.5 0 00157 0V35.43a3.27 3.27 0 00-1-2.32zm-43.67 89.09a36.15 36.15 0 01-32.09-19.5 2 2 0 00-3.55 0 36.17 36.17 0 11.37-32.58 17.91 17.91 0 00.84 24.56.8.8 0 001.12 0 17.91 17.91 0 00.84-24.56 36.16 36.16 0 1132.46 52.07z"
      fill="#000"
    />
    <path d="M43.34 57.86a15.83 15.83 0 11-3 0 22.83 22.83 0 103 0z" fill="#18a5d6" />
    <path
      d="M45.25 60.69a6 6 0 012.27 4.72 5.69 5.69 0 01-11.37 0 6 6 0 012.18-4.57l.34-.33a4.05 4.05 0 001.63-2.61 15.47 15.47 0 103 0h-.12c.12.9 1.11 1.92 2.07 2.79z"
      fill="#18a5d6"
    />
    <path
      d="M45.25 60.69a6 6 0 012.27 4.72 5.69 5.69 0 01-11.37 0 6 6 0 012.18-4.57l.34-.33a4.05 4.05 0 001.63-2.61 15.47 15.47 0 103 0h-.12c.12.9 1.11 1.92 2.07 2.79z"
      fill="#004e69"
    />
    <path d="M116.56 57.87a15.48 15.48 0 11-2.92 0 23 23 0 102.92 0z" fill="#f01446" />
    <path
      d="M118.58 60.69a6 6 0 012.27 4.72 5.69 5.69 0 01-11.37 0 6 6 0 012.18-4.57l.34-.33c.87-.84 1.56-1.51 1.63-2.59a15.82 15.82 0 102.92 0c.11.9 1.08 1.91 2.03 2.77z"
      fill="#f01446"
    />
    <path
      d="M118.58 60.69a6 6 0 012.27 4.72 5.69 5.69 0 01-11.37 0 6 6 0 012.18-4.57l.34-.33c.87-.84 1.56-1.51 1.63-2.59a15.82 15.82 0 102.92 0c.11.9 1.08 1.91 2.03 2.77z"
      fill="#8b0020"
    />
    <path d="M113.65 57.86z" fill="#fff" />
    <path
      d="M240.08 87.91c0 10.66-5.19 15.17-12.84 15.17s-12.85-4.51-12.85-15.17v-22a2 2 0 00-2.15-2l-16.54 1.47a2 2 0 00-1.81 2v20.53c0 22.82 13.39 34.3 33.34 34.3s33.34-11.61 33.34-34.3v-26a2 2 0 00-2.15-2l-16.54 1.45a2 2 0 00-1.81 2zM369.33 87.91c0 10.66-5.19 15.17-12.84 15.17s-12.85-4.51-12.85-15.17V54.6a2 2 0 00-2.15-2l-16.54 1.45a2 2 0 00-1.81 2v31.86c0 22.82 13.39 34.3 33.34 34.3s33.34-11.61 33.34-34.3V50.56a2 2 0 00-2.15-2L371.13 50a2 2 0 00-1.81 2zM402.66 99.93v18.52a2 2 0 002 2h37.64a2 2 0 002-2v-16.54a2 2 0 00-2-2h-19.14V47.64a2 2 0 00-2.15-2l-16.54 1.45a2 2 0 00-1.81 2zM273.41 99.93v18.52a2 2 0 002 2h37.66a2 2 0 002-2v-16.54a2 2 0 00-2-2h-19.16v-41a2 2 0 00-2.15-2l-16.54 1.45a2 2 0 00-1.81 2zM506.93 56.92V40.31a2 2 0 00-2.15-2l-46.94 4.11a2 2 0 00-1.81 2v74a2 2 0 002 2H505a2 2 0 002-2v-16.51a2 2 0 00-2-2h-28.46v-11.1h17.28a2 2 0 002-2V73a2 2 0 00-2-2h-17.28v-9.61l28.59-2.5a2 2 0 001.8-1.97z"
      fill="#000"
    />
  </svg>
)
