import * as React from 'react'
import styled from 'styled-components'

import * as fonts from '@fonts'

import { Icon } from '../Icon'
import { SpecificIconProps } from '../types'

export const Alert = styled(function Alert(props: SpecificIconProps): React.ReactElement<SpecificIconProps> {
  return (
    <Icon {...props} name="alert">
      <g>
        <path
          d="M18 17.058C18 18.131 17.105 19 16 19s-2-.87-2-1.942V8.942C14 7.869 14.895 7 16 7s2 .87 2 1.942v8.116zM16 0c8.837 0 16 7.163 16 16s-7.163 16-16 16S0 24.837 0 16 7.163 0 16 0zm0 3.556C9.127 3.556 3.556 9.127 3.556 16c0 6.873 5.571 12.444 12.444 12.444 6.873 0 12.444-5.571 12.444-12.444 0-6.873-5.571-12.444-12.444-12.444zM16 21c1.105 0 2 .707 2 1.579v.842c0 .872-.895 1.579-2 1.579s-2-.707-2-1.579v-.842c0-.872.895-1.579 2-1.579z"
          fillRule="evenodd"
        />
      </g>
    </Icon>
  )
})``

export const ArrowLeft = styled(function ArrowLeft(props: SpecificIconProps): React.ReactElement<SpecificIconProps> {
  return (
    <Icon {...props} name="arrow-left">
      <g>
        <path
          d="m 30.179517,13.985 a -1.778,1.778 0 1 0 0,-3.555 H 1.7355173 a -1.778,1.778 0 0 0 0,3.555 z M 4.2785173,12.207 13.644517,3.05 a -1.778,1.778 0 1 0 -2.485,-2.543 L 0.49251728,10.937 a -1.778,1.778 0 0 0 0,2.542 L 11.159517,23.909 a -1.778,1.778 0 1 0 2.485,-2.543 z"
          fillRule="nonzero"
        />
      </g>
    </Icon>
  )
})``

export const ArrowRight = styled(function ArrowRight(props: SpecificIconProps): React.ReactElement<SpecificIconProps> {
  return (
    <Icon {...props} name="arrow-right" transform="translate(0 3)">
      <g>
        <path
          d="M1.778 13.985a1.778 1.778 0 110-3.555h28.444a1.778 1.778 0 010 3.555H1.778zm25.901-1.778L18.313 3.05A1.778 1.778 0 1120.798.507l10.667 10.43a1.778 1.778 0 010 2.542l-10.667 10.43a1.778 1.778 0 11-2.485-2.543l9.366-9.159z"
          fillRule="nonzero"
        />
      </g>
    </Icon>
  )
})``

export const Bubble = styled(function Bubble(props: SpecificIconProps): React.ReactElement<SpecificIconProps> {
  return (
    <Icon {...props} name="bubble" viewBox="0 0 34 32">
      <g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M31.3887 22.0074C32.4514 19.9035 33.0041 17.5708 32.9983 15.2062V14.4184C32.5695 6.59636 26.402 0.428835 18.6779 0.00270422L17.7899 5.18107e-06C15.4291 -0.00615625 13.0956 0.546514 10.9841 1.61301C5.83911 4.18438 2.58375 9.44837 2.58152 15.2077C2.57602 17.316 3.01592 19.402 3.8692 21.3275L1.09265 29.6572C0.629427 31.0468 1.9515 32.3689 3.34116 31.9057L11.6708 29.1291C13.5964 29.9824 15.6827 30.4223 17.7945 30.4168C23.5499 30.4146 28.8139 27.1592 31.3887 22.0074ZM12.587 25.6304C12.164 25.4167 11.6731 25.3808 11.2234 25.5307L5.58969 27.4086L7.46761 21.7749C7.61749 21.3252 7.5816 20.8344 7.36791 20.4113C6.55388 18.7997 6.13203 17.0186 6.13674 15.213C6.13845 10.7961 8.63282 6.76265 12.5803 4.78977C14.1986 3.97235 15.9798 3.55051 17.7853 3.55521L18.58 3.55522C24.3967 3.87883 29.1195 8.60159 29.4458 14.5163L29.4431 15.213C29.4478 17.0186 29.026 18.7997 28.2119 20.4113C26.2357 24.3655 22.2022 26.8599 17.7892 26.8616C15.9798 26.8663 14.1986 26.4445 12.587 25.6304Z"
        />
      </g>
    </Icon>
  )
})``

export const Bulb = styled(function Bulb(props: SpecificIconProps): React.ReactElement<SpecificIconProps> {
  return (
    <Icon {...props} name="bulb" viewBox="0 0 22 32">
      <g>
        <path
          d="M11 0C4.608 0 0 5.186 0 11c0 2.773 1.028 5.303 2.723 7.237 1.04 1.187 2.67 3.675 3.276 5.76V24h3v-.007c0-.298-.045-.594-.135-.88C8.515 22 7.438 19.066 4.98 16.26A7.975 7.975 0 013.003 11C2.991 6.398 6.733 3 11 3c4.411 0 8 3.589 8 8a7.976 7.976 0 01-1.978 5.26c-2.445 2.787-3.526 5.716-3.881 6.84-.092.29-.139.591-.14.894V24h3v-.003c.606-2.085 2.237-4.573 3.277-5.76A10.945 10.945 0 0022 11c0-6.075-4.925-11-11-11zM6.004 28.699c0 .197.058.388.167.552l1.532 2.303a1 1 0 00.833.446h4.928a.999.999 0 00.832-.446l1.532-2.303c.109-.164.167-.356.168-.552l.003-2.7H6l.003 2.7zM11 5c-3.309 0-6 2.691-6 6a1 1 0 102 0c0-2.206 1.795-4 4-4a1 1 0 100-2z"
          fillRule="evenodd"
        />
      </g>
    </Icon>
  )
})``

export const Calendar = styled(function Calendar(props: SpecificIconProps): React.ReactElement<SpecificIconProps> {
  return (
    <Icon {...props} name="calendar">
      <g>
        <path
          d="M21 0c.828427 0 1.5.651222 1.5 1.454545V2.909l3 .000091c2.485281 0 4.5 1.953666 4.5 4.363636v20.363637C30 30.046333 27.985281 32 25.5 32h-21C2.014719 32 0 30.046333 0 27.636364V7.272727c0-2.40997 2.014719-4.363636 4.5-4.363636l3-.000091V1.454545C7.5.698476 8.094888.077141 8.85554.006658L9 0c.828427 0 1.5.651222 1.5 1.454545V2.909h9V1.454545c0-.756069.594888-1.377404 1.35554-1.447887zm6 14.545H3v13.091364c0 .803323.671573 1.454545 1.5 1.454545h21c.828427 0 1.5-.651222 1.5-1.454545V14.545zM7.5 5.818l-3 .000182c-.828427 0-1.5.651222-1.5 1.454545V11.636h24V7.272727c0-.803323-.671573-1.454545-1.5-1.454545l-3-.000182v1.454727c0 .756069-.594888 1.377405-1.35554 1.447887L21 8.727273c-.828427 0-1.5-.651222-1.5-1.454546V5.818h-9v1.454727c0 .756069-.594888 1.377405-1.35554 1.447887L9 8.727273c-.828427 0-1.5-.651222-1.5-1.454546V5.818z"
          fillRule="nonzero"
        />
      </g>
    </Icon>
  )
})``

export const CaretDown = styled(function CaretDown(props: SpecificIconProps): React.ReactElement<SpecificIconProps> {
  return (
    <Icon {...props} name="caret-down" viewBox="0 0 32 20">
      <g>
        <path
          d="M28.965144.527208c.694265-.702944 1.819892-.702944 2.514157 0 .694265.702944.694265 1.84264 0 2.545584l-14.222222 14.4c-.694266.702944-1.819892.702944-2.514158 0l-14.222222-14.4c-.694265-.702944-.694265-1.84264 0-2.545584.694265-.702944 1.819892-.702944 2.514157 0L16 13.654416 28.965144.527208z"
          fillRule="nonzero"
        />
      </g>
    </Icon>
  )
})``

export const CaretUp = styled(function CaretUp(props: SpecificIconProps): React.ReactElement<SpecificIconProps> {
  return (
    <Icon {...props} name="caret-up">
      <g transform="rotate(180 16 16), translate(0, 7)">
        <path
          d="M28.965144.527208c.694265-.702944 1.819892-.702944 2.514157 0 .694265.702944.694265 1.84264 0 2.545584l-14.222222 14.4c-.694266.702944-1.819892.702944-2.514158 0l-14.222222-14.4c-.694265-.702944-.694265-1.84264 0-2.545584.694265-.702944 1.819892-.702944 2.514157 0L16 13.654416 28.965144.527208z"
          fillRule="nonzero"
        />{' '}
      </g>
    </Icon>
  )
})``

export const Chain = styled(function Chain(props: SpecificIconProps): React.ReactElement<SpecificIconProps> {
  return (
    <Icon {...props} name="chain">
      <g>
        <path
          d="M11.71 18.502a1.79 1.79 0 01.36-2.503c.79-.592 1.91-.43 2.502.36a5.36 5.36 0 008.085.58l4.267-4.27a5.368 5.368 0 00-.066-7.52c-2.068-2.07-5.411-2.1-7.496-.084l-2.46 2.446c-.7.696-1.83.693-2.527-.007a1.79 1.79 0 01.008-2.53L16.86 2.51a8.93 8.93 0 0112.526.109c3.446 3.45 3.495 9.026.087 12.557l-4.289 4.292a8.932 8.932 0 01-13.474-.966zm8.58-5.004a1.79 1.79 0 01-.36 2.503c-.79.592-1.91.43-2.502-.36a5.36 5.36 0 00-8.085-.58l-4.267 4.27a5.368 5.368 0 00.066 7.52c2.068 2.07 5.411 2.1 7.493.088l2.444-2.446a1.786 1.786 0 012.528 0 1.79 1.79 0 010 2.529L15.14 29.49a8.93 8.93 0 01-12.526-.109c-3.446-3.45-3.495-9.026-.087-12.557l4.289-4.292a8.932 8.932 0 0113.474.966z"
          fillRule="nonzero"
        />
      </g>
    </Icon>
  )
})``

export const Check = styled(function Check(props: SpecificIconProps): React.ReactElement<SpecificIconProps> {
  return (
    <Icon {...props} name="check">
      <g transform="translate(0, 7)">
        <path
          d="M10.6666667 18.8191759L28.9651435.52069906c.6942654-.69426541 1.819892-.69426541 2.5141574 0 .6942655.6942654.6942655 1.81989203 0 2.51415744L11.9237454 22.5904121c-.6942654.6942654-1.819892.6942654-2.51415746 0L.52069906 13.7015232c-.69426541-.6942654-.69426541-1.8198921 0-2.5141575.6942654-.6942654 1.81989203-.6942654 2.51415744 0l7.6318102 7.6318102z"
          fillRule="evenodd"
        />
      </g>
    </Icon>
  )
})``

export const CheckCircle = styled(function CheckCircle(
  props: SpecificIconProps,
): React.ReactElement<SpecificIconProps> {
  return (
    <Icon {...props} name="check-circle">
      <g>
        <path
          d="M16 32C7.163 32 0 24.837 0 16S7.163 0 16 0s16 7.163 16 16-7.163 16-16 16zm0-3.556c6.873 0 12.444-5.571 12.444-12.444 0-6.873-5.571-12.444-12.444-12.444C9.127 3.556 3.556 9.127 3.556 16c0 6.873 5.571 12.444 12.444 12.444zm6.328-18.442a1.778 1.778 0 112.514 2.514l-10.43 10.43a1.778 1.778 0 01-2.514 0l-4.74-4.74a1.778 1.778 0 012.514-2.515l3.484 3.484 9.172-9.173z"
          fillRule="nonzero"
        />
      </g>
    </Icon>
  )
})``

export const Close = styled(function Close(props: SpecificIconProps): React.ReactElement<SpecificIconProps> {
  return (
    <Icon {...props} name="close" viewBox="0 0 19 19">
      <g>
        <path d="M2.7464 4.1606c-.4122-.4122-.4298-1.063-.0393-1.4535.3905-.3905 1.0413-.373 1.4535.0393L16.103 14.6886c.4122.4123.4298 1.063.0392 1.4535-.3905.3906-1.0412.373-1.4535-.0392L2.7464 4.1606Z" />
        <path d="M4.1606 16.1029c-.4122.4122-1.063.4298-1.4535.0392-.3905-.3905-.373-1.0412.0393-1.4535L14.6886 2.7464c.4123-.4122 1.063-.4298 1.4535-.0393.3906.3905.373 1.0413-.0392 1.4535L4.1606 16.1029Z" />
      </g>
    </Icon>
  )
})``

export const CodeBlock = styled(function CodeBlock(props: SpecificIconProps): React.ReactElement<SpecificIconProps> {
  return (
    <Icon {...props} name="code-block" viewBox="1 0 20 14">
      <g
        fill="none"
        fillRule="evenodd"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      >
        <path d="M6.28 2.546L1.8 7.026l4.48 4.48M15.987 11.507l4.48-4.48-4.48-4.48M9.476 12.2207l3.0562-9.9962" />
      </g>
    </Icon>
  )
})``

export const Edit = styled(function Edit(props: SpecificIconProps): React.ReactElement<SpecificIconProps> {
  return (
    <Icon {...props} name="edit">
      <g>
        <path
          d="M16.937 4.419L3.632 17.829v2.953h2.93l13.305-13.41-2.93-2.953zM18.22.536l5.498 5.542a1.84 1.84 0 010 2.588L8.6 23.906c-.341.344-.803.537-1.285.537H1.816A1.823 1.823 0 010 22.613V17.07c0-.486.191-.951.532-1.295L15.652.536a1.806 1.806 0 012.569 0zM1.816 32A1.823 1.823 0 010 30.17c0-1.011.813-1.83 1.816-1.83h24.368c1.003 0 1.816.819 1.816 1.83 0 1.01-.813 1.83-1.816 1.83H1.816z"
          fillRule="nonzero"
        />
      </g>
    </Icon>
  )
})``

export const Hash = styled(function Hash(props: SpecificIconProps): React.ReactElement<SpecificIconProps> {
  return (
    <Icon {...props} name="hash">
      <g>
        <text fontFamily={fonts.PRIMARY_FONT} fontWeight="bold" fontSize="40">
          <tspan x="4" y="31">
            #
          </tspan>
        </text>
      </g>
    </Icon>
  )
})``

export const Heart = styled(function Heart(props: SpecificIconProps): React.ReactElement<SpecificIconProps> {
  return (
    <Icon {...props} name="heart">
      <g>
        <path d="M16,30a1.63,1.63,0,0,1-1.2-.49L2.71,17.57a9,9,0,0,1,0-12.87,9.34,9.34,0,0,1,13.07,0L16,4.9l.24-.22a9.3,9.3,0,0,1,13,0l.05,0h0a9.06,9.06,0,0,1,0,12.89L17.21,29.51A1.78,1.78,0,0,1,16,30ZM9.23,5.38a5.74,5.74,0,0,0-4.1,1.75,5.66,5.66,0,0,0,0,8.11h0L16,25.92,26.88,15.18a5.8,5.8,0,0,0,1.78-4.06,5.68,5.68,0,0,0-1.78-4.06h0a6,6,0,0,0-8.23,0L17.21,8.49A1.75,1.75,0,0,1,16,9h0a1.77,1.77,0,0,1-1.2-.49L13.34,7.06A5.76,5.76,0,0,0,9.23,5.38Z" />
      </g>
    </Icon>
  )
})``

export const Letter = styled(function Letter(props: SpecificIconProps): React.ReactElement<SpecificIconProps> {
  return (
    <Icon {...props} name="letter" viewBox="0 0 32 26">
      <g fill="currentColor" fillRule="nonzero">
        <path d="M4.622 3.514c-.582 0-1.066.478-1.066 1.054v16.864c0 .576.484 1.054 1.066 1.054h22.756c.582 0 1.066-.478 1.066-1.054V4.568c0-.576-.484-1.054-1.066-1.054H4.622zm0-3.514h22.756C29.924 0 32 2.051 32 4.568v16.864C32 23.95 29.924 26 27.378 26H4.622C2.076 26 0 23.949 0 21.432V4.568C0 2.05 2.076 0 4.622 0zm24.58 3.128a1.791 1.791 0 012.476.432c.564.795.368 1.89-.436 2.447l-14.223 9.838a1.795 1.795 0 01-2.038 0L.758 6.007A1.744 1.744 0 01.322 3.56a1.791 1.791 0 012.475-.432L16 12.261l13.203-9.133z" />
      </g>
    </Icon>
  )
})``

export const Link = styled(function Link(props: SpecificIconProps): React.ReactElement<SpecificIconProps> {
  return (
    <Icon {...props} name="link">
      <g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M11.8998 14.6539C11.1378 15.2242 10.9822 16.3047 11.5521 17.0672C12.7499 18.6695 14.5841 19.6722 16.5787 19.8152C18.5733 19.9581 20.5316 19.2271 21.9453 17.812L25.0465 14.7088C27.6794 11.9814 27.642 7.6801 24.9835 5.01992C22.3251 2.35973 18.0265 2.32236 15.3222 4.93591L13.5262 6.72229C12.8514 7.39363 12.8482 8.48527 13.5191 9.16053C14.19 9.8358 15.281 9.83898 15.9558 9.16764L17.734 7.39871C19.0683 6.10939 21.2176 6.12808 22.5468 7.45817C23.8761 8.78826 23.8948 10.9389 22.5888 12.2919L19.5084 15.3739C18.8013 16.0817 17.8222 16.4472 16.8249 16.3758C15.8276 16.3043 14.9105 15.8029 14.3116 15.0018C13.7416 14.2393 12.6618 14.0835 11.8998 14.6539ZM18.0997 15.3461C18.8617 14.7758 19.0173 13.6953 18.4473 12.9328C17.2496 11.3305 15.4154 10.3278 13.4208 10.1848C11.4262 10.0419 9.46789 10.7729 8.05415 12.188L4.95296 15.2912C2.32008 18.0186 2.35743 22.3199 5.01592 24.9801C7.67441 27.6403 11.973 27.6776 14.6773 25.0641L16.4665 23.2742C17.1393 22.6009 17.1393 21.5092 16.4665 20.8359C15.7936 20.1626 14.7026 20.1626 14.0298 20.8359L12.262 22.6048C10.9312 23.8906 8.78187 23.8719 7.45263 22.5418C6.12338 21.2117 6.1047 19.0611 7.41065 17.7081L10.4911 14.6261C11.1982 13.9183 12.1773 13.5528 13.1746 13.6242C14.1719 13.6957 15.089 14.1971 15.6879 14.9982C16.2579 15.7607 17.3377 15.9165 18.0997 15.3461Z"
        />
      </g>
    </Icon>
  )
})``

export const Lock = styled(function Lock(props: SpecificIconProps): React.ReactElement<SpecificIconProps> {
  return (
    <Icon {...props} name="social-facebook">
      <g>
        <path
          d="M14 0c4.6391919 0 8.4 3.90733309 8.4 8.72727273V13.09l1.4.0009091c2.3195959 0 4.2 1.9536665 4.2 4.3636364v10.1818181C28 30.0463335 26.1195959 32 23.8 32H4.2C1.88040405 32 0 30.0463335 0 27.6363636V17.4545455c0-2.4099699 1.88040405-4.3636364 4.2-4.3636364L5.6 13.09V8.72727273c0-4.73230437 3.62529469-8.5848862 8.1478416-8.72341569zm9.8 16H4.2c-.77319865 0-1.4.6512222-1.4 1.4545455v10.1818181c0 .8033233.62680135 1.4545455 1.4 1.4545455h19.6c.7731986 0 1.4-.6512222 1.4-1.4545455V17.4545455C25.2 16.6512222 24.5731986 16 23.8 16zM14 2.90909091c-3.0927946 0-5.6 2.60488873-5.6 5.81818182V13.09h11.2V8.72727273c0-3.13856535-2.3919472-5.69669516-5.3851979-5.8139805z"
          fillRule="nonzero"
        />
      </g>
    </Icon>
  )
})``

export const MapCursor = styled(function MapCursor(props: SpecificIconProps): React.ReactElement<SpecificIconProps> {
  return (
    <Icon {...props} name="map-cursor" viewBox="0 0 32 33">
      <g>
        <path
          d="M13.5 0C20.956 0 27 6.006 27 13.414c0 4.51-2.401 8.884-6.346 13.02a40.897 40.897 0 01-4.21 3.825 30.81 30.81 0 01-1.952 1.442 1.799 1.799 0 01-1.984 0 30.81 30.81 0 01-1.952-1.442 40.897 40.897 0 01-4.21-3.824C2.4 22.298 0 17.923 0 13.415 0 6.004 6.044 0 13.5 0zm0 3.556c-5.48 0-9.922 4.413-9.922 9.858 0 3.41 1.99 7.036 5.365 10.575a37.369 37.369 0 004.557 4.04 37.369 37.369 0 004.557-4.04c3.374-3.54 5.365-7.165 5.365-10.575 0-5.445-4.442-9.858-9.922-9.858zm0 4.202c3.144 0 5.693 2.532 5.693 5.656 0 3.124-2.549 5.657-5.693 5.657-3.144 0-5.693-2.533-5.693-5.657s2.549-5.656 5.693-5.656zm0 3.555c-1.168 0-2.114.94-2.114 2.101 0 1.16.946 2.101 2.114 2.101s2.114-.94 2.114-2.1c0-1.161-.946-2.102-2.114-2.102z"
          fillRule="nonzero"
        />
      </g>
    </Icon>
  )
})``

export const Minus = styled(function Minus(props: SpecificIconProps): React.ReactElement<SpecificIconProps> {
  return (
    <Icon {...props} name="minus">
      <g transform="translate(0, 15)">
        <path
          d="M1.7778 4.2222c-.9819 0-1.7778-.8954-1.7778-2 0-1.1045.796-2 1.7778-2h28.4444c.9819 0 1.7778.8955 1.7778 2 0 1.1046-.796 2-1.7778 2H1.7778z"
          fillRule="nonzero"
        />
      </g>
    </Icon>
  )
})``

export const Person = styled(function Person(props: SpecificIconProps): React.ReactElement<SpecificIconProps> {
  return (
    <Icon {...props} name="person" viewBox="0 0 19 19">
      <g>
        <path d="M9.5255 11.2812c5.5833 0 8.3347 2.483 8.3347 6.7844 0 .5161-.4146.9344-.9261.9344-.5115 0-.9261-.4183-.9261-.9344 0-3.22-1.879-4.9156-6.4825-4.9156-4.6036 0-6.4826 1.6956-6.4826 4.9156 0 .5161-.4146.9344-.926.9344-.5115 0-.9262-.4183-.9262-.9344 0-4.3014 2.7515-6.7844 8.3348-6.7844ZM9.5254 0c2.8576 0 4.7627 1.9 4.7627 4.75 0 2.7952-1.7537 4.6355-4.4198 4.7442l-.1578.0044c-.0613.001-.123.0014-.1851.0014-2.8576 0-4.7627-1.9-4.7627-4.75S6.6677 0 9.5254 0Zm0 1.7813c-1.8712 0-2.9767 1.1025-2.9767 2.9687s1.1055 2.9688 2.9767 2.9688l.1582-.0012c1.7829-.0267 2.8185-1.0955 2.8185-2.9676 0-1.8662-1.1055-2.9688-2.9767-2.9688Z" />
      </g>
    </Icon>
  )
})``

export const Plus = styled(function Plus(props: SpecificIconProps): React.ReactElement<SpecificIconProps> {
  return (
    <Icon {...props} name="plus">
      <g>
        <path
          d="M14.222 1.778a1.778 1.778 0 113.556 0v28.444a1.778 1.778 0 11-3.556 0V1.778zm-12.444 16a1.778 1.778 0 110-3.556h28.444a1.778 1.778 0 110 3.556H1.778z"
          fillRule="nonzero"
        />
      </g>
    </Icon>
  )
})``

export const Read = styled(function Read(props: SpecificIconProps): React.ReactElement<SpecificIconProps> {
  return (
    <Icon {...props} name="read">
      <g transform="translate(0 3)">
        <path
          d="M16 0c4.506403 0 8.45159 2.176655 11.80605 5.754745 1.152448 1.229279 2.153283 2.541848 3.001503 3.855221.512491.793535.864259 1.418901 1.051683 1.793749.187685.37537.187685.8172 0 1.19257-.187424.374848-.539192 1.000214-1.051683 1.793749-.84822 1.313373-1.849055 2.625942-3.001503 3.855221C24.45159 21.823345 20.506403 24 16 24c-4.506403 0-8.45159-2.176655-11.80605-5.754745-1.152448-1.229279-2.153283-2.541848-3.001503-3.855221-.512491-.793535-.864259-1.418901-1.051683-1.793749-.187685-.37537-.187685-.8172 0-1.19257.187424-.374848.539192-1.000214 1.051683-1.793749.84822-1.313373 1.849055-2.625942 3.001503-3.855221C7.54841 2.176655 11.493597 0 16 0zm0 2.666667c-3.660263 0-6.965077 1.823345-9.860617 4.911921-1.035051 1.104055-1.940467 2.291485-2.70683 3.478113-.217517.336799-.410435.653125-.57827.943299.167835.290174.360753.6065.57827.943299.766363 1.186628 1.671779 2.374058 2.70683 3.478113C9.034923 19.509988 12.339737 21.333333 16 21.333333c3.660263 0 6.965077-1.823345 9.860617-4.911921 1.035051-1.104055 1.940467-2.291485 2.70683-3.478113.217517-.336799.410435-.653125.57827-.943299-.167835-.290174-.360753-.6065-.57827-.943299-.766363-1.186628-1.671779-2.374058-2.70683-3.478113C22.965077 4.490012 19.660263 2.666667 16 2.666667zm0 4c2.945519 0 5.333333 2.387814 5.333333 5.333333 0 2.945519-2.387814 5.333333-5.333333 5.333333-2.945519 0-5.333333-2.387814-5.333333-5.333333 0-2.945519 2.387814-5.333333 5.333333-5.333333zm0 2.666666c-1.472759 0-2.666667 1.193908-2.666667 2.666667S14.527241 14.666667 16 14.666667 18.666667 13.472759 18.666667 12 17.472759 9.333333 16 9.333333z"
          fillRule="nonzero"
        />
      </g>
    </Icon>
  )
})``

export const Search = styled(function Search(props: SpecificIconProps): React.ReactElement<SpecificIconProps> {
  return (
    <Icon {...props} name="search" viewBox="0 0 19 19">
      <g fillRule="nonzero">
        <path d="M8.2582 15.3987c-3.9436 0-7.1406-3.1969-7.1406-7.1405 0-3.9436 3.197-7.1406 7.1406-7.1406s7.1405 3.197 7.1405 7.1406-3.1969 7.1405-7.1405 7.1405Zm0-1.8628c2.9148 0 5.2777-2.3629 5.2777-5.2777 0-2.9149-2.3629-5.2778-5.2777-5.2778-2.9149 0-5.2778 2.363-5.2778 5.2778s2.363 5.2777 5.2778 5.2777Zm9.3514 2.7565c.3637.3637.3637.9534 0 1.3172-.3638.3637-.9535.3637-1.3172 0l-4.305-4.3051a.9313.9313 0 0 1 1.3171-1.3171l4.3051 4.305Z" />
      </g>
    </Icon>
  )
})``

export const Share = styled(function Share(props: SpecificIconProps): React.ReactElement<SpecificIconProps> {
  return (
    <Icon {...props} name="share">
      <g>
        <path
          d="M21 0c3 0 6 3 6 6s-3 5-6 5l-3-1-7 4a6 6 0 010 2l7 4 3-1c3 0 6 2 6 5s-3 6-6 6a6 6 0 01-5-7l-7-4-3 2c-3 0-6-3-6-6s3-6 6-6l3 2 7-4V6c0-3 2-6 5-6zm0 22l-2 1v1c0 2 1 3 2 3 2 0 3-1 3-3 0-1-1-2-3-2zM6 13c-2 0-3 1-3 2s1 2 3 2a2 2 0 002-1 2 2 0 000-2l-2-1zM21 3c-1 0-2 1-2 3a2 2 0 000 1 2 2 0 005-1c0-2-1-3-3-3z"
          fillRule="nonzero"
        />
      </g>
    </Icon>
  )
})``

export const Shipping = styled(function Shipping(props: SpecificIconProps): React.ReactElement<SpecificIconProps> {
  return (
    <Icon {...props} name="shipping">
      <g>
        <path
          d="M16.82061.424171L27.729572 5.83782C29.119765 6.523452 29.999176 7.930986 30 9.472451v12.898738c-.000824 1.540746-.880235 2.94828-2.267409 3.632419l-10.908572 5.413456c-.319572.158716-.65486.273417-.997137.344105-.238117.150683-.52204.238831-.826706.238831-.301479 0-.582647-.086314-.819785-.235403-.348888-.069335-.691294-.185426-1.017302-.34734l-10.91273-5.415526C.86153 25.307181-.010404 23.891106.000094 22.356937V9.471646c.001116-1.540721.880527-2.94821 2.267668-3.632332L13.179743.424171c1.146735-.565561 2.494131-.565561 3.640867 0zM2.727 9.984l.000462 12.382269c-.003542.514564.287129.986632.746323 1.216281L13.466 28.541V15.312L2.727 9.984zm24.545 0l-10.738 5.328v13.222l9.98195-4.952943c.463398-.228544.756535-.697722.75681-1.210586L27.272 9.984zM14.396384 2.846722L4.419 7.797l10.56204 5.241808c.00637-.000077.012748-.000116.019136-.000116L15.019 13.039 25.58 7.797l-9.976031-4.950278.003018.001493c-.382245-.18852-.831377-.18852-1.210603-.001493z"
          fillRule="nonzero"
        />
      </g>
    </Icon>
  )
})``

export const Star = styled(function Star(props: SpecificIconProps): React.ReactElement<SpecificIconProps> {
  return (
    <Icon {...props} name="star">
      <g fill="transparent" stroke="currentColor" strokeWidth={3}>
        <path d="M11.615 10.463l3.707-7.515a1.25 1.25 0 0 1 2.243 0l3.71 7.514 8.295 1.213a1.25 1.25 0 0 1 .692 2.13l-6.002 5.848 1.417 8.257a1.25 1.25 0 0 1-1.814 1.317l-7.418-3.902-7.42 3.9a1.25 1.25 0 0 1-1.815-1.318l1.417-8.257-6-5.847a1.25 1.25 0 0 1 .69-2.13l8.296-1.213.002.002z" />
      </g>
    </Icon>
  )
})``

export const Stopwatch = styled(function Stopwatch(props: SpecificIconProps): React.ReactElement<SpecificIconProps> {
  return (
    <Icon {...props} name="stopwatch" viewBox="0 0 32 33">
      <g fillRule="nonzero">
        <path d="M13 6.5a13 13 0 100 26 13 13 0 000-26zm0 3.25a9.75 9.75 0 110 19.5 9.75 9.75 0 010-19.5z" />
        <path d="M13 13c.83 0 1.52.63 1.61 1.44l.02.19v4.2l1.96 1.96c.58.58.63 1.5.13 2.14l-.13.16c-.59.58-1.51.63-2.15.13l-.15-.13-2.44-2.44a1.63 1.63 0 01-.46-.94l-.02-.21v-4.88c0-.9.73-1.62 1.63-1.62zM13 9.75c-.83 0-1.52-.63-1.61-1.44l-.02-.19v-6.5a1.63 1.63 0 013.24-.18l.02.19v6.5c0 .9-.73 1.62-1.63 1.62z" />
        <path d="M9.75 3.25A1.63 1.63 0 019.56.01L9.75 0h6.5a1.62 1.62 0 01.19 3.24l-.19.01h-6.5z" />
      </g>
    </Icon>
  )
})``

export const Tag = styled(function Tag(props: SpecificIconProps): React.ReactElement<SpecificIconProps> {
  return (
    <Icon {...props} name="tag" viewBox="0 0 32 26">
      <>
        <path
          d="M15.57 0H1.73C.77 0 0 .77 0 1.73v13.85c0 .46.18.9.5 1.22l11.9 11.88a4.5 4.5 0 006.36 0l9.93-9.93a4.5 4.5 0 000-6.35L16.8.5c-.32-.32-.76-.5-1.22-.5zM3.47 3.46h11.39l11.38 11.39c.4.4.4 1.05 0 1.46l-9.92 9.92c-.2.2-.46.3-.74.3h-.16a1.04 1.04 0 01-.57-.3L3.46 14.86V3.46z"
          fillRule="nonzero"
        />
      </>
    </Icon>
  )
})``

export const TimesCircle = styled(function TimesCircle(
  props: SpecificIconProps,
): React.ReactElement<SpecificIconProps> {
  return (
    <Icon {...props} name="times-circle">
      <g fillRule="nonzero">
        <path d="M27.314 27.314c-6.249 6.248-16.38 6.248-22.628 0-6.248-6.249-6.248-16.38 0-22.628 6.249-6.248 16.38-6.248 22.628 0 6.248 6.249 6.248 16.38 0 22.628zM24.8 24.8c4.86-4.86 4.86-12.74 0-17.6-4.86-4.86-12.74-4.86-17.6 0-4.86 4.86-4.86 12.74 0 17.6 4.86 4.86 12.74 4.86 17.6 0z" />
        <path d="M9.767 12.281a1.778 1.778 0 112.514-2.514l9.386 9.386a1.778 1.778 0 11-2.514 2.514l-9.386-9.386zm2.514 9.386a1.778 1.778 0 11-2.514-2.514l9.386-9.386a1.778 1.778 0 112.514 2.514l-9.386 9.386z" />
      </g>
    </Icon>
  )
})``

export const Tip = styled(function Tip(props: SpecificIconProps): React.ReactElement<SpecificIconProps> {
  return (
    <Icon {...props} name="tip">
      <g fillRule="nonzero" transform="translate(5, 6), scale(1.15)">
        <path d="M14.5 13c-.3841 0-.7567-.1268-1.056-.3592l-.036-.031-1.012-.8416-.484-.386C9.06 9.0655 7 7.386 7 4.7065 7 2.0272 9 .0002 11.568.0002c1.0876-.0109 2.1377.3832 2.932 1.1004.7926-.7173 1.8416-1.1115 2.928-1.1004 2.564 0 4.568 2.0656 4.572 4.7064 0 2.0502-1.308 3.5328-2.588 4.7103-.636.5907-1.356 1.1815-2.188 1.8648l-.144.1159c-.472.386-.976.7914-1.492 1.2277l-.036.0309c-.3002.2263-.6711.3475-1.052.3437zM11.568 1.9151C10.128 1.9152 9 3.1314 9 4.6912c0 1.7065 1.364 2.8918 4.2 5.2006l.496.4093c.26.2162.532.4362.8.6679.448-.386.884-.7336 1.292-1.0617l.136-.112c.8-.664 1.504-1.2355 2.1-1.7876 1.2-1.0926 1.972-2.0733 1.972-3.3165 0-1.5444-1.132-2.776-2.572-2.776-.8671.008-1.6642.4612-2.092 1.1892-.1854.266-.496.4252-.828.4247-.3335.0018-.6458-.1576-.832-.4247-.43-.7316-1.2326-1.1853-2.104-1.1892zM5.0146 21.2961a1.2488 1.2488 0 011.1828 0c3.3795 1.7748 5.0308 1.7785 5.6606 1.6612 0 0 5.1153-.1665 10.3688-4.9761l.4224-.4163c.3803-.3749.4604-.9536.1959-1.4152-.2086-.3605-.5622-.6172-.9735-.7066a1.5078 1.5078 0 00-1.1848.2373l-1.636.9045a26.9317 26.9317 0 00-3.122 1.9866c-1.8777 1.5265-4.5682 1.5998-6.5286.1779 2.3503.8136 4.6084.1551 5.6376-.6698.9332-.7266.8986-1.6007-.9063-1.718-2.7766-.1854-3.6368-.6812-3.9018-.9801a3.161 3.161 0 00-.8333-.6925C5.6636 12.6229 2 15.862 2 15.862l.2112.3785 2.3772 4.3517.384.6736a.0502.0502 0 00.0576.0227" />
        <path d="M2.187 16L5 21.5674 2.8633 23 0 17.2113z" />
      </g>
    </Icon>
  )
})``

export const Unread = styled(function Unread(props: SpecificIconProps): React.ReactElement<SpecificIconProps> {
  return (
    <Icon {...props} name="unread">
      <g transform="translate(0 1)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0.520699 1.39961C-0.173566 2.09387 -0.173566 3.2195 0.520699 3.91376L5.74707 9.14013C3.50862 11.1343 1.63348 13.5092 0.211279 16.1596C-0.0618479 16.6686 -0.0706466 17.2785 0.187685 17.7952C0.376978 18.1738 0.726684 18.7955 1.23387 19.5808C2.07127 20.8774 3.05912 22.173 4.19799 23.3878C7.53424 26.9464 11.4763 29.1214 16 29.1214C18.6875 29.0776 21.2837 28.3165 23.5378 26.9309L28.9651 32.3582C29.6594 33.0525 30.785 33.0525 31.4793 32.3582C32.1736 31.6639 32.1736 30.5383 31.4793 29.844L3.03486 1.39961C2.34059 0.70534 1.21496 0.70534 0.520699 1.39961ZM10.9578 14.3509L8.26609 11.6592C6.51064 13.194 5.00855 14.998 3.81663 17.0046C3.93285 17.1979 4.06764 17.4149 4.22068 17.6518C4.94893 18.7795 5.80956 19.9081 6.7919 20.956C9.51626 23.862 12.6045 25.5658 15.9709 25.5661C17.7061 25.5377 19.4018 25.1083 20.9295 24.3225L18.6493 22.0424C17.4052 22.733 15.9243 22.9404 14.5036 22.5776C12.4994 22.0657 10.9344 20.5008 10.4226 18.4966C10.0597 17.0758 10.2672 15.595 10.9578 14.3509ZM13.8046 17.1977C13.8113 17.3376 13.8321 17.478 13.8676 17.6168C14.0577 18.3612 14.639 18.9425 15.3834 19.1326C15.5222 19.168 15.6626 19.1888 15.8025 19.1956L13.8046 17.1977ZM27.7793 16.3485C27.0511 15.2209 26.1904 14.0922 25.2081 13.0443C22.4837 10.1384 19.3955 8.4345 15.9958 8.43449C15.2196 8.43267 14.4458 8.5211 13.69 8.69801C12.734 8.92179 11.7776 8.3282 11.5539 7.3722C11.3301 6.4162 11.9237 5.45981 12.8797 5.23604C13.9038 4.99631 14.9524 4.87648 16 4.87894C20.5237 4.87894 24.4658 7.05388 27.802 10.6125C28.9409 11.8273 29.9287 13.1229 30.7661 14.4195C31.2733 15.2048 31.623 15.8265 31.8123 16.2051C32.0702 16.7209 32.0619 17.3297 31.7901 17.8382C30.9469 19.4156 29.9414 20.9007 28.7898 22.2692C28.1576 23.0205 27.0361 23.117 26.2849 22.4849C25.5336 21.8527 25.4371 20.7312 26.0692 19.98C26.8556 19.0454 27.563 18.0477 28.1844 16.9974C28.0679 16.8036 27.9328 16.5861 27.7793 16.3485Z"
        />
      </g>
    </Icon>
  )
})``
