import styled, { css } from 'styled-components'

import * as colors from '@colors'
import * as fonts from '@fonts'
import { Icon } from '@glyphs/index'

export type BaseLinkStyleProps = {
  /** @default false */
  disabled?: boolean
  /** @default false */
  underlined?: boolean
  /** @default false */
  uppercase?: boolean
  /** @default false */
  withIcon?: boolean
}

export type LinkStyleProps = BaseLinkStyleProps & React.AnchorHTMLAttributes<HTMLAnchorElement>

const withIconStyle = css`
  align-items: center;
  display: flex;

  ${Icon} {
    color: ${colors.GREY_SHADE_4};
    margin-right: 5px;
  }

  ${Icon} + * {
    vertical-align: middle;
  }
`

export const _baseStyle = css<BaseLinkStyleProps>`
  cursor: pointer;
  font-family: ${fonts.PRIMARY_FONT};
  font-weight: 600;
  outline: none;
  text-decoration: none;
  transition: color 200ms ease-in-out;

  ${({ disabled = false }) =>
    disabled &&
    css`
      &[href] {
        pointer-events: none;
        opacity: 0.4;
      }

      opacity: 0.4;
    `}

  ${({ underlined = false }) =>
    underlined &&
    css`
      text-decoration: underline;
    `}

  ${({ uppercase = false }) =>
    uppercase &&
    css`
      text-transform: uppercase;
    `}

  ${({ withIcon = false }) => withIcon && withIconStyle}
`

export const Base = styled.a<LinkStyleProps>`
  ${_baseStyle}
`

type BasePrimaryLinkStyleProps = BaseLinkStyleProps & {
  /** @default false */
  tinted?: boolean
}

type PrimaryLinkStyleProps = BasePrimaryLinkStyleProps & LinkStyleProps

export const _primaryTintedColor = css`
  color: ${colors.PRIMARY_BLUE};
`

export const _primaryStyle = css<BasePrimaryLinkStyleProps>`
  ${({ tinted = false }) => (tinted ? _primaryTintedColor : `color: ${colors.PRIMARY_BLACK}`)};

  &:active,
  &:hover {
    &[href] {
      color: ${colors.PRIMARY_BLUE_HOVER};
    }
  }
`

export const Primary = styled(Base)<PrimaryLinkStyleProps>`
  ${_primaryStyle}
`
Primary.displayName = 'S.link.Primary'

export const Secondary = styled(Base)<LinkStyleProps>`
  color: ${colors.GREY_SHADE_3};
  font-weight: 400;

  &:active,
  &:hover {
    &[href] {
      text-decoration: underline;
    }
  }
`
Secondary.displayName = 'S.link.Secondary'

export const Inverted = styled(Base)<LinkStyleProps>`
  color: ${colors.PRIMARY_WHITE};

  &:hover,
  &:active {
    &[href] {
      text-decoration: underline;
    }
  }
`

Inverted.displayName = 'S.link.Inverted'
