import * as React from 'react'
import * as PropTypes from 'prop-types'
import styled from 'styled-components'
import 'intersection-observer'

import * as colors from '@colors'

import Icon from './Icon'

// NOTE: This is merely a proxy enabling us to target the component and style it
const VideoLoaderPlayIcon = styled(Icon)``

const VideoLoaderWrapper = styled.div`
  margin: 0 auto;
  text-align: center;
  position: relative;

  &:hover {
    cursor: pointer;

    ${VideoLoaderPlayIcon} {
      polygon {
        fill: ${colors.PRIMARY_BLUE_HOVER};
      }
    }
  }
`

const VideoLoaderButton = styled.div`
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
`

const VideoLoaderThumbnail = styled.img`
  max-width: 100%;
`

// TODO: Improve support for non-16/9 videos
const VideoWrapper = styled.div`
  background-color: black;
  height: 0;
  padding-bottom: 56.25%;
  position: relative;
`

const Video = styled.div`
  iframe,
  video {
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }
`

const setParam = (videoHtml = '', param, value) => {
  const paramAlreadySet = new RegExp(`[?&]${param}=`)
  const hasQueryParams = /\?[^=]+=/
  const srcAttribute = /src="(\S+)"/

  if (paramAlreadySet.test(videoHtml)) {
    return videoHtml
  }

  const [, url] = videoHtml.match(srcAttribute)

  const lastCharacter = url.charAt(url.length - 1)
  if (lastCharacter === '?' || lastCharacter === '&') {
    return videoHtml.replace(srcAttribute, `src="${url}${param}=${value}"`)
  }

  if (hasQueryParams.test(url)) {
    return videoHtml.replace(srcAttribute, `src="${url}&${param}=${value}"`)
  }

  return videoHtml.replace(srcAttribute, `src="${url}?${param}=${value}"`)
}

const setParams = (videoHtml = '', params) => {
  for (const param in params) {
    const value = params[param]
    videoHtml = setParam(videoHtml, param, value)
  }
  return videoHtml
}

const useEnterView = (onEnterView, threshold) => {
  const ref = React.useRef(null)

  React.useEffect(() => {
    // References to window object must be done in useEffect or componentDidMount to be
    // ignored by gatsby when building the DS website. Otherwise we get
    // `XXX is not defined` errors
    const observer = new window.IntersectionObserver(
      entries => {
        const hasEnteredView = entries.some(entry => entry.intersectionRatio >= threshold)
        if (hasEnteredView) {
          onEnterView()
        }
      },
      { threshold },
    )

    if (ref.current !== null) {
      observer.observe(ref.current)
    }
  })

  return ref
}

/** Acts as a placeholder for video content, and only loads the video when clicked. */
const VideoLoader = ({ className, placeholder, autoplay, srcSet, videoHtml }) => {
  const [videoLoaded, setVideoLoaded] = React.useState(false)
  const video = autoplay ? setParams(videoHtml, { mute: 1, autoplay: 1 }) : setParams(videoHtml, { autoplay: 1 })

  const ref = useEnterView(() => {
    if (autoplay) {
      setVideoLoaded(true)
    }
  }, 1)

  return (
    <React.Fragment>
      {!videoLoaded && (
        <VideoLoaderWrapper
          ref={ref}
          className={className}
          onClick={event => {
            event.preventDefault()
            setVideoLoaded(true)
          }}
        >
          <VideoLoaderButton>
            <VideoLoaderPlayIcon size={72} glyph="video-play" />
          </VideoLoaderButton>
          <VideoLoaderThumbnail src={placeholder} srcSet={srcSet} />
        </VideoLoaderWrapper>
      )}
      {videoLoaded && (
        <VideoWrapper className={className}>
          <Video dangerouslySetInnerHTML={{ __html: video }} />
        </VideoWrapper>
      )}
    </React.Fragment>
  )
}

VideoLoader.defaultProps = {}

VideoLoader.propTypes = {
  className: PropTypes.string,
  /** URL of the placeholder image (shown before video is actually loaded) */
  placeholder: PropTypes.string.isRequired,
  /** Whether to autoplay the video when it enters view or not */
  autoplay: PropTypes.bool,
  /** String of images for retina screen */
  srcSet: PropTypes.string,
  /** HTML code (iframe or video element) that will be loaded after a click */
  videoHtml: PropTypes.string.isRequired,
}

VideoLoader.displayName = 'legacy.VideoLoader'

export default VideoLoader
