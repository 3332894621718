import styled, { css } from 'styled-components'

import * as colors from '@colors'
import * as fonts from '@fonts'

export const _baseStyle = css`
  color: ${colors.PRIMARY_BLACK};
  font-family: ${fonts.PRIMARY_FONT};
  margin: 0;
  padding: 0;
`

export const Base = styled.p<React.HTMLAttributes<HTMLParagraphElement>>`
  ${_baseStyle}
`

export const smallStyle = css`
  ${_baseStyle};
  font-size: 14px;
  line-height: 22px;
  font-weight: 400;
`

export const mediumStyle = css`
  ${_baseStyle};
  font-size: 16px;
  line-height: 24px;
  font-weight: 300;
`

export const largeStyle = css`
  ${_baseStyle};
  font-size: 18px;
  line-height: 26px;
  font-weight: 300;
`

export const XS = styled(Base)<React.HTMLAttributes<HTMLParagraphElement>>`
  font-size: 11px;
  line-height: 16px;
`
XS.displayName = 'S.copy.XS'

export const S = styled(Base)<React.HTMLAttributes<HTMLParagraphElement>>`
  ${smallStyle}
`
S.displayName = 'S.copy.S'

export const M = styled(Base)<React.HTMLAttributes<HTMLParagraphElement>>`
  ${mediumStyle}
`
M.displayName = 'S.copy.M'

export const L = styled(Base)<React.HTMLAttributes<HTMLParagraphElement>>`
  ${largeStyle}
`
L.displayName = 'S.copy.L'

export const Serif = styled(Base)<React.HTMLAttributes<HTMLParagraphElement>>`
  font-size: 18px;
  line-height: 26px;
  font-family: ${fonts.SECONDARY_FONT};
`
Serif.displayName = 'S.copy.Serif'
