import * as React from 'react'
import * as PropTypes from 'prop-types'

export const Variant = _props => {
  return <></>
} // eslint-disable-line no-unused-vars

Variant.propTypes = {
  variant: PropTypes.shape({
    /** description of the variant  */
    description: PropTypes.oneOfType([
      PropTypes.shape({ __html: PropTypes.string.isRequired }),
      PropTypes.string.isRequired,
    ]).isRequired,
    /** if defined, tells how much of the reward is left  */
    stock: PropTypes.shape({
      /** total stock of the reward */
      total: PropTypes.number,
      /** amount already taken */
      taken: PropTypes.number.isRequired,
      /** amount still available */
      available: PropTypes.number,
    }).isRequired,
  }),
  translations: PropTypes.shape({
    add: PropTypes.string.isRequired,
    remove: PropTypes.string.isRequired,
    soldOut: PropTypes.string.isRequired,
    soonSoldOut: PropTypes.string.isRequired,
    choose: PropTypes.string.isRequired,
  }).isRequired,
  /** mode of the Reward Card (passed from parent, no need to set it) */
  mode: PropTypes.oneOf(['project', 'preview', 'checkout']),
  /** whether this variant is selected or not */
  isSelected: PropTypes.bool,
  isProjectFinished: PropTypes.bool,
  /** either handle click (mode project) or add/remove (mode checkout) */
  handlers: PropTypes.oneOfType([
    PropTypes.shape({
      onClick: PropTypes.func.isRequired,
    }),
    PropTypes.shape({
      onAdd: PropTypes.func.isRequired,
      onRemove: PropTypes.func.isRequired,
    }),
  ]),
}
