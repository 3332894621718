import * as PropTypes from 'prop-types'
import styled from 'styled-components'

import * as fonts from '@fonts'
import * as colors from '@colors'
import { hexToRgb } from '../utils/colors'

export const BasicLink = styled.a`
  font-family: ${fonts.PRIMARY_FONT};
  font-size: 13px;
  font-weight: 500;
  cursor: pointer;
`

export const PrimaryLink = styled(BasicLink)`
  color: ${props => colors.primary(props)};
  text-decoration: none;
  transition: color 200ms ease-in-out;

  &:hover {
    color: ${props => hexToRgb(colors.primary(props), 0.85)};
  }
`

PrimaryLink.propTypes = {
  as: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
}

export const SecondaryLink = styled(BasicLink)`
  color: ${colors.PRIMARY_BLACK};
  text-decoration: none;
  transition: color 200ms ease-in-out;

  &:hover {
    color: ${hexToRgb(colors.PRIMARY_BLACK, 0.85)};
  }
`

SecondaryLink.propTypes = {
  as: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
}

export const TertiaryLink = styled(BasicLink)`
  color: ${colors.GREY_SHADE_3};
  text-decoration: none;
  transition: text-decoration 200ms ease-in-out;

  :hover {
    text-decoration: underline;
    color: ${hexToRgb(colors.GREY_SHADE_3, 0.85)};
  }
`

TertiaryLink.propTypes = {
  as: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
}
