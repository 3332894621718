export function reducer(state, action) {
  switch (action.type) {
    case 'register': {
      const optionIndex = state.registered.findIndex(option => option.value === action.option.value)
      if (optionIndex !== -1) {
        const option = state.registered[optionIndex]
        if (option.disabled !== action.option.disabled) {
          return {
            ...state,
            registered: [
              ...state.registered.slice(0, optionIndex),
              { ...option, disabled: action.option.disabled },
              ...state.registered.slice(optionIndex + 1),
            ],
          }
        }
        return state
      }

      const nextState = {
        ...state,
        registered: [...state.registered, action.option],
      }
      return nextState
    }
    case 'deregister': {
      const optionIndex = state.registered.findIndex(option => option.value === action.value)
      if (optionIndex !== -1) {
        return {
          ...state,
          registered: [...state.registered.slice(0, optionIndex), ...state.registered.slice(optionIndex + 1)],
        }
      }

      return state
    }

    case 'focus': {
      return {
        ...state,
        focused: action.focused,
      }
    }
    case 'open': {
      return {
        ...state,
        focused: action.focused,
        open: true,
      }
    }
    case 'close': {
      if (!state.open) {
        return state
      }
      return {
        ...state,
        focused: undefined,
        open: false,
      }
    }
    default: {
      return state
    }
  }
}
