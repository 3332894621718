import styled from 'styled-components'

import ClickOutside from '../../../core/ClickOutside'
import { Label, Description } from '../Label'

import * as colors from '@colors'
import * as fonts from '@fonts'

// NOTE: for Firefox & Edge compatibility
const encodeColor = color => color.replace(/#/, '%23')

const getInputBorder = (error, success, disabled, focused) => {
  if (error) {
    return `1px dashed ${colors.SECONDARY_RED}`
  }

  if (disabled) {
    return `1px solid ${colors.GREY_SHADE_5}`
  }

  if (success) {
    return `1px solid ${colors.PRIMARY_GREEN}`
  }

  if (focused) {
    return `1px solid ${colors.PRIMARY_BLUE}`
  }

  return `1px solid ${colors.GREY_SHADE_5}`
}

export const PhoneInputWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
`

export const PhoneInputContainer = styled.div`
  display: flex;
  flex-direction: column;
  font-family: ${fonts.PRIMARY_FONT}, sans-serif;

  ${Label /* sc-sel */} + ${PhoneInputWrapper /* sc-sel */} {
    margin-top: 8px;
  }

  ${Description /* sc-sel */} + ${PhoneInputWrapper /* sc-sel */} {
    margin-top: 8px;
  }
`

export const PhoneInput = styled.input`
  width: 100%;
  font-family: ${fonts.PRIMARY_FONT}, sans-serif;
  font-size: 15px;
  font-weight: 400;
  color: ${colors.PRIMARY_BLACK};
  border: ${props => getInputBorder(props.error, props.success, props.disabled, props.focused)};
  border-radius: 2px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  padding: 15px;
  outline-color: ${colors.PRIMARY_BLUE};
  /* stylelint-disable-next-line */
  background-color: ${props => (props.disabled ? `${colors.GREY_SHADE_6}` : `${colors.PRIMARY_WHITE}`)};
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  ::placeholder {
    color: ${colors.GREY_SHADE_3};
    font-size: 15px;
    font-weight: 400;
  }
`

/* prettier-ignore */
export const PanelPositionner = styled(ClickOutside)`
  position: absolute;
  bottom: 0;
  width: 100%;
  max-height: 200px;
  background-color: ${colors.PRIMARY_WHITE};
  border: 1px solid ${colors.PRIMARY_BLUE};
  transform: translateY(calc(100% - 2px));
  overflow: hidden;
  z-index: 1;

  ::after {
    content: '';
    background-color: transparent;
    background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" fill-rule="evenodd" clip-rule="evenodd" viewBox="0 0 32 32" width="8"><g transform="translate(0, 12)"><path d="M32 .9c-.32-.6-.96-.9-1.918-.9H1.918C.958 0 .319.3 0 .9 0 1.8 0 2.4.32 3l14.079 14.7c.642 0 .96.3 1.6.3.64 0 .96-.3 1.283-.602L31.362 3C32 2.4 32 1.8 32 .9" fill="${encodeColor(colors.GREY_SHADE_4)}"/></g></svg>');
    background-repeat: no-repeat;
    background-position: center center;
    width: 100%;
    height: 16px;
    position: absolute;
    left: 50%;
    bottom: 0;
    transform: translateX(-50%);
  }
`

/* prettier-ignore */
export const CountryCodeItem = styled.li`
  position: relative;
  list-style-type: none;
  margin: 0;
  padding: 0;
  font-size: 15px;
  font-family: ${fonts.PRIMARY_FONT}, sans-serif;
  font-weight: 400;
  cursor: pointer;

  &:hover {
    color: ${colors.PRIMARY_BLUE};
  }

  ::before {
    content: '';
    background-color: ${colors.PRIMARY_BLUE};
    background-image: url('data:image/svg+xml;utf8,<svg width="8" height="6" xmlns="http://www.w3.org/2000/svg"><path d="M3.4214 3.7186c-.4942-.4935-.9652-.9657-1.4362-1.4355-.2309-.2303-.449-.2303-.6788-.0047a56.9675 56.9675 0 0 0-.4976.4901c-.2309.228-.2309.4699-.0023.699.666.6654 1.3309 1.3303 1.9957 1.9951.1007.1007.199.2031.3026.302.184.1766.4132.18.6047.0076.0243-.0214.0446-.0428.0677-.0648 1.1365-1.137 2.2729-2.2729 3.41-3.411a.775.775 0 0 0 .0688-.0758c.1464-.1777.1423-.4132-.015-.5764a18.8792 18.8792 0 0 0-.5926-.5867c-.177-.1684-.3946-.1643-.5798-.004a1.2406 1.2406 0 0 0-.0683.0653c-.8326.831-1.6659 1.6613-2.498 2.4945-.0289.0295-.0503.0654-.081.1053" fill="${encodeColor(colors.PRIMARY_WHITE)}" fill-rule="evenodd"/></svg>');
    background-repeat: no-repeat;
    background-position: center center;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    position: absolute;
    left: 0;
    top: 50%;
    transform: translate(-150%, -50%);
    display: ${props => (props.selected ? 'block' : 'none')};
  }
`

export const CountryCodePanel = styled.ul`
  margin: 0;
  padding: 24px;
  max-height: 200px;
  overflow-y: scroll;

  ${CountryCodeItem /* sc-sel */} + ${CountryCodeItem /* sc-sel */} {
    margin-top: 8px;
  }
`

export const Prefix = styled.span`
  color: ${colors.GREY_SHADE_3};
`

export const CallingPrefixDropdown = styled.div`
  background-color: ${colors.PRIMARY_WHITE};
  display: flex;
  align-items: center;
  border: ${props => getInputBorder(props.error, props.success, props.disabled, props.focused)};
  border-right: 0;
  border-radius: 2px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  padding: 0 10px;
  cursor: ${props => (props.disabled ? 'initial' : 'cursor')};
  pointer-events: ${props => (props.disabled ? 'none' : 'auto')};
`

export const Flag = styled.img`
  width: 30px;
  margin: 0 8px 0 4px;
`
