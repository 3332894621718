import * as React from 'react'
import * as PropTypes from 'prop-types'
import styled from 'styled-components'

import * as bp from '@bp'
import * as fonts from '@fonts'
import * as colors from '@colors'

import DiscreetText from './DiscreetText'
import LabelWithIcon from '@legacy/LabelWithIcon'
import GoalLabel from './GoalLabel'
import { ProjectCardReverse, ProjectCardReverseData } from './ProjectCardReverse'
import ProjectCardPicture from './ProjectCardPicture'
import ProjectCardTag from './ProjectCardTag'
import ProjectCardTitle from './ProjectCardTitle'
import RoundButton from '@legacy/RoundButton'
import Icon from '@legacy/Icon'
import { shorten } from '../../utils/shorten'

const ProjectCardRibbon = styled.img`
  max-height: 42%;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 1;
`

/* stylelint-disable-next-line */
const ProjectCardWrapper = styled.a`
  background-color: ${colors.PRIMARY_WHITE};
  display: flex;
  position: relative;
  flex-direction: column;
  border-width: ${props => (props.isSelected ? '3px' : '1px')};
  border-style: solid;
  border-color: ${props => (props.isSelected ? colors.PRIMARY_GREEN : colors.GREY_SHADE_5)};
  border-radius: 4px;
  min-width: 230px;
  max-width: 310px;
  flex: 1 1 auto;
  font-family: ${fonts.PRIMARY_FONT};
  cursor: pointer;
  text-decoration: none;
  -webkit-tap-highlight-color: transparent;

  :hover {
    text-decoration: none;
  }

  ${ProjectCardPicture /* sc-sel */},
  ${ProjectCardRibbon /* sc-sel */} {
    transition: opacity 250ms ease-out;
    opacity: 1;
  }

  /* stylelint-disable-next-line */
  ${ProjectCardReverse /* sc-sel */} {
    position: absolute;
    top: 0;
    left: 0;
    transition: opacity 250ms ease-out;
    opacity: 0;
  }

  @media screen and ${bp.TABLET} {
    &:hover {
      ${ProjectCardPicture /* sc-sel */},
      ${ProjectCardRibbon /* sc-sel */} {
        transition: opacity 250ms ease-in;
        opacity: ${props => (props.isOnline ? 0 : 1)};
      }

      /* stylelint-disable-next-line */
      ${ProjectCardReverse /* sc-sel */} {
        transition: opacity 250ms ease-in;
        opacity: 1;
      }
    }
  }
`

const ProjectCardHeader = styled.div`
  background-color: ${colors.GREY_SHADE_6};
  border-radius: 3px 3px 0 0;
  display: block;
  height: auto;
  padding: ${() => 9 / (16 / 100)}% 0 0 0; /* keep ratio A:B -> B / (A / 100) = C% */
  position: relative;
  overflow: hidden;
  width: 100%;
`

const Button = styled(RoundButton)`
  position: absolute;
  top: 0;
  right: 12px;
  transform: translate(0, -50%);
`

const ProjectCardContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 16px 14px 0;
  position: relative;
`

const ProjectCardFooter = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  line-height: 14px;
  padding: 10px 14px;
`

const Columns = styled.div`
  align-items: center;
  flex-direction: row;
  display: flex;
  justify-content: space-between;
  line-height: 18px;
  padding: 4px 0;

  span {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
`
/** ProjectCard displays a card-shaped summary of a project. */
const ProjectCard = ({
  action,
  absoluteUrl,
  currency,
  category,
  endDate,
  extraTime,
  goal,
  language,
  isOnline,
  isSelected,
  owner,
  productsSoldCount,
  progress,
  picture,
  raisedAmount,
  rates,
  ribbonImage,
  srcSet,
  subtitle,
  targetCurrency,
  title,
  translation,
  type,
  ...props
}) => {
  const isFinished = endDate ? (new Date().getTime() >= new Date(endDate).getTime() ? true : false) : false
  const status = getStatus()

  return (
    <ProjectCardWrapper href={absoluteUrl} isSelected={isSelected} isOnline={isOnline} {...props}>
      <ProjectCardHeader>
        {ribbonImage && <ProjectCardRibbon src={ribbonImage} />}
        <ProjectCardPicture src={picture} srcSet={srcSet} alt={title} />
        {subtitle && (
          <ProjectCardReverse isSelected={isSelected}>
            <span>{shorten(subtitle, 80)}</span>
            {isOnline && (
              <div>
                <ProjectCardReverseData dangerouslySetInnerHTML={{ __html: translation['backerCount'] }} />
                <ProjectCardReverseData dangerouslySetInnerHTML={{ __html: translation['progressInfo'] }} />
              </div>
            )}
          </ProjectCardReverse>
        )}
      </ProjectCardHeader>
      {isSelected && (
        <ProjectCardTag>
          <LabelWithIcon icon={<Icon glyph="star" size={12} />} label={translation['highlights']} inline={true} />
        </ProjectCardTag>
      )}
      <ProjectCardContent>
        {action && action.isCompact && <Button {...action} />}
        <ProjectCardTitle>{title}</ProjectCardTitle>
        <Columns>
          <DiscreetText>{owner}</DiscreetText>
          {isOnline && <DiscreetText light>{category}</DiscreetText>}
        </Columns>
      </ProjectCardContent>
      <ProjectCardFooter>
        <DiscreetText>
          {isOnline ? (
            <GoalLabel
              goal={goal}
              productsSoldCount={productsSoldCount}
              raisedAmount={raisedAmount}
              type={type}
              progress={progress}
              currency={currency}
              targetCurrency={targetCurrency}
              language={language}
              rates={rates}
            />
          ) : (
            translation['comingSoon']
          )}
        </DiscreetText>
        <DiscreetText>{status}</DiscreetText>
      </ProjectCardFooter>
    </ProjectCardWrapper>
  )

  function getStatus() {
    if (extraTime) {
      return translation['extraTime']
    } else if (isFinished) {
      return translation['finished']
    }

    return isOnline && translation['shortCountdown']
  }
}

ProjectCard.defaultProps = {
  isOnline: true,
  isSelected: false,
  isFinished: false,
  type: 'project',
}

ProjectCard.propTypes = {
  /** if defined, it will display action button on card */
  action: PropTypes.shape({
    /** trigger when action button is clicked */
    onClick: PropTypes.func,
    /** icon of the roundButton */
    icon: PropTypes.oneOf(['inactive-heart', 'active-heart', 'created', 'backed']).isRequired,
    /** string value representing the button text */
    text: PropTypes.string.isRequired,
  }),
  /** url of the project page on Ulule */
  absoluteUrl: PropTypes.string,
  /** name of the project's category */
  category: PropTypes.string,
  /** currency of the project */
  currency: PropTypes.string.isRequired,
  /** string value representing the project end date */
  endDate: PropTypes.string,
  /** boolean value project is on extra time */
  extraTime: PropTypes.bool,
  /** total amount of the project */
  goal: PropTypes.string,
  /** switch template of component */
  isOnline: PropTypes.bool,
  /** highlight component if set to true */
  isSelected: PropTypes.bool,
  /** language of project to choose right title and description */
  language: PropTypes.string.isRequired,
  /** name of the project's owner */
  owner: PropTypes.string,
  /** url of the main picture project */
  picture: PropTypes.string,
  /** used only in case of project with presales */
  productsSoldCount: PropTypes.string,
  /**	percentage of the project's progress */
  progress: PropTypes.number,
  /** list of currency rates */
  rates: PropTypes.objectOf(PropTypes.number).isRequired,
  /** amount raised by project currently */
  raisedAmount: PropTypes.number,
  /** URL of the image used as a corner ribbon (e.g. for an active sponsorship) */
  ribbonImage: PropTypes.string,
  /** image set with valid pixel density (e.g. 2x) or width descriptors (e.g. 640w) */
  srcSet: PropTypes.string,
  /** string value representing the project start date */
  startDate: PropTypes.string,
  /** short description only displayed on project card hover */
  subtitle: PropTypes.string,
  /** current currency, if different to project currency it will convert amount of project */
  targetCurrency: PropTypes.string.isRequired,
  /** name of the project */
  title: PropTypes.string,
  translation: PropTypes.shape({
    addToMyList: PropTypes.string,
    backed: PropTypes.string,
    backerCount: PropTypes.string.isRequired,
    comingSoon: PropTypes.string.isRequired,
    created: PropTypes.string,
    extraTime: PropTypes.string,
    finished: PropTypes.string.isRequired,
    highlights: PropTypes.string.isRequired,
    listed: PropTypes.string,
    progressInfo: PropTypes.string.isRequired,
    shortCountdown: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  }).isRequired,
  /** type of the project will change goal displaying */
  type: PropTypes.oneOf(['presale', 'project']),
}

export default ProjectCard
ProjectCard.displayName = 'legacy.ProjectCard'
