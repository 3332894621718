import * as React from 'react'
import * as PropTypes from 'prop-types'

import Transition from 'react-transition-group/Transition'

export const BottomToMiddle = ({ children, duration = 300, delay = 0, inProp = false, ...props }) => {
  const defaultStyle = {
    transition: `transform ${duration}ms cubic-bezier(0.645, 0.045, 0.355, 1)`,
    transitionDelay: `${delay}ms`,
  }

  const transitionStyles = {
    entering: {
      transform: 'translate(0, 100vh)',
    },
    entered: {
      transform: 'translate(0, 0)',
    },
    exiting: {
      transform: 'translate(0, 100vh)',
    },
  }

  return (
    <Transition
      appear
      in={inProp}
      timeout={{
        enter: 0,
        exit: duration,
      }}
      {...props}
    >
      {status => {
        if (status === 'exited') {
          return null
        }

        const currentStyles = transitionStyles[status]
        return React.cloneElement(children, {
          style: Object.assign({}, defaultStyle, currentStyles),
        })
      }}
    </Transition>
  )
}

BottomToMiddle.propTypes = {
  children: PropTypes.element.isRequired,
  inProp: PropTypes.bool,
  duration: PropTypes.number,
  delay: PropTypes.number,
}

export const ZoomIn = ({ children, duration = 300, delay = 0, inProp = false, ...props }) => {
  const defaultStyle = {
    transition: `all ${duration}ms cubic-bezier(0.645, 0.045, 0.355, 1)`,
    transitionDelay: `${delay}ms`,
  }

  const transitionStyles = {
    entering: {
      opacity: 0,
      transform: 'scale(0.3)',
    },
    entered: {
      opacity: 1,
      transform: 'scale(1)',
    },
    exiting: {
      opacity: 0,
      transform: 'scale(0.3)',
    },
  }

  return (
    <Transition
      appear
      in={inProp}
      timeout={{
        enter: 0,
        exit: duration,
      }}
      {...props}
    >
      {status => {
        if (status === 'exited') {
          return null
        }

        const currentStyles = transitionStyles[status]
        return React.cloneElement(children, {
          style: Object.assign({}, defaultStyle, currentStyles),
        })
      }}
    </Transition>
  )
}

ZoomIn.propTypes = {
  children: PropTypes.element.isRequired,
  inProp: PropTypes.bool,
  duration: PropTypes.number,
  delay: PropTypes.number,
}

export const FadeInAndOut = ({ children, duration = 300, delay = 0, inProp = false }) => {
  const defaultStyle = {
    transition: `opacity ${duration}ms cubic-bezier(0.645, 0.045, 0.355, 1)`,
    transitionDelay: `${delay}ms`,
    opacity: 0,
  }
  const transitionStyles = {
    entering: {
      opacity: 0,
    },
    entered: {
      opacity: 1,
    },
    exiting: {
      opacity: 0,
    },
  }

  return (
    <Transition
      appear
      in={inProp}
      timeout={{
        enter: 0,
        exit: duration,
      }}
    >
      {status => {
        if (status === 'exited') {
          return null
        }

        const currentStyles = transitionStyles[status]
        return React.cloneElement(children, {
          style: Object.assign({}, defaultStyle, currentStyles),
        })
      }}
    </Transition>
  )
}
FadeInAndOut.propTypes = {
  children: PropTypes.element.isRequired,
  inProp: PropTypes.bool,
  duration: PropTypes.number,
  delay: PropTypes.number,
}
