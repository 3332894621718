import styled from 'styled-components'

import * as bp from '@bp'
import * as colors from '@colors'
import * as glyphs from '@glyphs/index'
import * as zindex from '@zindex'
import * as heading from './heading'

export const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: ${zindex.MODAL};
`

export const Wrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: ${colors.PRIMARY_WHITE};

  &::before {
    content: '';
    width: 100%;
    height: 50px;
    background: ${colors.PRIMARY_BLUE};
    display: block;
  }

  ${glyphs.stroke.Close} {
    color: ${colors.PRIMARY_WHITE};
    left: 13px;
    position: absolute;
    top: 16px;
    &:hover {
      cursor: pointer;
    }
  }

  @media screen and ${bp.TABLET} {
    width: 100%;
    max-width: 540px;
    border: 1px solid ${colors.GREY_SHADE_5};
    position: relative;

    &::before {
      height: 20px;
      background: ${colors.PRIMARY_WHITE};
    }

    ${glyphs.stroke.Close} {
      left: auto;
      top: 13px;
      right: 13px;
      color: ${colors.GREY_SHADE_4};
    }
  }
`

export const Body = styled.div`
  padding: 15px 24px;
`

export const SmallWrapper = styled(Wrapper)`
  box-shadow: rgba(0, 0, 0, 0.5) 0 2px 3px;

  @media screen and ${bp.TABLET} {
    border: none;
  }

  &::before {
    height: 0;
  }

  ${glyphs.stroke.Close} {
    top: 16px;
    color: ${colors.PRIMARY_WHITE};
  }
`

export const Title = styled(heading.Card1)`
  background: ${colors.PRIMARY_BLUE};
  color: ${colors.PRIMARY_WHITE};
  padding: 13px 37px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`
