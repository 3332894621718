import styled from 'styled-components'

import * as colors from '@colors'

const DiscreetText = styled.span`
  font-size: 12px;
  font-weight: ${props => (props.light ? 300 : 400)};
  color: ${colors.GREY_SHADE_2};
`

export default DiscreetText
