import * as React from 'react'
import * as PropTypes from 'prop-types'
import styled from 'styled-components'

import { PRIMARY_BLUE } from '@colors'
import * as fonts from '@fonts'

const getSize = size => {
  switch (size) {
    case 'sm':
      return '10px'
    case 'md':
    default:
      return '12px'
  }
}

const getPadding = size => {
  switch (size) {
    case 'sm':
      return '0 1px'
    case 'md':
    default:
      return '0 6px'
  }
}

const TagWrapper = styled.span`
  background-color: ${({ backgroundColor }) => backgroundColor};
  border: none;
  border-radius: 1px;
  color: ${({ textColor }) => textColor};
  font-weight: normal;
  font-family: ${fonts.PRIMARY_FONT};
  font-size: ${({ size }) => getSize(size)};
  display: inline-block;
  white-space: nowrap;
  min-width: 22px;
  min-height: 20px;
  line-height: 20px;
  padding: ${({ size }) => getPadding(size)};
  vertical-align: middle;
  text-align: center;
`

const Tag = ({ children, backgroundColor, textColor, size }) => (
  <TagWrapper textColor={textColor} backgroundColor={backgroundColor} size={size}>
    {children}
  </TagWrapper>
)

Tag.defaultProps = {
  textColor: '#FFFFFF',
  backgroundColor: PRIMARY_BLUE,
  size: 'md',
}

Tag.propTypes = {
  /** color of the tag's background */
  backgroundColor: PropTypes.string,
  /** color of the label's background */
  textColor: PropTypes.string,
  size: PropTypes.oneOf(['sm', 'md', 'lg']),
  /** elements to insert inside tag */
  children: PropTypes.node.isRequired,
}

export default Tag
