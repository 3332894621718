import * as React from 'react'
import * as PropTypes from 'prop-types'
import styled from 'styled-components'

import * as colors from '@colors'
import * as fonts from '@fonts'

import ClickOutside from '../core/ClickOutside'
import { SecondaryLink } from '@legacy/Links'
import ShadowBox from '@legacy/ShadowBox'

/* eslint-disable @typescript-eslint/no-use-before-define */
const CurrentLanguage = styled.span`
  border: 1px solid ${colors.PRIMARY_BLACK};
  color: ${colors.PRIMARY_BLACK};
  font-family: ${fonts.PRIMARY_FONT};
  font-size: 13px;
  font-weight: 500;
  padding: 5px;
  cursor: pointer;

  ${CurrentCurrency /* sc-sel */} + & {
    border-left: 0;
  }

  &:hover {
    border: 1px solid ${props => colors.primary(props)};
    color: ${props => colors.primary(props)};
  }
`
/* eslint-enable */

const CurrentCurrency = styled.span`
  border: 1px solid ${colors.PRIMARY_BLACK};
  color: ${colors.PRIMARY_BLACK};
  font-family: ${fonts.PRIMARY_FONT};
  font-size: 13px;
  font-weight: 500;
  padding: 5px;
  cursor: pointer;

  ${CurrentLanguage /* sc-sel */} + & {
    border-left: 0;
  }

  &:hover {
    border: 1px solid ${props => colors.primary(props)};
    color: ${props => colors.primary(props)};

    ${CurrentLanguage /* sc-sel */} + & {
      margin-left: -1px;
    }
  }
`

const Wrapper = styled.div`
  position: relative;
  display: flex;
`

const LanguagesBox = styled(ShadowBox)`
  position: absolute;
  top: -10px;
  transform: translate3d(0, -100%, 0);
  width: 300px;
`

const CurrenciesBox = styled(ShadowBox)`
  position: absolute;
  top: -10px;
  transform: translate3d(0, -100%, 0);
  width: 325px;
`

const LinksWrapper = styled.ul`
  display: flex;
  flex-direction: ${props => (props.wrapped ? 'row' : 'column')};
  flex-wrap: ${props => (props.wrapped ? 'wrap' : 'nowrap')};
  justify-content: flex-start;
  list-style-type: none;
  margin: 0;
  padding: 0;

  > li {
    flex: 0 0 ${props => (props.wrapped ? '110px' : '260px')};
    margin: 10px 20px;
    min-height: 30px;
  }
`

export const Button = styled(SecondaryLink)`
  align-items: center;
  cursor: pointer;
  display: flex;
  font-family: ${fonts.PRIMARY_FONT};
  font-size: 13px;
  font-weight: 900;
  text-transform: uppercase;

  :hover {
    color: ${props => colors.primaryHover(props)};
  }
`

const CurrencyChoice = styled(Button)`
  align-items: flex-start;
  display: flex;
  flex-direction: column;
`

const CurrencySymbol = styled.span`
  white-space: nowrap;
  font-size: 18px;
  font-weight: 500;
  color: ${colors.PRIMARY_BLACK};

  ${CurrencyChoice /* sc-sel */}:hover & {
    color: ${props => colors.primary(props)};
  }
`

const CurrencyName = styled.span`
  white-space: nowrap;
  font-size: 12px;
  font-weight: 400;
  color: ${colors.GREY_SHADE_3};

  ${CurrencyChoice /* sc-sel */}:hover & {
    color: ${props => colors.primary(props)};
  }
`

class I18nSettingsBox extends React.Component {
  state = {
    languages: false,
    currencies: false,
  }

  constructor(props) {
    super(props)
    this.languageRef = React.createRef()
    this.currencyRef = React.createRef()
  }

  toggleLanguages = () => {
    this.setState({ languages: !this.state.languages })
  }

  closeLanguages = event => {
    if (event.target === this.languageRef.current) {
      event.stopPropagation()
    }
    this.setState({ languages: false })
  }

  toggleCurrencies = () => {
    this.setState({ currencies: !this.state.currencies })
  }

  closeCurrencies = event => {
    if (event.target === this.currencyRef.current) {
      event.stopPropagation()
    }
    this.setState({ currencies: false })
  }

  render() {
    return (
      <Wrapper>
        {this.state.languages && (
          <ClickOutside stopPropagation={false} onClickOutside={this.closeLanguages}>
            <LanguagesBox>
              <LinksWrapper wrapped>
                {Object.keys(this.props.languages).map(code => (
                  <li key={code}>
                    <Button
                      active={this.props.currentLanguage === code}
                      onClick={() => this.props.onLanguageClick(code)}
                    >
                      {this.props.languages[code]}
                    </Button>
                  </li>
                ))}
              </LinksWrapper>
            </LanguagesBox>
          </ClickOutside>
        )}
        {this.state.currencies && (
          <ClickOutside stopPropagation={false} onClickOutside={this.closeCurrencies}>
            <CurrenciesBox>
              <LinksWrapper wrapped>
                {Object.keys(this.props.currencies).map(code => (
                  <li key={code}>
                    <CurrencyChoice
                      active={this.props.currentLanguage === code}
                      onClick={() => this.props.onCurrencyClick(code)}
                    >
                      <CurrencySymbol>{this.props.currencies[code].symbol}</CurrencySymbol>
                      <CurrencyName>{this.props.currencies[code].name}</CurrencyName>
                    </CurrencyChoice>
                  </li>
                ))}
              </LinksWrapper>
            </CurrenciesBox>
          </ClickOutside>
        )}
        {this.props.languages && this.props.currentLanguage && (
          <CurrentLanguage ref={this.languageRef} onClick={this.toggleLanguages} role="button">
            {this.props.languages[this.props.currentLanguage]}
          </CurrentLanguage>
        )}
        {this.props.currencies && this.props.currentCurrency && (
          <CurrentCurrency ref={this.currencyRef} onClick={this.toggleCurrencies} role="button">
            {this.props.currencies[this.props.currentCurrency].symbol}
          </CurrentCurrency>
        )}
      </Wrapper>
    )
  }
}

I18nSettingsBox.propTypes = {
  currentLanguage: PropTypes.string,
  currentCurrency: PropTypes.string,
  languages: PropTypes.object,
  currencies: PropTypes.object,
  onLanguageClick: PropTypes.func.isRequired,
  onCurrencyClick: PropTypes.func.isRequired,
}

export default I18nSettingsBox
