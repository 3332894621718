import * as React from 'react'
import styled from 'styled-components'
import * as PropTypes from 'prop-types'

import * as breakpoints from '@bp'
import * as colors from '@colors'
import * as fonts from '@fonts'

import { hexToRgb } from '../../utils/colors'

import Icon from '../Icon'
import { LocaleMoneyDisplayer } from '../../core/Accounting'

import * as s from './state'
import { Action, AddIcon } from './Action'
import * as S from './styles'
import Tag from '../Tag'
import { VariantList } from './VariantList'
import { VariantSelect } from './VariantSelect'
import { Variant } from './Variant'
import * as behaviours from '@behaviours/index'

const StyledAction = styled(Action)`
  &:hover,
  &:active,
  &:focus {
    outline: none;
  }
`

const RewardWrapper = styled.section`
  padding: 5%;
  position: relative;
  max-width: 100%;
  word-wrap: break-word;
  min-width: 300px;
  border-width: ${({ state }) => (s.isSelected(state) ? '2px' : '1px')};
  border-top-width: 0;
  border-style: solid;
  box-sizing: border-box;
  border-radius: 2px;
  font-family: ${fonts.PRIMARY_FONT};
  font-weight: 500;

  /* stylelint-disable-next-line */
  cursor: ${({ state }) => (!s.isDisabled(state) && !s.hasVariants(state) ? 'pointer' : 'default')};

  /* stylelint-disable-next-line */
  border-color: ${({ state }) => {
    if (s.isDisabled(state)) {
      return colors.GREY_SHADE_6
    }
    if (s.isSelected(state)) {
      return hexToRgb(colors.PRIMARY_BLUE, 0.5)
    }
    if (!s.isSelected(state)) {
      return colors.GREY_SHADE_5
    }
  }};

  &:first-child {
    border-top-width: ${({ state }) => (s.isSelected(state) ? '2px' : '1px')};
    /* TODO : DECOMMENT THIS FIX WHEN NEW PAGE PROJECT IS READY */
    /* THIS FIX MAKE REGRESSION ON THE OLD PAGE PROJET */
    /* margin-top: ${({ state }) => (s.isLarge(state) ? '0px' : '-1px')}; */
    margin-top: ${({ isNewProject }) => (isNewProject ? '-1px' : '0px')};
  }

  &:hover {
    border-top-width: ${({ state }) => (s.isSelected(state) ? '2px' : '1px')};
    margin-top: ${({ state }) => (s.isLarge(state) ? '0px' : '-1px')};

    /* stylelint-disable-next-line */
    border-color: ${({ state }) =>
      !s.isDisabled(state) && !s.hasVariants(state) ? hexToRgb(colors.PRIMARY_BLUE, 0.5) : 'inherits'};

    ${StyledAction} {
      ${AddIcon} {
        color: ${colors.PRIMARY_BLUE};
      }
    }

    &:first-child {
      margin-top: ${({ isNewProject }) => (isNewProject ? '-1px' : '0px')};
    }
  }

  @media screen and ${breakpoints.TABLET} {
    padding: ${({ state }) => (s.isLarge(state) ? '3%' : '5%')};
  }
`

const RewardWrapperOpacity = styled.div`
  opacity: ${({ state }) => (s.isDisabled(state) ? 0.5 : 1)};
`
const PreviewLabel = styled.div`
  font-size: 10px;
  line-height: 16px;
  text-transform: uppercase;
  color: ${colors.GREY_SHADE_3};
  margin-bottom: 15px;
`
const RewardHeaderBar = styled.div`
  margin: 0 0 20px;
  display: flex;
  flex-direction: row;
  align-items: baseline;
  justify-content: space-between;
`

const Price = styled.span`
  color: ${({ state }) => (s.isDisabled(state) ? colors.GREY_SHADE_3 : colors.PRIMARY_BLUE)};
  font-size: 19px;
  line-height: 23px;
`

const OriginalPrice = styled.span`
  margin-right: ${({ withMargin }) => (withMargin ? '5px' : '0px')};
`

const ConversionWrapper = styled.span`
  color: ${colors.GREY_SHADE_2};
  display: inline-block;
  font-size: 11px;
  line-height: 16px;
`

const ConversionIcon = styled(Icon)`
  top: 2px;
  margin-right: 4px;
  color: ${colors.GREY_SHADE_4};
`

const Illustration = styled.img`
  opacity: ${({ state }) => (s.isDisabled(state) ? 0.5 : 1)};
  max-width: 100%;

  @media screen and ${breakpoints.TABLET} {
    max-width: ${({ state }) => (s.isLarge(state) ? '70%' : '100%')};
  }
`

const Title = styled.h1`
  color: ${({ state }) => (s.isDisabled(state) ? colors.GREY_SHADE_3 : colors.PRIMARY_BLACK)};
  font-size: 15px;
  line-height: 21px;
  font-weight: 500;
  margin: 20px 0 0;
`

const Description = styled.div`
  color: ${({ state }) => (s.isDisabled(state) ? colors.GREY_SHADE_3 : colors.PRIMARY_BLACK)};
  font-size: 15px;
  line-height: 21px;
  font-weight: 300;

  ul {
    padding-left: 40px;
  }

  ul,
  ol {
    margin: 1em 0;
    padding-left: 40px;

    li {
      line-height: 1.618;
    }

    ul,
    ol {
      margin: 0;
    }
  }

  strong {
    font-weight: bold;
  }

  & img {
    opacity: ${({ state }) => (s.isDisabled(state) ? 0.5 : 1)};
    max-width: 100%;
  }
`

const Footer = styled.footer`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 20px 0 0;
`

const Delivery = styled.span`
  display: flex;
  flex-direction: row;
  align-items: center;
`

const DeliveryLabel = styled.time`
  color: ${colors.GREY_SHADE_3};
  font-size: 10px;
  line-height: 16px;
  margin-left: 9px;
  text-transform: uppercase;
`

const CalendarIcon = styled(Icon).attrs(props => ({ ...props, glyph: 'calendar', size: 21 }))`
  color: ${colors.GREY_SHADE_4};
  top: -1px;
`

const SoldOut = styled.span`
  display: block;
`

const SoonSoldOut = styled(SoldOut)`
  color: ${colors.PRIMARY_BLUE};
`

const TagWrapper = styled.div`
  position: absolute;
  left: 14px;
  top: -13px;

  span {
    text-transform: uppercase;
  }
`

const getCurrencyIconName = currency => {
  let iconName
  if (currency === 'EUR') {
    iconName = 'euro'
  } else if (currency === 'CHF') {
    iconName = 'chf'
  } else if (currency === 'DKK' || currency === 'NOK' || currency === 'SEK') {
    iconName = 'kr'
  } else if (currency === 'GBP') {
    iconName = 'pound'
  } else {
    iconName = 'dolls'
  }
  return iconName
}

export const RewardCard = ({
  loading = 'eager',
  nativeLazyLoad = false,
  targetCurrency,
  targetLanguage,
  rates,
  reward,
  translations,
  mode,
  isSelected = false,
  isLarge = false,
  handlers,
  children,
  className,
  isProjectFinished,
  conversionCurrency,
  conversionLanguage,
  isNewProject = false,
}) => {
  const variants = React.Children.toArray(children)
  const isDropdown =
    variants.length > 5 && variants.every(variant => typeof variant.props.variant.description === 'string')
  const wiredVariants = variants.map(elt => React.cloneElement(elt, { mode, isDropdown, isProjectFinished }))
  const precision = Number.isInteger(parseFloat(reward && reward.price)) ? 0 : 2

  const state = s.rewardState(
    reward,
    variants.map(variant => variant.props.variant),
    mode,
    isSelected,
    isProjectFinished,
    isLarge,
  )

  let status = reward.stock !== null && reward.stock.taken ? translations.supporters : ''
  if (!variants.length) {
    if (s.isDisabled(state)) {
      status = translations.supporters
    } else if (reward.stock.total) {
      status = reward.stock.taken ? `${translations.supporters} / ${reward.stock.total}` : translations.limitedAvailable
    }
  }

  const onClick = () => {
    if (s.isPreview(state) || s.hasVariants(state)) {
      return
    }
    if (s.isProjectFinished(state) || s.isProject(state)) {
      handlers.onClick && handlers.onClick()
    } else if (s.isSelected(state)) {
      handlers.onRemove && handlers.onRemove()
    } else if (!s.isSelected(state) && !s.isDisabled(state)) {
      handlers.onAdd && handlers.onAdd()
    }
  }

  const conversionNeeded = conversionCurrency !== undefined
  const currencyIconName = getCurrencyIconName(conversionCurrency)

  // TODO: remove isNewProject upon disabling legacy project page
  return (
    <RewardWrapper className={className} onClick={onClick} state={state} isNewProject={isNewProject}>
      {reward.isHidden && (state.isLarge || isNewProject) && (
        <TagWrapper>
          <Tag backgroundColor={colors.PRIMARY_GREEN} textColor={colors.PRIMARY_WHITE} size={10}>
            {translations.hiddenReward}
          </Tag>
        </TagWrapper>
      )}
      <RewardWrapperOpacity state={state}>
        <header>
          {s.isPreview(state) && <PreviewLabel>{translations.preview}</PreviewLabel>}
          <RewardHeaderBar>
            <Price state={state}>
              <OriginalPrice withMargin={conversionNeeded}>
                {translations.pricePrefix}{' '}
                <LocaleMoneyDisplayer
                  precision={precision}
                  rates={rates}
                  amount={parseFloat(reward.price)}
                  currency={reward.currency}
                  targetCurrency={targetCurrency}
                  localeFormat={targetLanguage}
                />
                {'\u00A0'}
                {translations.priceSuffix}
              </OriginalPrice>
              {conversionNeeded && (
                <ConversionWrapper>
                  <ConversionIcon size={14} glyph={`currency-${currencyIconName}`} />
                  ~
                  <LocaleMoneyDisplayer
                    precision={precision}
                    rates={rates}
                    amount={parseFloat(reward.price)}
                    currency={reward.currency}
                    targetCurrency={conversionCurrency}
                    localeFormat={conversionLanguage}
                  />
                </ConversionWrapper>
              )}
            </Price>

            {!s.hasVariants(state) && <StyledAction state={state} translations={translations} handlers={handlers} />}
          </RewardHeaderBar>
          {reward.picture &&
            (loading === 'lazy' ? (
              <behaviours.LazyImage nativeLazyLoad={nativeLazyLoad}>
                <Illustration state={state} src={reward.picture} />
              </behaviours.LazyImage>
            ) : (
              <Illustration state={state} src={reward.picture} />
            ))}
          {reward.title && <Title state={state}>{reward.title}</Title>}
        </header>

        <Description state={state} dangerouslySetInnerHTML={{ __html: reward.description }} />

        {s.hasVariants(state) &&
          (isDropdown ? (
            <VariantSelect multiple={!s.isProject(state) && !s.isPreview(state)} placeholder={translations.choose}>
              {wiredVariants}
            </VariantSelect>
          ) : (
            <VariantList>{wiredVariants}</VariantList>
          ))}

        <Footer>
          {reward.formattedDate ? (
            <Delivery>
              <CalendarIcon />
              <DeliveryLabel>{reward.formattedDate}</DeliveryLabel>
            </Delivery>
          ) : (
            <span />
          )}
          <S.Status>
            {status}
            {!s.isProjectFinished(state) && s.isSoonSoldOut(state) && (
              <SoonSoldOut>{translations.soonSoldOut}</SoonSoldOut>
            )}
            {!s.isProjectFinished(state) && s.isDisabled(state) && <SoldOut>{translations.soldOut}</SoldOut>}
          </S.Status>
        </Footer>
      </RewardWrapperOpacity>
    </RewardWrapper>
  )
}

RewardCard.propTypes = {
  /** currency to display prices in */
  targetCurrency: PropTypes.string.isRequired,
  /** language to display prices in */
  targetLanguage: PropTypes.string.isRequired,
  /** rates for currency conversion */
  rates: PropTypes.object,
  /** reward description */
  reward: PropTypes.shape({
    /** currency of the price of the reward */
    currency: PropTypes.string.isRequired,
    /** description of the reward (can contain html) */
    description: PropTypes.string,
    /** reward formatted date */
    formattedDate: PropTypes.string.isRequired,
    /** whether or not the reward is hidden */
    isHidden: PropTypes.bool.isRequired,
    /** url of the illustration of the reward */
    picture: PropTypes.string,
    /** price of the reward */
    price: PropTypes.string.isRequired,
    /** if defined, tells how much of the reward is left  */
    stock: PropTypes.shape({
      /** total stock of the reward */
      total: PropTypes.number,
      /** amount already taken */
      taken: PropTypes.number.isRequired,
      /** amount available */
      available: PropTypes.number,
    }).isRequired,
    /** title of the reward */
    title: PropTypes.string,
  }).isRequired,
  translations: PropTypes.shape({
    add: PropTypes.string.isRequired,
    choose: PropTypes.string.isRequired,
    hiddenReward: PropTypes.string.isRequired,
    limitedAvailable: PropTypes.string.isRequired,
    preview: PropTypes.string.isRequired,
    pricePrefix: PropTypes.string.isRequired,
    priceSuffix: PropTypes.string,
    remove: PropTypes.string.isRequired,
    soldOut: PropTypes.string.isRequired,
    soonSoldOut: PropTypes.string.isRequired,
    supporters: PropTypes.string.isRequired,
  }).isRequired,
  /** mode of the Reward Card */
  mode: PropTypes.oneOf(['project', 'preview', 'checkout']).isRequired,
  /** wether the whole card is selected or not */
  isSelected: PropTypes.bool,
  isNewProject: PropTypes.bool,
  /** either handle click (mode project) or add/remove (mode checkout) */
  handlers: PropTypes.oneOfType([
    PropTypes.shape({
      onClick: PropTypes.func.isRequired,
    }),
    PropTypes.shape({
      onAdd: PropTypes.func.isRequired,
      onRemove: PropTypes.func.isRequired,
    }),
  ]),
  /** whether the project is online or not */
  isProjectFinished: PropTypes.bool,
  isLarge: PropTypes.bool,
  children: (props, propName, componentName) => {
    const isVariant = props[propName].every(prop => {
      return prop.type && prop.type === Variant
    })
    if (props[propName] && props[propName].length !== 0 && !isVariant) {
      return new Error(
        `Invalid prop '${propName}' supplied to '${componentName}'. Only <Variant> component can be children of <Reward>.`,
      )
    }
  },
  className: PropTypes.string,
  conversionCurrency: PropTypes.any,
  conversionLanguage: PropTypes.any,
  loading: PropTypes.oneOf(['eager', 'lazy']),
  nativeLazyLoad: PropTypes.bool,
}
