/* eslint-disable react/prop-types */
import * as React from 'react'
import styled, { css } from 'styled-components'

import * as behaviour from '../../../behaviours/index'
import * as colors from '@colors'
import * as fonts from '@fonts'

export const OptionWrapper = styled.div`
  display: flex;
  align-items: center;
  user-select: none;
  width: 100%;
`

const OptionLabel = styled.div`
  font-family: ${fonts.PRIMARY_FONT};
  font-size: 13px;
  line-height: 18px;
  width: 100%;
  ${({ status }) => {
    if (status === 'disabled') {
      return css`
        colors: ${colors.GREY_SHADE_4};
      `
    }
    if (status === 'selected') {
      return css`
        colors: ${colors.PRIMARY_BLUE};
      `
    }
    if (status === 'default') {
      return css`
        colors: ${colors.PRIMARY_BLACK};
      `
    }
  }}
`

export function Option({ disabled, label, value, onToggle, children, className }) {
  return (
    <behaviour.Option className={className} value={value} label={label} disabled={disabled} onToggle={onToggle}>
      {({ isSelected, isFocused, toggle, focus }) => {
        let status = 'default'
        if (isSelected) {
          status = 'selected'
        }
        if (disabled) {
          status = 'disabled'
        }
        return (
          <OptionWrapper
            focused={isFocused}
            onMouseMove={focus}
            onFocus={focus}
            onClick={toggle}
            onTouchStart={evt => {
              evt.preventDefault()
              toggle()
            }}
            tabIndex={-1}
          >
            <OptionLabel status={status}>{children}</OptionLabel>
          </OptionWrapper>
        )
      }}
    </behaviour.Option>
  )
}
