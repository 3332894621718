import styled from 'styled-components'

import * as bp from '@bp'
import * as colors from '@colors'
import Overlay from '@legacy/Overlay'
import Icon from '@legacy/Icon'
import { ImportantText } from '@legacy/Text'

export const CloseIcon = styled(Icon)`
  cursor: pointer;

  svg {
    color: ${colors.GREY_SHADE_4};
  }
`

export const ModalOverlay = styled(Overlay)`
  cursor: pointer;
`

export const ModalWrapper = styled.div`
  display: ${props => (props.isOpen ? 'flex' : 'none')};
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 9999; /* HACK: it should not be defined by design system */
`

const ModalContainer = styled.div`
  background-color: ${colors.PRIMARY_WHITE};
  display: flex;
  flex-direction: column;

  @media screen and ${bp.TABLET} {
    border: 1px solid ${colors.GREY_SHADE_5};
  }
`

export const ModalCloseZone = styled.div`
  flex: 0 0 50px;
  background-color: ${props => colors.primary(props)};
  width: 100%;
  height: 50px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 0 16px;
  cursor: pointer;

  ${CloseIcon /* sc-sel */} {
    color: ${colors.PRIMARY_WHITE};
  }

  @media screen and ${bp.TABLET} {
    background-color: transparent;
    position: absolute;
    top: 16px;
    right: 16px;
    display: inline-flex;
    width: auto;
    height: auto;
    padding: 0;

    ${CloseIcon /* sc-sel */} {
      color: ${colors.GREY_SHADE_3};

      :hover {
        color: ${colors.GREY_SHADE_4};
      }
    }
  }
`

export const ModalContent = styled.div`
  padding: 16px;

  @media screen and ${bp.TABLET} {
    padding-top: 48px;
  }
`

export const ClassicModalContainer = styled(ModalContainer)`
  position: relative;
  max-width: 100vw;
  width: 100%;
  height: 100vh;

  @media screen and ${bp.TABLET} {
    max-width: 540px;
    height: auto;
  }
`

export const NotificationModalContainer = styled(ModalContainer)`
  position: relative;
  max-width: calc(100vw - 40px);
  width: auto;
  height: auto;
  border: 1px solid ${colors.GREY_SHADE_5};

  ${CloseIcon /* sc-sel */} {
    position: absolute;
    top: 16px;
    right: 16px;
    color: ${colors.GREY_SHADE_3};

    :hover {
      color: ${colors.GREY_SHADE_4};
    }
  }
`

export const DialogContainer = styled(ModalContainer)`
  position: fixed;
  box-shadow: #0008 0 2px 3px;
  border-bottom-left-radius: 2px;
  border-bottom-right-radius: 2px;
  top: ${props => props.top};
  right: 10px;
  bottom: ${props => props.bottom};
  left: 10px;
  max-height: calc(100% - 75px);

  @media screen and ${bp.TABLET} {
    border: 0;
    width: 300px;
    left: ${props => props.left};
    right: ${props => props.right};
  }
`

export const DialogTitleBar = styled.div`
  background-color: ${props => props.color || colors.PRIMARY_BLUE};
  min-height: 48px;
  position: relative;
  display: flex;
  align-items: center;

  ${CloseIcon} {
    margin-right: 13px;

    svg {
      color: ${colors.PRIMARY_WHITE};
    }
  }
`

export const DialogTitle = styled.div`
  flex: 1;
  text-align: center;
  padding: 13px 10px;
  margin-left: 27px;

  ${ImportantText} {
    color: ${colors.PRIMARY_WHITE};
    font-size: 13px;
  }
`

export const DialogContent = styled.div`
  padding: 15px 25px;
  overflow: auto;
`
