import * as React from 'react'
import * as PropTypes from 'prop-types'

import * as S from './styles'
import { Detail } from './Label'

/** Select component used to select from multiple choices. */
class Select extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      value: props.initialValue || '',
    }
  }

  handleChange = event => {
    const { onChange, onChangeEvent } = this.props

    if (onChangeEvent && typeof onChangeEvent === 'function') {
      onChangeEvent(event)
    }

    this.setState(
      {
        value: event.currentTarget.value,
      },
      () => {
        if (onChange && typeof onChange === 'function') {
          onChange(this.state.value)
        }
      },
    )
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.value !== undefined && this.props.value !== prevState.value) {
      this.setState({ value: this.props.value })
    }
  }

  render() {
    const {
      label,
      description,
      options,
      placeholder,
      error,
      name,
      success,
      initialValue, // eslint-disable-line
      onChange, // eslint-disable-line
      value: valueProp, // eslint-disable-line
      ...props
    } = this.props

    return (
      <S.SelectContainer>
        {label && <S.SelectLabel htmlFor={name}>{label}</S.SelectLabel>}
        {description && <S.SelectDescription>{description}</S.SelectDescription>}
        <S.Select
          placeholder={placeholder}
          name={name}
          id={name}
          onChange={this.handleChange}
          error={error}
          value={this.state.value}
          {...props}
        >
          {<S.SelectOption value="">{placeholder}</S.SelectOption>}
          {options &&
            options.length &&
            options.length > 0 &&
            options.map((option, index) => (
              <S.SelectOption key={index} value={option && option.value}>
                {option && option.content}
              </S.SelectOption>
            ))}
        </S.Select>
        {error && !success && <Detail error={!!error}>{error}</Detail>}
        {success && !error && <Detail success={!!success}>{success}</Detail>}
      </S.SelectContainer>
    )
  }
}

Select.displayName = 'legacy.Select'

Select.defaultProps = {
  options: [],
}

Select.propTypes = {
  /** name attribute of input element */
  name: PropTypes.string.isRequired,
  /** used for prefetched data */
  initialValue: PropTypes.string,
  /** used to update the selected option from outside */
  value: PropTypes.string,
  /** default displayed text */
  placeholder: PropTypes.string,
  /** label for radio button */
  label: PropTypes.string,
  /** purpose of this element */
  description: PropTypes.string,
  /** mandatory function to retrieve value */
  onChange: PropTypes.func.isRequired,
  /** function to retrieve change event */
  onChangeEvent: PropTypes.func,
  /** success message */
  success: PropTypes.string,
  /** error message */
  error: PropTypes.string,
  /** browser will display an error if input is empty on form submitting */
  required: PropTypes.bool,
  /** disable the option */
  disabled: PropTypes.bool,
  /** collection of options related to the select */
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      content: PropTypes.string,
    }),
  ),
}

export default Select
