import * as PropTypes from 'prop-types'
import styled from 'styled-components'

import { BasicLink } from '@legacy/Links'

import * as fonts from '@fonts'
import * as colors from '@colors'

export const RegularText = styled.span`
  font-family: ${fonts.PRIMARY_FONT};
  font-size: 15px;
  font-weight: 400;
  color: ${colors.PRIMARY_BLACK};
  line-height: 22px;

  ${BasicLink /* sc-sel */} {
    font-size: 15px;
    font-weight: 500;
  }
`

RegularText.propTypes = {
  as: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
}

export const ImportantText = styled(RegularText)`
  font-weight: 500;
`

ImportantText.propTypes = {
  as: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
}

export const DiscreteText = styled(RegularText)`
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;

  ${BasicLink /* sc-sel */} {
    font-size: 12px;
  }
`

DiscreteText.propTypes = {
  as: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
}

export const BoringText = styled(RegularText)`
  color: ${colors.GREY_SHADE_2};
  font-size: 12px;
  font-weight: 300;
  line-height: 18px;

  ${BasicLink /* sc-sel */} {
    font-size: 12px;
  }
`

BoringText.propTypes = {
  as: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
}
