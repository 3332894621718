import * as React from 'react'
import * as PropTypes from 'prop-types'
import styled, { keyframes, css } from 'styled-components'

import * as bp from '@bp'
import * as fonts from '@fonts'
import * as colors from '@colors'

import Icon from './Icon'

const bounce = keyframes`
  30% {
    transform: scale(1.4);
  }

  50% {
    transform: scale(0.8);
  }

  100% {
    transform: scale(1);
  }
`

const debounce = keyframes`
  30% {
    transform: scale(0.8);
  }

  50% {
    transform: scale(0.7);
  }

  100% {
    transform: scale(1);
  }
`

const IconWrapper = styled.span`
  display: inline-flex;
`

const Label = styled.span`
  display: inline-flex;
  font-family: ${fonts.PRIMARY_FONT};
  font-size: 12px;
  color: ${props => (props.textColor !== undefined ? props.textColor : colors.GREY_SHADE_3)};
  font-weight: 500;
`

const LabelNormal = styled.span``
const LabelHover = styled.span``

const InactiveHeart = styled(Icon).attrs({
  glyph: 'heart-stroke',
  size: 18,
})`
  animation: ${debounce} cubic-bezier(0.04, 0.4, 0.5, 0.95) 0.8s forwards 1;
`

const ActiveHeart = styled(Icon).attrs({
  glyph: 'heart',
  size: 18,
})`
  animation: ${bounce} cubic-bezier(0.04, 0.4, 0.5, 0.95) 1.2s forwards 1;

  svg {
    fill: ${props => colors.primary(props)};
  }
`

const BackedIcon = styled(Icon).attrs({
  glyph: 'check-circle',
  size: 18,
})`
  svg {
    fill: ${colors.PRIMARY_GREEN};
  }
`

const CreatedIcon = styled(Icon).attrs({
  glyph: 'star-circle',
  size: 18,
})`
  svg {
    fill: ${colors.SECONDARY_YELLOW};
  }
`

const Wrapper = styled.div`
  position: absolute;
  top: 0;
`

const Button = styled.div`
  /* stylelint-disable-next-line */
  background-color: ${props => (props.backgroundColor !== undefined ? props.backgroundColor : colors.PRIMARY_WHITE)};
  display: flex;
  flex-direction: row;
  position: relative;
  align-items: center;
  justify-content: ${props => (!props.isCompact ? 'center' : 'flex-end')};;
  width: ${props => (!props.isCompact ? '100%' : '32px')};
  max-width: ${props => (!props.isCompact ? 'none' : '32px')};
  padding: 7px;
  padding-left: ${props => (!props.isCompact ? '10px' : 0)};
  height: 32px;
  min-width: 32px;
  min-height: 32px;
  border-radius: 16px;
  box-shadow: 0 1px 1px 1px rgba(224, 224, 224, 0.5);
  overflow: hidden;
  transition: max-width 200ms ease-in-out;
  cursor: pointer;
  user-select: none;
  color: ${colors.GREY_SHADE_4};
  box-sizing: border-box;

  ${LabelHover} {
    display: none;
  }

  &:hover {
    /* stylelint-disable-next-line */
    background-color: ${props =>
      props.backgroundColorHover !== undefined ? props.backgroundColorHover : colors.PRIMARY_WHITE};

    ${Label} {
      /* stylelint-disable-next-line */
      color: ${props => (props.textColorHover !== undefined ? props.textColorHover : colors.GREY_SHADE_3)};
    }

    ${props =>
      props.iconColorHover &&
      css`
        ${IconWrapper} {
          color: ${props.iconColorHover};
        }
      `}

    ${props =>
      props.hideIconHover &&
      css`
        ${IconWrapper} {
          display: none;
        }
      `}

    ${props =>
      props.textHover &&
      `
      ${LabelHover} {
        display: inline-block;
      }
      ${LabelNormal} {
        display: none;
      }
    `}
  }

  /* stylelint-disable-next-line */
  ${Label /* sc-sel */} {
    opacity: ${props => (!props.isCompact ? 1 : 0)};
    margin-right: 5px;
  }

  @media screen and (max-width: 425px) {
    &:hover {
      outline: 2px solid Highlight;
    }
  }

  @media screen and ${bp.TABLET} {
    /* stylelint-disable-next-line */
    &:hover {
      width: 100%;
      max-width: ${props => (!props.isCompact ? 'none' : '200px')};
      padding-left: 10px;
    }

    /* stylelint-disable-next-line */
    &:hover ${Label /* sc-sel */} {
      opacity: 1;
      transition: opacity 100ms ease-in;
      transition-delay: 200ms;
    }
  }
`

const getIcon = icon => {
  switch (icon) {
    case 'backed':
      return <BackedIcon />

    case 'created':
      return <CreatedIcon />

    case 'inactive-heart':
      return <InactiveHeart />

    case 'active-heart':
      return <ActiveHeart />

    default:
      return <InactiveHeart />
  }
}

const RoundButton = ({
  icon,
  iconColorHover,
  hideIconHover,
  text,
  textHover,
  textColor,
  textColorHover,
  backgroundColor,
  backgroundColorHover,
  onClick,
  isCompact,
  className,
}) => {
  const icn = getIcon(icon)
  return (
    <Wrapper className={className}>
      <Button
        onClick={onClick}
        isCompact={isCompact}
        backgroundColor={backgroundColor}
        backgroundColorHover={backgroundColorHover}
        textColorHover={textColorHover}
        textHover={textHover}
        hideIconHover={hideIconHover}
        iconColorHover={iconColorHover}
      >
        <Label textColor={textColor}>
          <LabelNormal>{text}</LabelNormal>
          <LabelHover>{textHover}</LabelHover>
        </Label>
        <IconWrapper>{icn}</IconWrapper>
      </Button>
    </Wrapper>
  )
}

RoundButton.propTypes = {
  className: PropTypes.string,
  /** Select the icon of the button */
  icon: PropTypes.oneOf(['inactive-heart', 'active-heart', 'created', 'backed']).isRequired,
  /** color of the icon on hover state */
  iconColorHover: PropTypes.string,
  /** hide the icon during the hover if set to true */
  hideIconHover: PropTypes.bool,
  /** string value representing the button text */
  text: PropTypes.string.isRequired,
  /** string value representing the button text on hover state */
  textHover: PropTypes.string,
  /** color of the text */
  textColor: PropTypes.string,
  /** color of the text on hover state */
  textColorHover: PropTypes.string,
  /** color of the background */
  backgroundColor: PropTypes.string,
  /** color of the background on hover state */
  backgroundColorHover: PropTypes.string,
  /** Show the text only during the hover if set to true */
  isCompact: PropTypes.bool,
  /** trigger when action button is clicked */
  onClick: PropTypes.func,
}

export default RoundButton
