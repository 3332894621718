import * as React from 'react'
import * as PropTypes from 'prop-types'
import styled from 'styled-components'

const LabelWithIconContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  white-space: ${props => (props.inline ? 'nowrap' : 'normal')};
  overflow: ${props => (props.inline ? 'hidden' : 'visible')};

  > div:first-child {
    margin-right: 10px;
  }
`

const LabelWithIcon = ({ icon, label, inline }) => (
  <LabelWithIconContainer inline={inline}>
    {icon}
    {label}
  </LabelWithIconContainer>
)

LabelWithIcon.propTypes = {
  icon: PropTypes.any.isRequired,
  label: PropTypes.any.isRequired,
  inline: PropTypes.bool,
}

export default LabelWithIcon
