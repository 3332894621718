import * as React from 'react'
import * as PropTypes from 'prop-types'
import styled from 'styled-components'

const JewelWrapper = styled.span`
  display: flex;
  align-items: center;
`

const JewelBullet = styled.sup`
  color: #ff4336;
  display: inline-flex;
  justify-content: center;
  align-items: baseline;
  flex-shrink: 0;
  margin-left: 6px;
  font-size: 11px;
  font-weight: 500;

  &::before {
    background-color: #ff4336;
    border-radius: 6px;
    content: '';
    margin-right: 3px;
    min-height: 6px;
    min-width: 6px;
  }
`

const Jewel = ({ number, children }) => (
  <JewelWrapper>
    {children}
    <JewelBullet>{number}</JewelBullet>
  </JewelWrapper>
)

Jewel.propTypes = {
  number: PropTypes.number.isRequired,
  children: PropTypes.any.isRequired,
}

export default Jewel
