import styled from 'styled-components'
import { Message } from './field'
import * as colors from '@colors'

const colorByScore = {
  worst: colors.SECONDARY_RED,
  bad: colors.SECONDARY_RED,
  weak: colors.SECONDARY_PURPLE,
  good: colors.SECONDARY_PURPLE,
  strong: colors.PRIMARY_BLUE,
}

export const Indicator = styled(Message)`
  color: ${colors.GREY_SHADE_1};
`

export const Strength = styled.span<{ score: keyof typeof colorByScore }>`
  color: ${props => colorByScore[props.score]};
`
