import styled, { css, keyframes } from 'styled-components'

import * as colors from '@colors'
import * as fonts from '@fonts'
import { Icon } from '@glyphs/index'

const bounce = keyframes`
  30% {
    transform: scale(1.4);
  }

  50% {
    transform: scale(0.8);
  }

  100% {
    transform: scale(1);
  }
`

const debounce = keyframes`
  30% {
    transform: scale(0.8);
  }

  50% {
    transform: scale(0.7);
  }

  100% {
    transform: scale(1);
  }
`

export const Base = styled.button`
  align-items: center;
  border-radius: 2px;
  border: none;
  box-sizing: border-box;
  cursor: pointer;
  display: inline-flex;
  font-family: ${fonts.PRIMARY_FONT};
  font-weight: 900;
  height: 50px;
  justify-content: center;
  outline: none;
  padding: 10px 40px;
  text-align: center;
  text-transform: uppercase;
  transition: 0.3s ease;
  text-decoration: none;

  &::-moz-focus-inner {
    border: 0;
  }

  &:focus {
    outline: none;
  }
`

const smallButtonCss = css`
  font-size: 13px;
  line-height: 18px;
`

const mediumButtonCss = css`
  font-size: 17px;
  line-height: 18px;
`

export const buttonColors = {
  black: { base: colors.PRIMARY_BLACK, hover: colors.PRIMARY_BLACK_HOVER, hoverOutlined: colors.PRIMARY_BLACK },
  blue: { base: colors.PRIMARY_BLUE, hover: colors.PRIMARY_BLUE_HOVER, hoverOutlined: colors.PRIMARY_BLUE },
  green: { base: colors.PRIMARY_GREEN, hover: colors.PRIMARY_GREEN_HOVER, hoverOutlined: colors.PRIMARY_GREEN },
  grey: { base: colors.GREY_SHADE_4, text: colors.PRIMARY_WHITE },
  red: { base: colors.PRIMARY_RED, hover: colors.PRIMARY_RED_HOVER, hoverOutlined: colors.PRIMARY_RED },
  white: { base: colors.PRIMARY_WHITE, hover: colors.PRIMARY_WHITE, hoverOutlined: colors.PRIMARY_BLUE },
}

export type ButtonProps = {
  /** @default false */
  loading?: boolean
  /** @default 'primary */
  kind?: 'primary' | 'secondary'
  /** @default 'small */
  size?: 'small' | 'medium'
  /** @default 'blue */
  color?: 'black' | 'blue' | 'green' | 'red' | 'white'
}

export const Button = styled(Base)<ButtonProps>`
  line-height: 18px;

  &:disabled {
    ${({ loading }) =>
      !loading &&
      css`
        background-color: ${buttonColors['grey'].base};
        border: 2px solid ${buttonColors['grey'].base};
        color: ${buttonColors['grey'].text};
        pointer-events: none;
        cursor: not-allowed;
      `}

  }

  ${({ size = 'small' }) => {
    if (size === 'medium') {
      return mediumButtonCss
    }
    return smallButtonCss
  }}

  ${({ loading }) =>
    loading &&
    css`
      cursor: wait;
    `}

  ${({ kind = 'primary', color = 'blue', loading = false }) => {
    if (kind === 'secondary') {
      return css`
        background-color: transparent;
        border: 2px solid ${buttonColors[color].base};
        color: ${buttonColors[color].base};

        ${!loading &&
          css`
            &:hover {
              border: 2px solid ${buttonColors[color].hoverOutlined};
              background-color: ${buttonColors[color].hoverOutlined};
              color: ${colors.PRIMARY_WHITE};
            }

            &:disabled {
              border: 2px solid ${colors.GREY_SHADE_4};
              color: ${colors.GREY_SHADE_4};
              cursor: default;
            }
          `}
      `
    }
    return css`
      background-color: ${buttonColors[color].base};
      color: ${colors.PRIMARY_WHITE};

      ${!loading &&
        css`
          &:hover {
            background-color: ${buttonColors[color].hover};
          }

          &:disabled {
            background-color: ${colors.GREY_SHADE_4};
            cursor: default;
          }
        `}
    `
  }}}
`
Button.displayName = 'S.button.Button'

type CallToActionProps = {
  /** @default false */
  onlyGlyph?: boolean
} & ButtonProps

export const CallToAction = styled(Button)<CallToActionProps>`
  width: 100%;

  ${Icon} {
    height: 22px;
    ${({ onlyGlyph = false }) =>
      !onlyGlyph &&
      css`
        margin-right: 10px;
      `};
    width: 22px;
  }
`
CallToAction.displayName = 'S.button.CallToAction'

/** @deprecated use the `LinkButton` component instead */
export const TextButton = styled.button`
  cursor: pointer;
  background: transparent;
  border: none;
  color: ${colors.GREY_SHADE_3};
  font-family: ${fonts.PRIMARY_FONT};
  font-size: 13px;
  font-weight: 500;
  line-height: 18px;
  margin: 0;
  padding: 13px;
  width: 100%;

  &:hover {
    text-decoration: underline;
  }
`
TextButton.displayName = 'S.button.TextButton'

export const RoundButton = styled(Base)<{ active?: boolean; hover?: boolean }>`
  height: 32px;
  padding: 0 15px;
  border-radius: 19px;
  ${smallButtonCss};
  box-shadow: 0 1px 2px 1px rgba(35, 34, 33, 0.1);
  color: ${colors.GREY_SHADE_1};
  background-color: ${colors.PRIMARY_WHITE};

  ${Icon} {
    height: 17px;
    width: 17px;
    margin: 0 0 0 6px;
    color: ${colors.GREY_SHADE_4};
    transition: 0.3s ease;
    animation: ${({ active }) => (active ? debounce : bounce)} cubic-bezier(0.04, 0.4, 0.5, 0.95) 0.8s forwards 1;
  }

  ${({ active, hover }) => {
    if (active && hover) {
      return css`
        color: ${colors.PRIMARY_WHITE};
        ${Icon} {
          color: ${colors.PRIMARY_WHITE};
        }
        background-color: ${colors.PRIMARY_RED};
      `
    } else if ((active && !hover) || (!active && hover)) {
      return css`
        ${Icon} {
          color: ${colors.PRIMARY_BLUE};
        }
      `
    }
  }}
`
RoundButton.displayName = 'S.button.RoundButton'
