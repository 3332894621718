import styled, { css } from 'styled-components'

import * as colors from '@colors'
import * as zindex from '@zindex'

export const BlockAnchor = styled.div`
  position: relative;
`

export const InlineAnchor = styled.span`
  position: relative;
`

type TooltipProps = {
  /** @default false */
  fitToContent?: boolean
  open?: boolean
  /** @default top */
  position?: 'bottom' | 'top' | 'right' | 'left'
}

export const Tooltip = styled.div<TooltipProps>`
  box-sizing: border-box;
  width: 226px;
  border: 1px solid ${colors.GREY_SHADE_5};
  align-items: center;
  background-color: ${colors.PRIMARY_WHITE};
  bottom: 30px;
  box-shadow: 1px 2px 3px 1px rgba(0, 0, 0, 0.12);
  box-sizing: border-box;
  left: 50%;
  right: auto;
  top: auto;
  padding: 9px;
  position: absolute;
  transform: translateX(-50%);
  height: min-content;
  z-index: ${zindex.ABOVE};

  ${({ position }) => {
    if (position === 'right') {
      return css`
        left: 100%;
        right: 0px;
        top: 50%;
        transform: translate(0%, -50%);
      `
    } else if (position === 'left') {
      return css`
        left: auto;
        right: 100%;
        top: 50%;
        transform: translate(0%, -50%);
      `
    } else if (position === 'bottom') {
      return css`
        bottom: 0px;
      `
    }
  }}
  ${({ fitToContent = false }) => {
    if (fitToContent) {
      return css`
        width: max-content;
      `
    }
    return css`
      width: 226px;
    `
  }}
  ${({ open }) => {
    if (open) {
      return css`
        display: inline-flex;
      `
    } else {
      return css`
        display: none;
      `
    }
  }}

  span,
  p {
    font-size: 13px;
    line-height: 22px;
  }

  &::after {
    content: '';
    display: inline-block;
    height: 12px;
    width: 12px;
    box-shadow: 3px 3px 3px 0 rgba(0, 0, 0, 0.12);
    transform: rotate(45deg) translateY(50%);
    position: absolute;
    bottom: -1px;
    top: auto;
    background-color: white;
    left: 50%;

    ${({ position }) => {
      if (position === 'right') {
        return css`
          bottom: auto;
          top: 50%;
          left: -9px;
          box-shadow: -1px 3px 2px 0 rgba(0, 0, 0, 0.12);
          transform: rotate(45deg) translateY(-50%);
        `
      } else if (position === 'left') {
        return css`
          bottom: auto;
          top: 50%;
          right: -2px;
          left: auto;
          box-shadow: 2px -2px 3px rgba(0, 0, 0, 0.12);
          transform: rotate(45deg) translateY(-50%);
        `
      } else if (position === 'bottom') {
        return css`
          bottom: auto;
          top: -10px;
          box-shadow: -1px -1px 2px 0 rgba(0, 0, 0, 0.12);
        `
      }
    }}
  }
`
Tooltip.displayName = 'S.tooltip.Tooltip'
