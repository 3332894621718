import * as React from 'react'
import * as PropTypes from 'prop-types'
import styled from 'styled-components'

import ProgressEgg from '../ProgressEgg'
import { LocaleMoneyDisplayer } from '../../core/Accounting'

const ProgressEggIcon = styled(ProgressEgg)`
  margin-right: 2px;
`

const LabelWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  white-space: normal;
  overflow: visible;
`

const GoalLabel = ({
  type,
  progress,
  raisedAmount,
  productsSoldCount,
  goal,
  currency,
  targetCurrency,
  language,
  rates,
}) => {
  const label =
    type === 'presale' ? (
      `${productsSoldCount} / ${goal}`
    ) : (
      <LocaleMoneyDisplayer
        amount={raisedAmount}
        currency={currency}
        targetCurrency={targetCurrency}
        localeFormat={language}
        rates={rates}
      />
    )

  return (
    <LabelWrapper>
      <ProgressEggIcon progress={progress} size={16} />
      {label}
    </LabelWrapper>
  )
}

GoalLabel.defaultProps = {
  goal: 0,
  raisedAmount: 0,
  progress: 0,
  productsSoldCount: 0,
}

GoalLabel.propTypes = {
  type: PropTypes.oneOf(['presale', 'project']).isRequired,
  progress: PropTypes.number,
  goal: PropTypes.string,
  raisedAmount: PropTypes.number,
  productsSoldCount: PropTypes.string,
  targetCurrency: PropTypes.string,
  currency: PropTypes.string.isRequired,
  language: PropTypes.string.isRequired,
  rates: PropTypes.object,
}

export default GoalLabel
