import * as React from 'react'
import * as PropTypes from 'prop-types'

import * as S from './styles'
import Avatar from '@legacy/Avatar'

const MAX_CHAR = 170

/** This component aims to serve Ulule business. It shows the support of a sponsor to a project. */
const SponsorshipBanner = ({ sponsor, link, description, mainColor }) => {
  const content = (
    <React.Fragment>
      <S.Sponsor mainColor={mainColor}>
        <Avatar src={sponsor.avatarUrl} alt={sponsor.name} size={44} border />
        {sponsor.name}
      </S.Sponsor>
      <S.Description mainColor={mainColor}>{description.slice(0, MAX_CHAR)}</S.Description>
    </React.Fragment>
  )

  if (link) {
    return (
      <S.Container href={link} target="_blank" rel="noopener" mainColor={mainColor}>
        {content}
      </S.Container>
    )
  }

  return <S.Container mainColor={mainColor}>{content}</S.Container>
}

SponsorshipBanner.propTypes = {
  sponsor: PropTypes.shape({
    avatarUrl: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  }).isRequired,
  /** url will open in new tab */
  link: PropTypes.string,
  /** should not more than 170 characters */
  description: PropTypes.string.isRequired,
  /** color of component */
  mainColor: PropTypes.string,
}

export default SponsorshipBanner
SponsorshipBanner.displayName = 'legacy.SponsorshipBanner'
