/* eslint-disable react/prop-types */
import * as React from 'react'
import styled, { css } from 'styled-components'

import { NavigableList, NavigableItem } from '../navigableList'

import { useSelect } from './useSelect'
import { selectContext, useSelectContext } from './context'

const SelectBox = styled.div`
  display: inline-flex;
  flex-direction: column;
`

const SelectButton = styled.button`
  text-align: left;
  display: inline-block;
  padding: 0;
  border: none;
  background: none;
`

const OptionDrawer = styled.div`
  position: relative;
  display: inline-block;
  height: 0;
  z-index: 10;
  visibility: ${({ active }) => (active ? 'initial' : 'hidden')};
  overflow: ${({ active }) => (active ? 'visible' : 'hidden')};
`

const OptionList = styled(NavigableList)`
  ${({ height }) =>
    height !== undefined &&
    css`
      max-height: ${height}px;
      overflow-y: auto;
    `}
`
export function Select(props) {
  // eslint-disable-next-line no-undef
  if (process.env.NODE_ENV !== 'production') {
    React.useEffect(() => {
      if (props.selected !== undefined && props.initialSelected !== undefined) {
        // eslint-disable-next-line no-console
        console.error(new Error('<Select> is both controlled and uncontrolled, use either selected or initialSelected'))
      }
    }, [])
  }

  if (props.selected !== undefined) {
    return <ControlledSelect {...props} />
  } else {
    return <UncontrolledSelect {...props} />
  }
}

function UncontrolledSelect({
  initialSelected = [],
  multiple,
  height,
  onChange,
  children,
  className,
  renderSelect,
  renderDrawer,
  onClose,
}) {
  const [selected, setSelected] = React.useState(multiple ? initialSelected : [initialSelected[0]])

  function handleChange(selected) {
    onChange && onChange(selected)
    setSelected(selected)
  }

  return (
    <ControlledSelect
      selected={selected}
      height={height}
      onChange={handleChange}
      className={className}
      multiple={multiple}
      onClose={onClose}
      renderDrawer={renderDrawer}
      renderSelect={renderSelect}
    >
      {children}
    </ControlledSelect>
  )
}

function ControlledSelect({
  className,
  multiple = false,
  selected = [],
  onClose = () => {},
  onChange = () => {},
  // eslint-disable-next-line react/display-name
  renderDrawer: Drawer = ({ children }) => <>{children}</>,
  renderSelect: Select = defaultRenderSelect,
  height,
  children,
}) {
  const ref = React.useRef(null)

  const { useOption, isOpen, open, close, selectedOptions, focused, focus, clear } = useSelect(
    ref,
    multiple ? selected : [selected[0]],
    onClose,
    onChange,
    multiple,
  )

  return (
    <SelectBox className={className} ref={ref}>
      <SelectButton onMouseDown={isOpen ? close : open} type="button">
        <Select selected={selectedOptions} isOpen={isOpen} />
      </SelectButton>
      <OptionDrawer active={isOpen}>
        <Drawer close={close} clear={clear}>
          <selectContext.Provider value={useOption}>
            <OptionList height={height} active={isOpen} focused={focused} onFocus={focus}>
              {children}
            </OptionList>
          </selectContext.Provider>
        </Drawer>
      </OptionDrawer>
    </SelectBox>
  )
}

function defaultRenderSelect({ selected }) {
  return <>{selected.length === 0 ? 'Select' : selected.map(option => option.label).join(', ')}</>
}

export function Option({ value, label, children: Children, disabled = false, onToggle = () => {}, className }) {
  const useOption = useSelectContext()
  const { toggle, isSelected, multiple } = useOption({
    value,
    label,
    disabled,
    handleToggle: onToggle,
  })

  return (
    <NavigableItem identifier={value} label={label} disabled={disabled} className={className}>
      {({ isFocused, focus }) => (
        <Children
          isSelected={isSelected()}
          isFocused={isFocused}
          focus={focus}
          toggle={toggle}
          label={label}
          multiple={multiple}
        />
      )}
    </NavigableItem>
  )
}
