export const DEFAULT_CURRENCIES = {
  USD: {
    symbol: '$',
    trigram: 'USD',
    name: 'United States Dollar',
  },
  EUR: {
    symbol: '€',
    trigram: '',
    name: 'Euro',
  },
  NZD: {
    symbol: '$',
    trigram: 'NZD',
    name: 'New Zealand Dollar',
  },
  GBP: {
    symbol: '£',
    trigram: '',
    name: 'British Pound',
  },
  DKK: {
    symbol: 'kr',
    trigram: ' DKK',
    name: 'Danish Krone',
  },
  NOK: {
    symbol: 'kr',
    trigram: ' NOK',
    name: 'Norwegian Krone',
  },
  AUD: {
    symbol: '$',
    trigram: 'AUD',
    name: 'Australian Dollar',
  },
  CAD: {
    symbol: '$',
    trigram: 'CAD',
    name: 'Canadian Dollar',
  },
  SEK: {
    symbol: 'kr',
    trigram: ' SEK',
    name: 'Swedish Krona',
  },
  CHF: {
    symbol: 'CHF',
    trigram: '',
    name: 'Swiss Franc',
  },
}

export const DEFAULT_LANGUAGES = {
  en: 'English',
  de: 'Deutsch',
  fr: 'Français',
  es: 'Español',
  it: 'Italiano',
  pt: 'Portugês',
  nl: 'Nederlands',
  ca: 'Català',
}

export const DEFAULT_LANGUAGE = 'en'
export const DEFAULT_CURRENCY = 'EUR'
