import * as React from 'react'
import * as PropTypes from 'prop-types'
import styled from 'styled-components'

import * as colors from '@colors'
import * as fonts from '@fonts'

import DiscreetText from './DiscreetText'
import GoalLabel from './GoalLabel'
import ProjectCardTitle from './ProjectCardTitle'

const CardWrapper = styled.a`
  color: ${colors.PRIMARY_BLACK};
  display: flex;
  font-family: ${fonts.PRIMARY_FONT};
  line-height: 1.23;
  text-decoration: none;
  min-width: 280px;
  width: 100%;

  ${ProjectCardTitle} {
    margin-bottom: 5px;
  }

  &:hover ${ProjectCardTitle} {
    text-decoration: underline;
  }
`

const Picture = styled.div`
  background-color: ${colors.GREY_SHADE_6};
  background-image: ${props => (props.url ? `url(${props.url})` : undefined)};
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  max-height: 35px;
  margin: 2px 10px 0 0;
  max-width: 60px;
  width: 100%;
`

const ContentWrapper = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`

const Description = styled.span`
  display: block;
  font-size: 13px;
  font-weight: 500;
  margin-bottom: 12px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`

/* TODO: This component isn't really a card per-say.
  Design-wise, it was thought as part of a list of projects, not much as a standalone presentational component.
  That being said, it should probably not be named a "Card" nor used as one, it does not even have a background.
*/
/** HorizontalProjectCard is a presentational component for projects. With the shape of a card. It's layed horizontally, too. */
const HorizontalProjectCard = ({
  absoluteUrl,
  currency,
  goal,
  isOnline,
  language,
  picture,
  productsSoldCount,
  progress,
  rates,
  raisedAmount,
  subtitle,
  targetCurrency,
  title,
  translation,
  type,
}) => {
  return (
    <CardWrapper href={absoluteUrl}>
      <Picture url={picture} />
      <ContentWrapper>
        <ProjectCardTitle>{title}</ProjectCardTitle>
        {subtitle && <Description>{subtitle}</Description>}
        <DiscreetText light>
          {isOnline ? (
            <GoalLabel
              currency={currency}
              goal={goal}
              language={language}
              productsSoldCount={productsSoldCount}
              progress={progress}
              rates={rates}
              raisedAmount={raisedAmount}
              targetCurrency={targetCurrency}
              type={type}
            />
          ) : (
            translation['comingSoon']
          )}
        </DiscreetText>
      </ContentWrapper>
    </CardWrapper>
  )
}

HorizontalProjectCard.defaultProps = {
  isOnline: true,
  type: 'project',
}

HorizontalProjectCard.propTypes = {
  /** url of the project page on Ulule */
  absoluteUrl: PropTypes.string,
  /** currency of the project */
  currency: PropTypes.string.isRequired,
  /** minimum amount that the project needs to sell (if presale) or raise (if project) */
  goal: PropTypes.string,
  /** indicates if project has already launched. Determines goal display within the component. */
  isOnline: PropTypes.bool,
  /** language of project to choose right title and description */
  language: PropTypes.string.isRequired,
  /** url of the main project picture */
  picture: PropTypes.string,
  /** total of pre-orders sold (only when the project is a pre-sale */
  productsSoldCount: PropTypes.string,
  /** percentage of the project's progress */
  progress: PropTypes.number,
  /** list of currency rates */
  rates: PropTypes.object,
  /** amount currently raised by the project */
  raisedAmount: PropTypes.number,
  /** current currency. If different to project currency, raised amount will be converted to this */
  targetCurrency: PropTypes.string,
  /** name of the project */
  title: PropTypes.string,
  translation: PropTypes.shape({
    comingSoon: PropTypes.string.isRequired,
  }),
  /** project type, determines goal display */
  type: PropTypes.oneOf(['presale', 'project']),
  /** short description of the project */
  subtitle: PropTypes.string,
}

export default HorizontalProjectCard
HorizontalProjectCard.displayName = 'legacy.HorizontalProjectCard'
