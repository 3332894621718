import * as React from 'react'
import * as PropTypes from 'prop-types'

import { BottomToMiddle, ZoomIn } from '@legacy/UluleTransitions'
import { ImportantText } from '@legacy/Text'
import ModalBehaviour from './ModalBehaviour'
import * as S from './styles'

export const ClassicModal = ({ isClosable, children, overlay, isOpen, onClosing }) => (
  <ModalBehaviour isClosable={isClosable} overlay={overlay} isOpen={isOpen} onClosing={onClosing}>
    {({ isOpen, onClickClose, onExited }) => (
      <BottomToMiddle inProp={isOpen} duration={200} onExited={onExited}>
        <S.ClassicModalContainer>
          {isClosable && (
            <S.ModalCloseZone onClick={onClickClose}>
              <S.CloseIcon glyph="close" size={14} />
            </S.ModalCloseZone>
          )}
          <S.ModalContent>{children}</S.ModalContent>
        </S.ClassicModalContainer>
      </BottomToMiddle>
    )}
  </ModalBehaviour>
)

ClassicModal.propTypes = {
  /** elements passed as children for modal */
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.arrayOf(PropTypes.element)]).isRequired,
  /** display overlay */
  overlay: PropTypes.bool,
  /** show / hide modal */
  isOpen: PropTypes.bool,
  /** enable the fact that the modal is closable */
  isClosable: PropTypes.bool,
  /** this function is trigerred when modal is close from inside */
  onClosing: PropTypes.func,
}

export const NotificationModal = ({ isClosable, children, overlay, isOpen, onClosing }) => (
  <ModalBehaviour isClosable={isClosable} overlay={overlay} isOpen={isOpen} onClosing={onClosing}>
    {({ isOpen, onClickClose, onExited }) => (
      <ZoomIn inProp={isOpen} duration={200} onExited={onExited}>
        <S.NotificationModalContainer>
          <S.ModalContent>{children}</S.ModalContent>
          {isClosable && <S.CloseIcon glyph="close-light" size={14} onClick={onClickClose} />}
        </S.NotificationModalContainer>
      </ZoomIn>
    )}
  </ModalBehaviour>
)

NotificationModal.propTypes = {
  /** elements passed as children for modal */
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.arrayOf(PropTypes.element)]).isRequired,
  /** display overlay */
  overlay: PropTypes.bool,
  /** show / hide modal */
  isOpen: PropTypes.bool,
  /** enable the fact that the modal is closable */
  isClosable: PropTypes.bool,
  /** this function is trigerred when modal is close from inside */
  onClosing: PropTypes.func,
}

export const Dialog = ({ children, isOpen, onClosing, title, color, top, right, bottom, left }) => (
  <ModalBehaviour isClosable overlay={false} isOpen={isOpen} onClosing={onClosing} wrapper="div">
    {({ isOpen, onClickClose, onExited }) => (
      <BottomToMiddle inProp={isOpen} duration={200} onExited={onExited}>
        <S.DialogContainer top={top} right={right} bottom={bottom} left={left}>
          <S.DialogTitleBar color={color}>
            <S.DialogTitle>{title && <ImportantText>{title}</ImportantText>}</S.DialogTitle>
            <S.CloseIcon glyph="close" size={14} onClick={onClickClose} />
          </S.DialogTitleBar>
          <S.DialogContent>{children}</S.DialogContent>
        </S.DialogContainer>
      </BottomToMiddle>
    )}
  </ModalBehaviour>
)

Dialog.propTypes = {
  /** elements passed as children for modal */
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.arrayOf(PropTypes.element)]),
  /** show / hide modal */
  isOpen: PropTypes.bool,
  /** this function is trigerred when modal is close from inside */
  onClosing: PropTypes.func,
  /** title to display */
  title: PropTypes.string,
  /** color of the title bar */
  color: PropTypes.string,
  /** position: top */
  top: PropTypes.string,
  /** position: right */
  right: PropTypes.string,
  /** position: bottom */
  bottom: PropTypes.string,
  /** position: left */
  left: PropTypes.string,
}
